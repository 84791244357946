// // import React, { useEffect, useState } from "react";
// // import UITheam from "../common/UITheam";
// // import Header from "../common/Header";
// // import Sidebar from "../common/Sidebar";
// // import Footer from "../common/Footer";
// // import axios from "axios";
// // import DeleteModal from "./DeleteModal";
// // import AddBrokerModal from "./AddBrokerModal";
// // // import { BaseURL } from "../common/BaseUrl";
// // import { BaseURL } from "../../BaseUrl";
// // import Brokerstable from "./Brokerstable";
// // import EditBrokerModal from "./EditBrokerModal";

// // export default function Brokers() {
// //   const [brokersArray, setBrokersArray] = useState([]);
// //   const [selectedBroker, setSelectedBroker] = useState(null);
// //   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
// //   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
// //   const [isAddBrokerModalOpen, setIsAddBrokerModalOpen] = useState(false);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       const token = localStorage.getItem("token"); // Retrieve token from localStorage or session storage
// //       console.log("inside fetchData");
// //       try {
// //         const response = await axios.get(`${BaseURL}/getAllBrokers`, {
// //           headers: {
// //             Authorization: `Bearer ${token}`, // Attach token to Authorization header
// //           },
// //         });
// //         console.log(response.data.brokers, "inside response.data.brokers");
// //         if (
// //           response.status === 201 &&
// //           response.data.message === "All Broker Details Collected"
// //         ) {
// //           setBrokersArray(response.data.brokers);
// //           console.log(brokersArray,"inside Brokers.js");
// //         } else if (
// //           response.status === 200 &&
// //           response.data.message === "No brokers found"
// //         ) {
// //           alert(response.data.message);
// //         }
// //       } catch (error) {
// //         if (
// //           error.response &&
// //           error.response.status === 500 &&
// //           error.response.data.message === "Internal server error"
// //         ) {
// //           alert(error.response.data.message);
// //         } else {
// //           console.error("Error fetching brokers:", error);
// //         }
// //       }
// //     };

// //     fetchData();
// //   }, []); // Empty dependency array to run only on mount

// //   const handleEdit = (brokerId) => {
// //     const selected = brokersArray.find((broker) => broker.id === brokerId);
// //     setSelectedBroker(selected);
// //     setIsEditModalOpen(true);
// //   };

// //   const handleAddBroker = () => {
// //     setIsAddBrokerModalOpen(true);
// //   };

// //   const handleSave = async (updatedBroker) => {
// //     try {
// //       // Send PUT request to update broker
// //       const response = await axios.put(`${BaseURL}/editBroker`, updatedBroker);
// //       if (
// //         response.status === 200 &&
// //         response.data.message === "Name must be at least 4 characters long"
// //       ) {
// //         alert(response.data.message);
// //       }
// //       // Assuming your backend returns updated broker details, handle the response
// //       // Check for successful update
// //       if (
// //         response.status === 200 &&
// //         response.data.message === "Broker details updated successfully"
// //       ) {
// //         // Update the brokersArray state accordingly
// //         alert(response.data.message);
// //         const updatedBrokers = brokersArray.map((broker) =>
// //           broker.id === updatedBroker.id
// //             ? { ...broker, ...updatedBroker }
// //             : broker
// //         );
// //         setBrokersArray(updatedBrokers);
// //         console.log(brokersArray, "brokersArray in frontend");
// //         setIsEditModalOpen(false); // Close the edit modal
// //       } else {
// //         console.error("Error updating broker:", response.data.message, "1st");
// //       }
// //     } catch (error) {
// //       console.error("Error updating broker:", error, "2nd");
// //     }
// //   };

// //   const handleDeleteModalOpen = (brokerId) => {
// //     const selected = brokersArray.find((broker) => broker.id === brokerId);
// //     setSelectedBroker(selected);
// //     setIsDeleteModalOpen(true);
// //   };

// //   return (
// //     <div>
// //       <div className="container-scroller">
// //         <UITheam />
// //         <Header />

// //         <div className="container-fluid page-body-wrapper">
// //           <Sidebar />

// //           <div className="main-panel">
// //             <div className="content-wrapper">
// //               <div className="card">
// //                 <div className="card-body">
// //                   <div
// //                     style={{
// //                       display: "flex",
// //                       justifyContent: "space-between",
// //                       alignItems: "center",
// //                       marginBottom: "20px",
// //                     }}
// //                   >
// //                     <h4 style={{ marginTop: "0" }}>Broker Management</h4>
// //                     <button
// //                       className="btn btn-primary rounded"
// //                       onClick={handleAddBroker}
// //                     >
// //                       Add Broker
// //                     </button>
// //                   </div>
// //                   <AddBrokerModal
// //                     isOpen={isAddBrokerModalOpen}
// //                     onClose={() => setIsAddBrokerModalOpen(false)}
// //                     setBrokersArray={setBrokersArray}
// //                   />

// //                   <Brokerstable
// //                     brokersArray={brokersArray}
// //                     handleEdit={handleEdit}
// //                     handleDelete={handleDeleteModalOpen}
// //                   />
// //                   <div>
// //                     <EditBrokerModal
// //                       isOpen={isEditModalOpen}
// //                       setIsEditModalOpen={setIsEditModalOpen}
// //                       onClose={() => setIsEditModalOpen(false)}
// //                       broker={selectedBroker}
// //                       handleSave={handleSave}
// //                     />
// //                     <DeleteModal
// //                       isOpen={isDeleteModalOpen}
// //                       setIsOpen={setIsDeleteModalOpen}
// //                       broker={selectedBroker}
// //                       handleDelete={async (brokerId) => {
// //                         try {
// //                           const response = await axios.delete(
// //                             `${BaseURL}/deleteBroker/${brokerId}`
// //                           );
// //                           if (
// //                             response.status === 201 &&
// //                             response.data.message ===
// //                               "Broker deleted successfully"
// //                           ) {
// //                             const updatedBrokers = brokersArray.filter(
// //                               (broker) => broker.id !== brokerId
// //                             );
// //                             setBrokersArray(updatedBrokers);
// //                             setIsDeleteModalOpen(false);
// //                           } else {
// //                             console.error(
// //                               "Error deleting broker:",
// //                               response.data.message
// //                             );
// //                           }
// //                         } catch (error) {
// //                           console.error("Error deleting broker:", error);
// //                         }
// //                       }}
// //                     />
// //                   </div>
// //                 </div>
// //               </div>

// //               {/* <div> */}

// //               {/* </div> */}
// //             </div>
// //             <Footer />
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import DeleteModal from "./DeleteModal";
import AddBrokerModal from "./AddBrokerModal";
import EditBrokerModal from "./EditBrokerModal";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import { BaseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function Brokers() {
  const [brokersArray, setBrokersArray] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddBrokerModalOpen, setIsAddBrokerModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BaseURL}/getAllBrokers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.brokers, "------------------------");
      console.log(response.status);
      if (
        response.status === 201 &&
        response.data.message === "All Brokers Details Collected"
      ) {
        console.log(brokersArray, "---------------------------");
        setBrokersArray(response.data.brokers);
      } else if (
        response.status === 200 &&
        response.data.message === "No brokers found"
      ) {
        alert(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 500 &&
        error.response.data.message === "Internal server error"
      ) {
        alert(error.response.data.message);
      } else {
        console.error("Error fetching brokers:", error);
      }
    }
  };

  const handleEdit = (brokerId) => {
    const selected = brokersArray.find((broker) => broker.id === brokerId);
    setSelectedBroker(selected);
    setIsEditModalOpen(true);
  };

  const handleAddBroker = () => {
    setIsAddBrokerModalOpen(true);
  };

  const handleSave = async (updatedBroker) => {
    try {
      const response = await axios.put(`${BaseURL}/editBroker`, updatedBroker);
      if (
        response.status === 200 &&
        response.data.message === "Name must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }
      if (
        response.status === 201 &&
        response.data.message === "Broker details updated successfully"
      ) {
        alert(response.data.message);
        const updatedBrokers = brokersArray.map((broker) =>
          broker.id === updatedBroker.id
            ? { ...broker, ...updatedBroker }
            : broker
        );
        setBrokersArray(updatedBrokers);
        fetchData();
        setIsEditModalOpen(false);
      } else {
        console.error("Error updating broker:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating broker:", error);
    }
  };

  const handleDeleteModalOpen = (brokerId) => {
    const selected = brokersArray.find((broker) => broker.id === brokerId);
    setSelectedBroker(selected);
    setIsDeleteModalOpen(true);
  };

  const handleView = (id) => {
    const broker = brokersArray.find((broker) => broker.id === id);
    navigate("/brokersprofile", { state: { broker } });
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      const response = await axios.put(
        `${BaseURL}/changeStatusBrokerSide?id=${id}&status=${newStatus}`
      );
      if (
        response.status === 201 &&
        response.data.message === "Broker details updated successfully"
      ) {
        console.log("Status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "mobile", headerName: "Mobile", type: "number", width: 160 },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={() =>
              handleStatusChange(params.row.id, params.row.status)
            }
          />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleEdit(params.row.id)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => handleDeleteModalOpen(params.row.id)}
          >
            Delete
          </button>
          <button
            className="btn btn-sm btn-info ms-2"
            onClick={() => handleView(params.row.id)}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>Broker Management</h4>
                    <button
                      className="btn btn-primary rounded"
                      onClick={handleAddBroker}
                    >
                      Add Broker
                    </button>
                  </div>
                  <AddBrokerModal
                    isOpen={isAddBrokerModalOpen}
                    onClose={() => setIsAddBrokerModalOpen(false)}
                    setBrokersArray={setBrokersArray}
                  />

                  <div
                    style={{
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DataGrid
                      rows={brokersArray}
                      columns={columns}
                      pageSize={5}
                      pagination
                      rowsPerPageOptions={[5, 10]}
                    />
                  </div>
                  <EditBrokerModal
                    isOpen={isEditModalOpen}
                    setIsEditModalOpen={setIsEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    broker={selectedBroker}
                    handleSave={handleSave}
                  />
                  <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setIsOpen={setIsDeleteModalOpen}
                    broker={selectedBroker}
                    handleDelete={async (brokerId) => {
                      try {
                        const response = await axios.delete(
                          `${BaseURL}/deleteBroker/${brokerId}`
                        );
                        if (
                          response.status === 201 &&
                          response.data.message ===
                            "Broker deleted successfully"
                        ) {
                          const updatedBrokers = brokersArray.filter(
                            (broker) => broker.id !== brokerId
                          );
                          setBrokersArray(updatedBrokers);
                          fetchData();
                          setIsDeleteModalOpen(false);
                        } else {
                          console.error(
                            "Error deleting broker:",
                            response.data.message
                          );
                        }
                      } catch (error) {
                        console.error("Error deleting broker:", error);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
