import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";

import { jwtDecode } from "jwt-decode";

function Login() {
  const [staffDetails, setStaffDetails] = useState({
    email: "",
    password: "",
  });
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const decodeStaffToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          // Decode the token using jwt-decode library
          const decodedToken = jwtDecode(token);
          console.log(decodedToken, "---------decodedToken---------");
          // Set staff details extracted from the token
          setStaffDetails({
            id: decodedToken._id,
            email: decodedToken.email,
            name: decodedToken.name,
            mobile: decodedToken.mobile,
            address: decodedToken.address,
          });
          navigate("/dashboard");
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };
    // Call decodeStaffToken when component mounts
    decodeStaffToken();
  });

  useEffect(() => {
    const fetchLogoDetails = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getlogodetails`);
        console.log(response); // Log the entire response to debug
        if (response.status === 200 && response.data.success) {
          console.log("Logo URL:", response.data.results[0].logo); // Log the logo URL to verify
          setLogoUrl(response.data.results[0].logo);
        } else {
          console.error("Failed to fetch logo details:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching logo details:", error);
      }
    };

    fetchLogoDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaffDetails({ ...staffDetails, [name]: value });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BaseURL}`, {
        email: staffDetails.email,
        password: staffDetails.password,
      });

      console.log(response, "--------------------");
      const { message, token, type, permissions, staffId } = response.data;

      if (response.status === 200) {
        switch (message) {
          case "Staff not found, Please signup":
            alert("Staff not found. Please signup.");
            break;
          case "Password is incorrect":
            alert("Password is incorrect. Please try again.");
            break;
          case "Please enter a valid email address":
            alert("Please enter a valid email address.");
            break;
          default:
            alert("Unexpected response from server.");
            break;
        }
      } else if (response.status === 201) {
        localStorage.setItem("token", token);
        localStorage.setItem("type", type);
        localStorage.setItem("staffId", staffId);
        localStorage.setItem("permissions", JSON.stringify(permissions));
        alert(response.data.message);
        setStaffDetails(response.data, "--------setStaffDetails-------------");
        console.log(
          type,
          "----------------------------------------jihwwihdijwi"
        );
        // navigate('/dashboard')
        if (type == 1) {
          navigate("/dashboard");
        } else if (type == 0 && permissions.length > 0) {
          console.log(typeof permissions, "hgwhuegfiewg");
          let arr = permissions.split(",");
          console.log(arr);
          const leastPermission = Math.min(...arr);
          console.log(leastPermission, "leastpermission");
          switch (leastPermission) {
            case 0:
              navigate("/properties");
              break;
            case 1:
              navigate("/users");
              break;
            case 2:
              navigate("/services");
              break;
            case 3:
              navigate("/packages");
              break;
            case 4:
              navigate("/tickets");
              break;
            case 5:
              navigate("/rewards");
              break;
            case 6:
              navigate("/payments");
              break;
            default:
              navigate("/dashboard");
              break;
          }
        } else {
          navigate("/dashboard");
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    }
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo" style={{textAlign:"center"}}>
                  <a className="navbar-brand brand-logo" href="#" >
                    {logoUrl ? (
                      <img src={logoUrl} alt="logo"  style={{width:'130px'}}/>
                    ) : (
                      <div>Loading logo...</div>
                    )}
                  </a>
                  <a className="navbar-brand brand-logo-mini" href="/">
                    {/* <img src="assets/images/logo-mini.svg" alt="logo" /> */}
                  </a>
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="fw-light">Sign in to continue.</h6>
                <form onSubmit={handleSubmit} className="pt-3">
                  <div className="form-group">
                    <input
                      // type="email"
                      name="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Enter Email / Phone"
                      onChange={handleChange}
                      value={staffDetails.email}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-lg"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onChange={handleChange}
                      value={staffDetails.password}
                    />
                  </div>
                  <div className="mt-3 d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                  </div>
                </form>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  {/* <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      Keep me signed in
                    </label>
                  </div> */}
                  <a href="/forgotpassword" className="auth-link text-black">
                    Forgot password?
                  </a>
                </div>
                {/* <div className="text-center mt-4 fw-light">
                  Don't have an account?
                  <a href="/register" className="text-primary">
                     Register
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

export default Login;
