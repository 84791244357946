import React, { useState } from "react";
import { isValidMobile } from "../../../utils/validations";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../BaseUrl";

function AddStaffModal({ isOpen, onClose, fetchData }) {
  const [staffDetails, setStaffDetails] = useState({
    type: 0, // Default role set to 0 for "Staff"
    name: "",
    email: "",
    mobile: "",
    address: "",
    password: "",
    permissions: [],
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "type") {
      setStaffDetails((prevStaffDetails) => ({
        ...prevStaffDetails,
        type: parseInt(value), // Convert value to integer (0 or 1)
      }));
    } else if (name === "mobile") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      setStaffDetails((prevStaffDetails) => ({
        ...prevStaffDetails,
        [name]: cleanedValue,
      }));
    } else if (type === "checkbox") {
      const permissions = checked
        ? [...staffDetails.permissions, parseInt(value)]
        : staffDetails.permissions.filter((p) => p !== parseInt(value));

      setStaffDetails((prevStaffDetails) => ({
        ...prevStaffDetails,
        permissions: permissions,
      }));
    } else {
      setStaffDetails((prevStaffDetails) => ({
        ...prevStaffDetails,
        [name]: value,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isValidMobile(staffDetails.mobile)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }
      const response = await axios.post(`${BaseURL}/register`, staffDetails);

      if (response.status === 200 && response.data.message) {
        alert(response.data.message);
      }

      if (response.status === 201) {
        alert("Staff registered successfully!");
        setStaffDetails({
          type: 0, // Reset role to 0 (Staff)
          name: "",
          email: "",
          mobile: "",
          address: "",
          password: "",
          permissions: [],
        });
        onClose(); // Close the modal
        fetchData(); // Fetch updated staff list
      }
    } catch (error) {
      console.error("There was an error registering the staff member:", error);
    }
  };

  return (
    <>
      <div
        className={`modal ${isOpen ? "show" : ""}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ top: "60px" }}>
            <div className="modal-header">
              <h5 className="modal-title">Add Staff</h5>
              <button
                type="button"
                className="close"
                onClick={onClose}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: 0,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#000",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <select
                    name="type"
                    className="form-control"
                    value={staffDetails.type}
                    onChange={handleChange}
                    required
                  >
                    <option value={1}>Admin</option>
                    <option value={0}>Staff</option>
                  </select>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    value={staffDetails.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={staffDetails.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Mobile"
                    value={staffDetails.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    value={staffDetails.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={staffDetails.password}
                    onChange={handleChange}
                    required
                    minLength={6}
                  />
                </div>

                {staffDetails.type === 0 && (
                  <div className="row">
                    <h5>Permissions:</h5>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="properties"
                        value={0}
                        checked={staffDetails.permissions.includes(0)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="properties">
                        Properties
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="user"
                        value={1}
                        checked={staffDetails.permissions.includes(1)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="user">
                        Users
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="service"
                        value={2}
                        checked={staffDetails.permissions.includes(2)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="service">
                        Services
                      </label>
                    </div>
                    {/* <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="package"
                        value={3}
                        checked={staffDetails.permissions.includes(3)}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="package">
                        Packages
                      </label>
                    </div> */}
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="tickets"
                        value={4}
                        checked={staffDetails.permissions.includes(4)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="tickets">
                        Tickets
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="rewards"
                        value={5}
                        checked={staffDetails.permissions.includes(5)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="rewards">
                        Rewards
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="payments"
                        value={6}
                        checked={staffDetails.permissions.includes(6)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="payments">
                        Payments
                      </label>
                    </div>

                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="activities"
                        value={7}
                        checked={staffDetails.permissions.includes(7)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="activities">
                        Activities
                      </label>
                    </div>

                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="user_query"
                        value={8}
                        checked={staffDetails.permissions.includes(8)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="user_query">
                        User Query
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="coupons"
                        value={9}
                        checked={staffDetails.permissions.includes(9)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="coupons">
                        Coupons
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="testimonials"
                        value={10}
                        checked={staffDetails.permissions.includes(10)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="testimonials">
                       Testimonials
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="agents"
                        value={11}
                        checked={staffDetails.permissions.includes(11)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="agents">
                        Agents
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="abouts"
                        value={12}
                        checked={staffDetails.permissions.includes(12)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="abouts">
                        Abouts
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions"
                        id="teams"
                        value={13}
                        checked={staffDetails.permissions.includes(13)}
                        onChange={handleChange}
                        style={{marginRight:"10px"}}
                      />
                      <label className="form-check-label" htmlFor="teams">
                        Teams
                      </label>
                    </div>

                    
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" className="btn btn-primary btn-block">
                    Add Staff
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-background-blur ${isOpen ? "show" : ""}`}
        style={{
          display: isOpen ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the opacity as needed
          backdropFilter: "blur(1px)", // Adjust the blur effect as needed
          zIndex: 1000, // Ensure this is above your modal's z-index
        }}
      ></div>
    </>
  );
}

export default AddStaffModal;
