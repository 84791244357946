import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Switch demo" } };

function Staffstable({ staffsArray, handleEdit, handleDelete, setStaffsArray }) {
  const navigate = useNavigate();

  // Add serialId to each staff object
  const staffsWithSerialId = staffsArray.map((staff, index) => ({
    ...staff,
    serialId: index + 1,
  }));

  const handleView = (id) => {
    const staff = staffsArray.find((staff) => staff.id === id);
    navigate("/staffprofile", { state: { staff } });
  };

  const handleStatusChange = async (id, currentStatus) => {
    // Toggle the status value between 0 and 1
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      // Send the updated staff status to the backend API
      const response = await axios.put(
        `${BaseURL}/changeStatus?id=${id}&status=${newStatus}`
      );
      if (
        response.status === 201 &&
        response.data.message === "Status updated successfully"
      ) {
        setStaffsArray((prevStaffsArray) =>
          prevStaffsArray.map((staff) =>
            staff.id === id ? { ...staff, status: newStatus } : staff
          )
        );
        console.log("Status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const columns = [
    { field: "serialId", headerName: "S/N", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 250 },
    { field: 'type', headerName: 'Type', width: 80, 
      renderCell: (params) => {
        return params.value === 1 ? 'Admin' : 'Staff';
      } 
    },
    { field: "mobile", headerName: "Mobile", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // height: "100%",
            // width: "100%",
          }}
        >
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={() => handleStatusChange(params.row.id, params.row.status)}
          />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // height: "100%",
            // width: "100%",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleEdit(params.row.id)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => handleDelete(params.row.id)}
          >
            Archive
          </button>
          {/* <button
            className="btn btn-sm btn-info ms-2"
            onClick={() => handleView(params.row.id)}
          >
            View
          </button> */}
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DataGrid
        rows={staffsWithSerialId} // Use staffsWithSerialId with serialId added
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default Staffstable;
