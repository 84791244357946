import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import axios from "axios";
import ArchivedTable from "./ArchivedTable";
// import ViewStaffModal from "./ViewStaffModal";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";

function ArchivedStaffs() {
  const [archivedStaffs, setArchivedStaffs] = useState([]);
  const [filteredArchivedStaffs, setFilteredArchivedStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [isViewStaffModalOpen, setIsViewStaffModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getarchivedstaffs`);
        if (
          response.status === 201 &&
          response.data.message === "archived staffs found"
        ) {
          setArchivedStaffs(response.data.results);
          setFilteredArchivedStaffs(response.data.results);
        } else if (
          response.status === 200 &&
          response.data.message === "No archived staffs found"
        ) {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching archived staffs:", error);
      }
    };

    fetchData();
  }, []);

  const handleView = (staffId) => {
    const selected = archivedStaffs.find((staff) => staff.id === staffId);
    setSelectedStaff(selected);
    setIsViewStaffModalOpen(true);
  };

  const handleUnarchive = async (staffId) => {
    try {
      const response = await axios.put(`${BaseURL}/unarchivestaff/${staffId}`);
      if (
        response.status === 200 &&
        response.data.message ===
          "Staff moved from archived_staffs to staffs table successfully"
      ) {
        setArchivedStaffs((prevStaffs) =>
          prevStaffs.filter((staff) => staff.staffId !== staffId)
        );
        setFilteredArchivedStaffs((prevStaffs) =>
          prevStaffs.filter((staff) => staff.staffId !== staffId)
        );
        alert("Staff unarchived successfully!");
      } else {
        console.error("Unexpected response:", response);
        alert("Unexpected response from server.");
      }
    } catch (error) {
      console.error("Error unarchiving staff:", error);
      alert("Error unarchiving staff!");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterStaffs(query);
  };

  const filterStaffs = (query) => {
    let filtered = archivedStaffs;
    if (query) {
      filtered = filtered.filter(
        (staff) =>
          staff.name.toLowerCase().includes(query) ||
          staff.email.toLowerCase().includes(query) ||
          staff.mobile.toLowerCase().includes(query)
      );
    }
    setFilteredArchivedStaffs(filtered);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                   <h4 style={{ marginTop: "0" }}>Archived Staffs</h4>
                   

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        placeholder="Search staffs"
                        value={searchQuery}
                        onChange={handleSearch}
                        style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "200px", 
                          fontSize: "16px",
                        }}
                      />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        className="btn btn-secondary rounded"
                        to="/staffs"
                        style={{ marginRight: "10px" }}
                      >
                      Back &nbsp;
                       <i class='fa fa-reply'></i>
                        
                      </Link>
                     
                     
                    </div>
                  </div>

                  <ArchivedTable
                    archivedStaffs={filteredArchivedStaffs}
                    handleView={handleView}
                    handleUnarchive={handleUnarchive}
                  />
                  {/* <ViewStaffModal
                    isOpen={isViewStaffModalOpen}
                    setIsOpen={setIsViewStaffModalOpen}
                    staff={selectedStaff}
                  /> */}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchivedStaffs;
