import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";

export default function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [openTicketId, setOpenTicketId] = useState(null);
  const [newTickets, setNewTickets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [newTicketCount, setNewTicketCount] = useState(0); 
  const staffId  = localStorage.getItem("staffId");
  const type = localStorage.getItem("type");

  useEffect(() => {
    fetchTickets();
    const interval = setInterval(fetchTickets, 6000);
    return () => clearInterval(interval);
  }, []);

  const fetchTickets = () => {
    axios
      .get(`${BaseURL}/getticketsandmessages/${staffId}/${type}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const fetchedTickets = response.data;
          const newTickets = fetchedTickets.filter(
            (ticket) =>
              !tickets.some((existingTicket) => existingTicket.id === ticket.id)
          );
          setNewTicketCount(newTickets.length);
          setNewTickets(newTickets);
          setTickets(fetchedTickets);
        } else {
          setNewTickets([]);
          setTickets([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching tickets:", error);
        setNewTickets([]);
        setTickets([]);
      });
  };

  const deleteTicket = (ticketId) => {
    axios
      .delete(`${BaseURL}/tickets/${ticketId}`)
      .then((response) => {
        fetchTickets();
        alert("Ticket deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting ticket:", error);
      });
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(timestamp).toLocaleDateString("en-GB", options);
  };

  const memoizedTicket = useMemo(() => {
    if (currentTicket?.id && tickets.length > 0) {
      const updatedTicket = tickets.find(
        (ticket) => ticket.id === currentTicket.id
      );
      if (updatedTicket) {
        return updatedTicket;
      }
    }
    return currentTicket;
  }, [currentTicket, tickets]);

  useEffect(() => {
    setCurrentTicket(memoizedTicket);
  }, [memoizedTicket]);

  const handleTicketClick = (ticket) => {
    setOpenTicketId((prevOpenTicketId) =>
      prevOpenTicketId === ticket.id ? null : ticket.id
    );
    setCurrentTicket(ticket);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filterTickets = (ticket) => {
    const { id, name, email, mobile, created_date } = ticket;
    const searchLower = searchTerm.toLowerCase();
    const ticketMatches =
      id.toString().includes(searchTerm) ||
      name.toLowerCase().includes(searchLower) ||
      email.toLowerCase().includes(searchLower) ||
      mobile.toLowerCase().includes(searchLower);

    const startDateMatch = startDate
      ? new Date(created_date) >= new Date(startDate)
      : true;
    const endDateMatch = endDate
      ? new Date(created_date) <= new Date(endDate)
      : true;

    return ticketMatches && startDateMatch && endDateMatch;
  };

  const filteredNewTickets = newTickets.filter(filterTickets);

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
      
        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 className="card-title">New Support Tickets</h4>
                    <input
                      type="text"
                      placeholder="Search by name, email, mobile, or ID"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      style={{
                        marginRight: "10px",
                        border: "1px solid black",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        transition: "box-shadow 0.3s ease",
                        width: "200px",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                  {filteredNewTickets.length === 0 ? (
                    <p>No new tickets found.</p>
                  ) : (
                    <ul className="list-group" style={{height:"65vh", overflowY:"scroll"}}>
                      {filteredNewTickets.map((ticket) => {
                        const latestMessage =
                          ticket.messages[ticket.messages.length - 1];
                        const highlightClass =
                          latestMessage && latestMessage.is_admin_reply === 0
                            ? "highlight-red"
                            : "highlight-green";
                        return (
                          <li
                            key={ticket.id}
                            className={`list-group-item ${highlightClass}`}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div onClick={() => handleTicketClick(ticket)} style={{cursor:"pointer", textAlign:'left'}}>
                              <Link to={`/tickets/${ticket.id}`} style={{color:"black", textDecoration:"none"}}>
                                <strong>Ticket Id :{ticket.id}</strong> &emsp;{" "}
                                <span>
                                  <b>subject-</b> {ticket.subject}
                                </span>
                                <div>
                                  <span>
                                    <b>Name:</b> {ticket.name}
                                  </span>{" "}
                                  &emsp;{" "}
                                  <span>
                                    <b>Email:</b> {ticket.email}
                                  </span>
                                  <br />
                                  <span>
                                    <b>Mobile:</b> +91 {ticket.mobile}
                                  </span>{" "}
                                  &emsp;{" "}
                                  <span>
                                    <b>Created date:</b>{" "}
                                    {formatDate(ticket.created_date)}
                                  </span>
                                </div>
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to={`/tickets/${ticket.id}`}
                                  className="btn btn-primary btn-sm"
                                >
                                  Reply
                                </Link>
                                <button
                                  onClick={() => deleteTicket(ticket.id)}
                                  className="btn btn-danger btn-sm ms-2"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                            {/* {openTicketId === ticket.id && (
                              <div className="mt-3 ticket-message-container">
                                <div>
                                  <strong>Created Date:</strong>{" "}
                                  {formatDate(ticket.timestamp)}
                                </div>
                                <div className="messages">
                                  {currentTicket.messages.map(
                                    (message, index) => (
                                      <div
                                        key={index}
                                        className={`mb-2 ${
                                          message.is_admin_reply
                                            ? "text-end"
                                            : "text-start"
                                        }`}
                                      >
                                        <strong>
                                          {message.is_admin_reply
                                            ? "Admin"
                                            : "User"}
                                          :
                                        </strong>{" "}
                                        {message.message} <br />
                                        <small>
                                          {formatDate(message.timestamp)}
                                        </small>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )} */}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <Footer />
      </div>
      <style>
        {`
          .notification {
            position: fixed;
            top: 10px;
            right: 10px;
            background-color: #ff4d4d;
            color: white;
            padding: 10px;
            border-radius: 5px;
            z-index: 1000;
          }
          .notification-count {
            font-size: 16px;
            font-weight: bold;
          }

          .highlight-red {
            background-color: #fff;
            border: 2px dashed red;
            padding: 10px;
          }

          .highlight-green {
            background-color: #fff;
            border: 2px dashed green;
            padding: 10px;
          }
          
          .ticket-message-container {
            max-height: 300px;
            overflow-y: auto;
          }
          .messages {
            max-height: 200px;
            overflow-y: auto;
            border: 1px solid #ddd;
            padding: 10px;
            margin-bottom: 10px;
          }
        `}
      </style>
    </div>
  );
}
