import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Switch,
  Container,
  Modal,
  Box,
  TextField,
  Typography,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { format } from "date-fns";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";

export default function SubCategories() {
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [search, setSearch] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedData, setEditedData] = useState({
    name: "",
    categoryId: "",
    subcategory_image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${BaseURL}/getallsubcategories`);
      setSubCategories(response.data.results);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BaseURL}/getallcategories`);
      setCategories(response.data.results);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, []);


  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(
        `${BaseURL}/changesubcategorystatus?id=${id}&status=${newStatus}`
      );
      setSubCategories((prevSubCategories) =>
        prevSubCategories.map((subCategory) =>
          subCategory.id === id
            ? { ...subCategory, status: newStatus }
            : subCategory
        )
      );
      setSnackbarMessage(`Status changed successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleArchive = async (id) => {
    try {
      await axios.put(`${BaseURL}/archivesubcategory/${id}`);
      setSubCategories((prevSubCategories) =>
        prevSubCategories.filter((subCategory) => subCategory.id !== id)
      );
      setSnackbarMessage(`Subcategory archived successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error archiving subcategory:", error);
    }
  };

  const handleEditClick = (subCategoryId) => {
    const subCategory = subCategories.find((sub) => sub.id === subCategoryId);
    setSelectedCategory(subCategory);
    setOpenEditModal(true);
    setEditedData({
      name: subCategory.name,
      categoryId: subCategory.categoryId,
      subcategory_image: subCategory.category_subtypes_image,
    });
    setImagePreview(subCategory.category_subtypes_image);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategory(null);
    setEditedData({
      name: "",
      categoryId: "",
      subcategory_image: null,
    });
    setImagePreview(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEditedData({ ...editedData, subcategory_image: file });
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSaveChanges = () => {
    const subCategoryId = selectedCategory.id;
    const formData = new FormData();
    formData.append("name", editedData.name);
    formData.append("categoryId", editedData.categoryId);
    if (editedData.subcategory_image) {
      formData.append("subcategory_image", editedData.subcategory_image);
    }

    axios
      .put(`${BaseURL}/editsubcategory/${subCategoryId}`, formData)
      .then((response) => {
        console.log("Successfully saved changes:", response.data);
        setSnackbarMessage("Changes saved successfully.");
        setSnackbarOpen(true);
        handleCloseEditModal();
        fetchSubCategories(); // Re-fetch subcategories after successful edit
      })
      .catch((error) => {
        console.error("Error saving changes:", error);
        setSnackbarMessage("Error saving changes.");
        setSnackbarOpen(true);
      });
  };


  const filteredSubCategories = subCategories
    .filter((subCategory) =>
      subCategory.name.toLowerCase().includes(search.toLowerCase())
    )
    .filter((subCategory) =>
      filterCategory ? subCategory.category_name === filterCategory : true
    );

  const columns = [
    {
      field: "sn",
      headerName: "S/N",
      width: 70,
      renderCell: (params) => subCategories.indexOf(params.row) + 1,
    },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "category_name",
      headerName: "Category Name",
      width: 200,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      renderCell: (params) => {
        const timestampValue = params.value ? new Date(params.value) : null;
        return timestampValue
          ? format(timestampValue, "dd/MM/yyyy HH:mm:ss")
          : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Switch
          checked={params.value === 1}
          onChange={() =>
            handleStatusChange(params.row.id, params.value === 1 ? 0 : 1)
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            onClick={() => handleEditClick(params.row.id)}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleArchive(params.row.id)}
            startIcon={<DeleteIcon />}
          >
            Archive
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="card-body">
                    <h4 className="card-title">Subcategories Management</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div>
                        <TextField
                          label="Search by Name"
                          variant="outlined"
                          size="small"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          style={{ marginRight: "10px" }}
                        />
                        <FormControl
                          variant="outlined"
                          size="small"
                          style={{ minWidth: 200 }}
                        >
                          <InputLabel id="filter-category-label">
                            Filter by Category
                          </InputLabel>
                          <Select
                            labelId="filter-category-label"
                            value={filterCategory}
                            onChange={(e) => setFilterCategory(e.target.value)}
                            label="Filter by Category"
                          >
                            <MenuItem value="">
                              <em>All</em>
                            </MenuItem>
                            {categories.map((category) => (
                              <MenuItem key={category.id} value={category.name}>
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            textDecoration: "none",
                            marginLeft: "20px",
                          }}
                          onClick={() => {
                            navigate("/addsubcategories");
                          }}
                        >
                          Add SubCategory
                        </button>
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            textDecoration: "none",
                            marginLeft: "20px",
                          }}
                        >
                          <Link
                            to="/archivesubcategories"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Archive
                          </Link>
                        </button>
                      </div>
                    </div>

                    <div style={{ height: 600, width: "100%" }}>
                      <DataGrid
                        rows={filteredSubCategories}
                        columns={columns}
                        pageSize={10}
                        getRowId={(row) => row.id}
                      />
                    </div>
                    <Modal open={openEditModal} onClose={handleCloseEditModal}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          borderRadius: "10px",
                          boxShadow: 24,
                          p: 4,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Edit SubCategory
                        </Typography>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          value={editedData.name}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              name: e.target.value,
                            })
                          }
                          margin="normal"
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        >
                          <InputLabel id="edit-category-label">
                            Category
                          </InputLabel>
                          <Select
                            labelId="edit-category-label"
                            value={editedData.categoryId}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                categoryId: e.target.value,
                              })
                            }
                            label="Category"
                          >
                            {categories.map((category) => (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          onChange={handleImageChange}
                        />
                        <label htmlFor="raised-button-file">
                          <Button variant="contained" component="span">
                            Upload Image
                          </Button>
                        </label>
                        {imagePreview && (
                          <Box
                            mt={2}
                            component="img"
                            src={imagePreview}
                            alt="Image Preview"
                            sx={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "10px",
                            }}
                          />
                        )}
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveChanges}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseEditModal}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={() => setSnackbarOpen(false)}
                      message={snackbarMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
