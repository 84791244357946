import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

export default function StaffSalesReport() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (date) => date.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(formatDate(today));
  const [endDate, setEndDate] = useState(formatDate(tomorrow));
  const [reportData, setReportData] = useState([]);
  const [staffSummary, setStaffSummary] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const formatDate1 = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedStartDate = formatDate1(startDate);
  const formattedEndDate = formatDate1(endDate);

  useEffect(() => {
    if (staffSummary.length > 0) {
      const total = staffSummary.reduce(
        (acc, item) => acc + item.totalPayment,
        0
      );
      setTotalAmount(total);
    } else {
      setTotalAmount(0);
    }
  }, [staffSummary]);

  const fetchReport = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/getStaffSalesReport?startDate=${startDate}&endDate=${endDate}`
      );
      if (response.data.success) {
        const data = response.data.results;

        // Summarize payments by staff
        const summary = data
          .filter(item => item.assign_staff_id) // Exclude items with no staff assigned
          .reduce((acc, item) => {
            const staffId = item.assign_staff_id;
            if (!acc[staffId]) {
              acc[staffId] = {
                id: staffId,
                staff_name: item.staff_name || "N/A",
                staff_email: item.staff_email || "N/A",
                totalPayment: 0,
              };
            }
            acc[staffId].totalPayment += parseFloat(item.payment_amount) - parseFloat(item.refund_amount);
            return acc;
          }, {});

        setStaffSummary(Object.values(summary));
      } else {
        setStaffSummary([]);
        console.warn("No data found");
      }
    } catch (error) {
      console.error("Error fetching report data", error);
      setStaffSummary([]);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Staff Sales Payment Report", 14, 20);
    doc.text(`From: ${formattedStartDate} To: ${formattedEndDate}`, 14, 30);
    doc.autoTable({
      startY: 40,
      head: [["Staff ID", "Staff Name", "Email", "Total Payment"]],
      body: [
        ...staffSummary.map((row) => [
          row.id || "N/A",
          row.staff_name || "N/A",
          row.staff_email || "N/A",
          row.totalPayment.toFixed(2),
        ]),
        ["", "", "Total Amount", totalAmount.toFixed(2)],
      ],
    });
    doc.save("staff_sales_payment_report.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet([
      {
        "Staff ID": "Staff Sales Payment Report",
        "Staff Name": "",
        "Email": "",
        "Total Payment": `From: ${formattedStartDate} To: ${formattedEndDate}`,
      },
      ...staffSummary.map((row) => ({
        "Staff ID": row.id || "N/A",
        "Staff Name": row.staff_name || "N/A",
        "Email": row.staff_email || "N/A",
        "Total Payment": row.totalPayment.toFixed(2),
      })),
      {
        "Staff ID": "",
        "Staff Name": "",
        "Email": "Total Amount",
        "Total Payment": totalAmount.toFixed(2),
      },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Staff Sales Payment Report");
    XLSX.writeFile(wb, "staff_sales_payment_report.xlsx");
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
           
            <div className="content-wrapper">
              <div className="card">
              
                <div className="card-body">
                <h4 style={{margin:'10px', paddingBottom:'20px'}}>Staff Sales Payment Report</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={fetchReport}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={generatePDF}
                    >
                      Generate PDF
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={exportToExcel}
                    >
                      Export to Excel
                    </Button>
            
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Staff ID</TableCell>
                          <TableCell>Staff Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Total Payment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {staffSummary && staffSummary.length > 0 ? (
                          staffSummary.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.id || "N/A"}</TableCell>
                              <TableCell>{row.staff_name || "N/A"}</TableCell>
                              <TableCell>{row.staff_email || "N/A"}</TableCell>
                              <TableCell>
                                {row.totalPayment.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ marginTop: "20px", fontWeight: "bold" }}>
                    Total Amount: ₹{totalAmount.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
