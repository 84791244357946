import React, { useEffect, useState } from "react";
import { isValidMobile } from "../../../utils/validations";

function EditUserModal({ isOpen, setIsEditModalOpen, onClose, user, handleSave }) {
  const [editedDetails, setEditedDetails] = useState({
    id:"",
    name: "",
    email: "",
    mobile: "",
  });

  // Update editedDetails when user prop changes
  useEffect(() => {
    if (user) {
      setEditedDetails({
        id:user.id|| "",
        name: user.name || "",
        email: user.email || "",
        mobile: user.mobile || "",
      });
    }
  }, [user]);

  // Function to handle changes in user details inputs
  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // Validation check for mobile number
    if (!isValidMobile(editedDetails.mobile)) {
      alert("Please enter a valid 10-digit mobile number");
      return;
    }
    
    // Call handleSave function to update user details
    handleSave(editedDetails);

    // Close the modal after saving
    setIsEditModalOpen(false);
  };

  return (
    <div>
      <div
        className="modal-background"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(1px)",
          zIndex: 1000,
          display: isOpen ? "block" : "none",
        }}
      ></div>

      <div
        className={`modal ${isOpen ? "show" : ""}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ top: "60px" }}>
            <div className="modal-header">
              <h5 className="modal-title">Edit User Details</h5>
              <button
                type="button"
                className="close"
                onClick={onClose}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: 0,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#000",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editedDetails.email}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={editedDetails.name}
                    name="name"
                    onChange={handleUserDetailsChange}
                  />
                </div>
                <div className="form-group">
                  <label>Mobile</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile"
                    value={editedDetails.mobile}
                    name="mobile"
                    onChange={handleUserDetailsChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserModal;
