import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import ViewPropertyModal from "./ViewPropertyModal"; // Import the modal component

export default function UserProperties() {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [propertiesPerPage] = useState(10); // Number of properties per page
  const [selectedProperty, setSelectedProperty] = useState(null); // State for selected property modal
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${BaseURL}/getusersproperties/${id}`)
      .then((response) => {
        if (response.data.message === "Properties found") {
          setProperties(response.data.results);
        } else {
          setProperties([]);
        }
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the properties data!",
          error
        );
        setProperties([]);
      });
  }, [id]);

  // Pagination logic
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openPropertyModal = (property) => {
    setSelectedProperty(property);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProperty(null);
    setModalOpen(false);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">User Properties</h4>
                  <div style={{ overflowX: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Title</th>
                          <th>Address</th>
                          <th>Price</th>
                          <th>Approval</th>
                          <th>Status</th>
                          <th>Featured</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentProperties.length > 0 ? (
                          currentProperties.map((property, index) => (
                            <tr key={index}>
                              <td>{property.id}</td>
                              <td>{property.title}</td>
                              <td>{`${property.address}, ${property.city}, ${property.state}, ${property.country} - ${property.zipcode}`}</td>
                              <td>{property.price}</td>
                              <td>
                                {property.approval === 1
                                  ? "Approved"
                                  : "Pending"}
                              </td>
                              <td>
                                {property.status === 1 ? "Active" : "Closed"}
                              </td>
                              <td>
                                {property.featured === 1
                                  ? "Featured"
                                  : "Not Featured"}
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => openPropertyModal(property)}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" style={{ textAlign: "center" }}>
                              No properties found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  {properties.length > propertiesPerPage && (
                    <nav>
                      <ul className="pagination">
                        {Array(Math.ceil(properties.length / propertiesPerPage))
                          .fill()
                          .map((_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                currentPage === index + 1 ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>

      {/* Modal for viewing property details */}
      <ViewPropertyModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        property={selectedProperty}
      />
    </div>
  );
}
