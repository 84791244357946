// src/components/Logout.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearStaffDetails } from '../redux/actions/staffActions';



const Logout = () => {
    const dispatch=useDispatch();
  useEffect(() => {
    // localStorage.removeItem("usertoken");
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    localStorage.removeItem("permissions");
    // localStorage.removeItem("userId");
    dispatch(clearStaffDetails());
    window.location.href = '/'; // Adjust the path as needed
  }, []);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;
