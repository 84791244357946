import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import axios from "axios";
import ArchivedUserTable from "./ArchivedUserTable";
import ViewUserModal from "./ViewArchivedUser";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";

function ArchivedUser() {
  const [archivedUsers, setArchivedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isViewUserModalOpen, setIsViewUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getarchivedusers`);
        if (response.status === 201 && response.data.message === "All Archived user Details Collected") {
          setArchivedUsers(response.data.archived_users || []);
          setFilteredUsers(response.data.archived_users || []);
        } else if (response.status === 200 && response.data.message === "No archived users found") {
          alert(response.data.message);
          setArchivedUsers([]);
          setFilteredUsers([]);
        }
      } catch (error) {
        console.error("Error fetching archived users:", error);
        setArchivedUsers([]);
        setFilteredUsers([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      archivedUsers.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.mobile.includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, archivedUsers]);

  const handleView = (userId) => {
    const selected = archivedUsers.find((user) => user.userId === userId);
    setSelectedUser(selected);
    setIsViewUserModalOpen(true);
  };

  const handleUnarchive = async (userId) => {
    try {
      const response = await axios.put(`${BaseURL}/unarchiveuser/${userId}`);
 
        setArchivedUsers((prevUsers) => prevUsers.filter((user) => user.userId !== userId));
        setFilteredUsers((prevUsers) => prevUsers.filter((user) => user.userId !== userId));
        alert("User unarchived successfully!");
      
    } catch (error) {
      console.error("Error unarchiving user:", error);
      alert("Error unarchiving user!");
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <h4 style={{ marginTop: "0" }}>Archived Users</h4>
                    <div style={{ display: "flex", marginRight: "10px" }}>
                      <div style={{ marginRight: "60px" }}>
                        <input
                          type="text"
                          placeholder="Search by name, mobile, email"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          style={{
                            marginRight: "10px",
                            border: "1px solid black",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            transition: "box-shadow 0.3s ease",
                            width: "200px",
                            fontSize: "16px",
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Link
                          className="btn btn-secondary rounded"
                          to="/users"
                          style={{ marginRight: "10px" }}
                        >
                          Back &nbsp;
                          <i className='fa fa-reply'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <ArchivedUserTable
                    archivedUsers={filteredUsers}
                    handleView={handleView}
                    handleUnarchive={handleUnarchive}
                  />
                  <ViewUserModal
                    isOpen={isViewUserModalOpen}
                    setIsOpen={setIsViewUserModalOpen}
                    user={selectedUser}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchivedUser;
