import { SET_STAFF_DETAILS, CLEAR_STAFF_DETAILS } from "../types/staffTypes";

const initialState = {
  staffDetails: {
    id: null,
    name: "",
    email: "",
  },
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF_DETAILS:
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          ...action.payload,
        },
      };
    case CLEAR_STAFF_DETAILS:
      return {
        ...state,
        staffDetails: {
          id: null,
          name: "",
          email: "",
        },
      };
    default:
      return state;
  }
};

export default staffReducer;
