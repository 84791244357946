import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Modal, TextField } from "@mui/material";
import { format } from "date-fns-tz";

export default function TestimonialList() {
  const [testimonials, setTestimonials] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(null);
  const [editedTestimonial, setEditedTestimonial] = useState({
    id: "",
    name: "",
    description: "",
    rating: "",
    designation: "",
    image: "",
  });
  const [newImage, setNewImage] = useState(null);
  const [newImagePreview, setNewImagePreview] = useState("");

  useEffect(() => {
    fetchTestimonials(page, pageSize);
  }, [page, pageSize]);

  const fetchTestimonials = async (page, pageSize) => {
    try {
      const response = await axios.get(`${BaseURL}/getalltestimonials`, {
        params: { page: page + 1, limit: pageSize },
      });
      setTestimonials(response.data.results);
      setRowCount(response.data.totalResults);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseURL}/deletetestimonial/${id}`);
      fetchTestimonials(page, pageSize);
    } catch (error) {
      console.error("Error deleting testimonial:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), "dd/MM/yyyy hh:mm a", {
      timeZone: "Asia/Kolkata",
    });
  };

  const handleEditModalOpen = (testimonial) => {
    setCurrentTestimonial(testimonial);
    setEditedTestimonial({
      id: testimonial.id,
      name: testimonial.name,
      description: testimonial.description,
      rating: testimonial.rating,
      designation: testimonial.designation,
      image: testimonial.image,
    });
    setNewImage(null);
    setNewImagePreview("");
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setNewImagePreview(URL.createObjectURL(file));
  };

  const handleEditTestimonial = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editedTestimonial.id);
      formData.append("name", editedTestimonial.name);
      formData.append("description", editedTestimonial.description);
      formData.append("rating", editedTestimonial.rating);
      formData.append("designation", editedTestimonial.designation);
      if (newImage) {
        formData.append("image", newImage);
      }

      await axios.put(
        `${BaseURL}/edittestimonial/${editedTestimonial.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchTestimonials(page, pageSize);
      setEditModalOpen(false);
    } catch (error) {
      console.error("Error editing testimonial:", error);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 120 },
    { field: "description", headerName: "Description", width: 150 },
    { field: "rating", headerName: "Rating", width: 80 },
    { field: "designation", headerName: "Designation", width: 200 },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt={params.row.name}
          style={{ width: "50px", borderRadius: "5px" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "5px" }}
            onClick={() => handleEditModalOpen(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>Testimonials</h3>
                    <div
                      style={{
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <Link to="/add-testimonial">
                        <Button variant="contained" color="success">
                          Add Testimonial
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <DataGrid
                      rows={testimonials}
                      columns={columns}
                      pagination
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 20]}
                      rowCount={rowCount}
                      paginationMode="server"
                      onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      autoHeight
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {/* Edit Testimonial Modal */}
      <Modal
        open={editModalOpen}
        onClose={handleEditModalClose}
        aria-labelledby="edit-testimonial-modal"
        aria-describedby="modal-to-edit-testimonial"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            height: "80vh",
            overflowY: "auto",
          }}
        >
          <h2>Edit Testimonial</h2>
          <TextField
            label="Name"
            value={editedTestimonial.name}
            onChange={(e) =>
              setEditedTestimonial({
                ...editedTestimonial,
                name: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={editedTestimonial.description}
            onChange={(e) =>
              setEditedTestimonial({
                ...editedTestimonial,
                description: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
  label="Rating"
  value={editedTestimonial.rating}
  type="number"
  inputProps={{
    min: 1,
    max: 5,
    inputMode: "numeric",
  }}
  onChange={(e) => {
    const ratingValue = parseInt(e.target.value); // Convert input to integer
    if (!isNaN(ratingValue) && ratingValue >= 1 && ratingValue <= 5) {
      setEditedTestimonial({
        ...editedTestimonial,
        rating: ratingValue.toString(), // Convert back to string for consistency
      });
    }
  }}
  fullWidth
  margin="normal"
/>
          <TextField
            label="Designation"
            value={editedTestimonial.designation}
            onChange={(e) =>
              setEditedTestimonial({
                ...editedTestimonial,
                designation: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ marginTop: "20px" }}
          />
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div>
              <h4>Current Image:</h4>
              <img
                src={editedTestimonial.image}
                alt={editedTestimonial.name}
                style={{ width: "100px", borderRadius: "5px" }}
              />
            </div>
            {newImagePreview && (
              <div style={{ marginLeft: "20px" }}>
                <h4>New Image:</h4>
                <img
                  src={newImagePreview}
                  alt="New preview"
                  style={{ width: "100px", borderRadius: "5px" }}
                />
              </div>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditTestimonial}
            style={{ marginTop: "20px" }}
          >
            Save Changes
          </Button>
        </div>
      </Modal>
    </div>
  );
}
