import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Tooltip } from "@mui/material";

function Propertiestable({
  propertiesArray,
  handleEdit,
  handleDelete,
  handleStatusChange,
  handleView,
  handleApprovalChange,
  handleFeaturedChange,
}) {
  // Add serialId to each property object
  const propertiesWithSerialId = propertiesArray.map((property, index) => ({
    ...property,
    serialId: index + 1,
  }));

  const columns = [
    { field: "serialId", headerName: "Sr. ID", width: 70 },
    { field: "title", headerName: "Title", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    { field: "state", headerName: "State", width: 130 },
    { field: "country", headerName: "Country", width: 70 },
    { field: "price", headerName: "Price", type: "number", width: 130 },
    {
      field: "property_type",
      headerName: "Property Type",
      width: 150,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          {params.value === 0 && "Residential"}
          {params.value === 1 && "Commercial"}
          {params.value === 2 && "Plot"}
        </div>
      ),
    },
    {
      field: "featured",
      headerName: "Featured",
      width: 100,
      renderCell: (params) => (
        <Switch
          checked={params.row.featured === 1}
          onChange={() =>
            handleFeaturedChange(params.row.id, params.row.featured)
          }
          color="primary"
        />
      ),
    },
    {
      field: "approval",
      headerName: "Approval",
      width: 100,
      renderCell: (params) => (
        <Switch
          checked={params.row.approval === 1}
          onChange={() =>
            handleApprovalChange(params.row.id, params.row.approval)
          }
          color="primary"
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Switch
          checked={params.row.status === 1}
          onChange={() => handleStatusChange(params.row.id, params.row.status)}
          color="primary"
        />
      ),
    },
    {
      field: "reject",
      headerName: "Reject",
      width: 120,
      renderCell: (params) => {
        if (params.row.status === -2) {
          return (
            <Tooltip title={params.row.reject_reson || "No reason provided"}>
              <span style={{ color: "red", cursor: "pointer" }}>Rejected</span>
            </Tooltip>
          );
        }
        return null;
      },
    },
    {
      field: "actions",
      headerName: "Actions(View/Edit/Archived)",
      width: 380,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          <IconButton color="primary" onClick={() => handleView(params.row.id)}>
            <Button variant="outlined" color="success" startIcon={<VisibilityIcon />}>
              View
            </Button>
          </IconButton>
          <IconButton color="primary" onClick={() => handleEdit(params.row.id)}>
            <Button variant="outlined" startIcon={<EditIcon />}>
              Edit
            </Button>
          </IconButton>
          <IconButton color="error" onClick={() => handleDelete(params.row.id)}>
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />}>
              Archive
            </Button>
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={propertiesWithSerialId}
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default Propertiestable;
