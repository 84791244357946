import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { Button, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";

export default function About() {
  const [teamPhotos, setTeamPhotos] = useState([]);
  const [officePhotos, setOfficePhotos] = useState([]);
  const [expertiesPhotos, setExpertiesPhotos] = useState([]);
  const [selectedTeamFiles, setSelectedTeamFiles] = useState([]);
  const [selectedOfficeFiles, setSelectedOfficeFiles] = useState([]);
  const [selectedExpertiesFiles, setSelectedExpertiesFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchImages("team", setTeamPhotos);
    fetchImages("office", setOfficePhotos);
    fetchImages("expertise", setExpertiesPhotos);
  }, []);

  const fetchImages = async (type, setImages) => {
    try {
      const response = await axios.get(`${BaseURL}/get/${type}/photos`);
      setImages(response.data.results || []);  
      console.log(response.data.results);
    } catch (error) {
      console.error(`Error fetching ${type} photos:`, error);
      setImages([]);  // Set to an empty array in case of error
    }
  };

  const handleFileChange = (e, setFiles) => {
    setFiles([...e.target.files]);
  };

  const handleUpload = async (type, files, setImages) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("photos", file);
    });
  
    try {
      setIsSubmitting(true);
      await axios.post(`${BaseURL}/upload/${type}/photos`, formData);
      alert("Images Uploaded Successfully");
      fetchImages(type, setImages);
      // Clear selected files state after successful upload
      if (type === 'team') {
        setSelectedTeamFiles([]);
      } else if (type === 'office') {
        setSelectedOfficeFiles([]);
      } else if (type === 'expertise') {
        setSelectedExpertiesFiles([]);
      }
    } catch (error) {
      console.error(`Error uploading ${type} photos:`, error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleDelete = async (type, id, setImages) => {
    try {
      await axios.delete(`${BaseURL}/delete/${type}/photo/${id}`);
      fetchImages(type, setImages);
    } catch (error) {
      console.error(`Error deleting ${type} photo:`, error);
    }
  };

  const renderPhotos = (photos, type, setImages) => (
    <Grid container spacing={2}>
      {photos && photos.map((photo) => (
        <Grid item key={photo.id} xs={4}>
          <div style={{ position: "relative" }}>
            <img src={photo.images_array} style={{ width: "100%", borderRadius: "8px" }} />
            <IconButton
              color="secondary"
              onClick={() => handleDelete(type, photo.id, setImages)}
              style={{ position: "absolute", top: "0px", right: "1px", background: "rgba(255, 255, 255, 0.8)", fontSize:'12px' }}
            >
              {/* <DeleteIcon /> */}
        X
            </IconButton>
          </div>
        </Grid>
      ))}
    </Grid>
  );

  const renderUploadSection = (title, files, setFiles, photos, type, setImages, backgroundColor) => (
    <div style={{ flex: 1, margin: "0 10px", backgroundColor, padding: "10px", borderRadius: "8px" }}>
      <h4>{title}</h4>
      <input
        type="file"
        multiple
        onChange={(e) => handleFileChange(e, setFiles)}
        style={{
          margin: "10px 0",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          display: "block",
          maxWidth: "250px",
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleUpload(type, files, setImages)}
        style={{
          display: "block",
          marginBottom: "20px",
          backgroundColor: "#3f51b5",
          color: "#fff",
        }}
        disabled={isSubmitting}
      >
        Upload
        
      </Button>
      {files.length > 0 && (
        <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
          {Array.from(files).map((file, index) => (
            <div key={index} style={{ margin: "5px" }}>
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "8px" }}
              />
            </div>
          ))}
        </div>
      )}
      {renderPhotos(photos, type, setImages)}
    </div>
  );

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
                  {renderUploadSection("Upload Team Photos", selectedTeamFiles, setSelectedTeamFiles, teamPhotos, "team", setTeamPhotos, "#f8d7da")}
                  <div style={{ width: "2px", backgroundColor: "#ccc", margin: "0 10px" }}></div>
                  {renderUploadSection("Upload Office Photos", selectedOfficeFiles, setSelectedOfficeFiles, officePhotos, "office", setOfficePhotos, "#d4edda")}
                  <div style={{ width: "2px", backgroundColor: "#ccc", margin: "0 10px" }}></div>
                  {renderUploadSection("Upload Experties Photos", selectedExpertiesFiles, setSelectedExpertiesFiles, expertiesPhotos, "expertise", setExpertiesPhotos, "#d1ecf1")}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
