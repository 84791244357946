import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { BaseURL } from "../../BaseUrl";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PaymentChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Payment",
        data: [],
        backgroundColor: "#42A5F5",
        borderColor: "#1E88E5",
        borderWidth: 1,
      },
      {
        label: "Total Refund",
        data: [],
        backgroundColor: "#FF7043",
        borderColor: "#FF5722",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getMonthlyPaymentChart`);
        if (response.data.success) {
          const data = response.data.data;

          // Create a list of all 12 months
          const allMonths = Array.from({ length: 12 }, (_, i) => {
            const month = new Date(2024, i, 1).toISOString().slice(0, 7); // Format: YYYY-MM
            return month;
          });

          // Map data to month and value
          const monthDataMap = new Map();
          data.forEach(item => {
            monthDataMap.set(item.month, {
              totalPayment: parseFloat(item.totalPayment),
              totalRefund: parseFloat(item.totalRefund),
            });
          });

          // Fill chart data
          const labels = allMonths;
          const totalPayments = allMonths.map(month => monthDataMap.get(month)?.totalPayment || 0);
          const totalRefunds = allMonths.map(month => monthDataMap.get(month)?.totalRefund || 0);

          setChartData({
            labels,
            datasets: [
              {
                label: "Total Payment",
                data: totalPayments,
                backgroundColor: "#42A5F5",
                borderColor: "#1E88E5",
                borderWidth: 1,
              },
              {
                label: "Total Refund",
                data: totalRefunds,
                backgroundColor: "#FF7043",
                borderColor: "#FF5722",
                borderWidth: 1,
              },
            ],
          });
        } else {
          console.error("Failed to fetch chart data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();
  }, []);


  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "90%",
    // maxWidth: "600px",
  };

  const headingStyle = {
    marginBottom: "20px",
    color: "#333",
    fontSize: "24px",
    fontWeight: "bold",
  };


  return (
    <div style={containerStyle}>
      <h4>Monthly Payment and Refund</h4>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.dataset.label || "";
                  const value = context.raw;
                  return `${label}: ₹${value.toFixed(2)}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Month",
              },
              grid: {
                display: false, // Hide grid lines
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount",
              },
              beginAtZero: true,
              ticks: {
                callback: function(value) {
                  return `₹${value.toFixed(2)}`;
                }
              },
              grid: {
                display: false, // Hide grid lines
              },
            },
          },
        }}
      />
    </div>
  );
};

export default PaymentChart;
