import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Editor } from 'primereact/editor';
import { Link } from "react-router-dom";

export default function AddPackages() {
  const [name, setName] = useState('');
  const [actualPrice, setActualPrice] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [description, setDescription] = useState('');
  const [ip, setIp] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch the client's IP address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIp(response.data.ip);
      })
      .catch(error => {
        console.error('There was an error fetching the IP address!', error);
      });
  }, []);

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "This field is required.";
    tempErrors.actualPrice = actualPrice ? "" : "This field is required.";
    tempErrors.discountedPrice = discountedPrice ? "" : "This field is required.";
    tempErrors.description = description ? "" : "This field is required.";
    if (actualPrice && discountedPrice && Number(discountedPrice) > Number(actualPrice)) {
      tempErrors.discountedPrice = "Discounted price cannot be greater than actual price.";
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const newPackage = {
        name: name,
        actual_price: actualPrice,
        discounted_price: discountedPrice,
        description: description,
        ip: ip
      };

      axios.post(`${BaseURL}/addpackage`, newPackage)
        .then(response => {
          alert('Package added successfully!');
          // Reset form fields
          setName('');
          setActualPrice('');
          setDiscountedPrice('');
          setDescription('');
          setErrors({});
        })
        .catch(error => {
          console.error('There was an error adding the package!', error);
        });
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}>
                    <h4 className="card-title">Add New Package</h4>
                    <Link to="/packages" className="btn btn-secondary">Back</Link>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Package Name/Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      {errors.name && <div className="error" style={{ color: "red" }}>{errors.name}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="actualPrice">Actual Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="actualPrice"
                        value={actualPrice}
                        onChange={(e) => setActualPrice(e.target.value)}
                        required
                      />
                      {errors.actualPrice && <div className="error" style={{ color: "red" }}>{errors.actualPrice}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="discountedPrice">Discounted Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="discountedPrice"
                        value={discountedPrice}
                        onChange={(e) => setDiscountedPrice(e.target.value)}
                        required
                      />
                      {errors.discountedPrice && <div className="error" style={{ color: "red" }}>{errors.discountedPrice}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <Editor
                        style={{ height: '320px' }}
                        value={description}
                        onTextChange={(e) => setDescription(e.htmlValue)}
                      />
                      {errors.description && <div className="error" style={{ color: "red" }}>{errors.description}</div>}
                    </div>
                    <button type="submit" className="btn btn-primary mt-3">Add Package</button>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
