import React, { useState } from 'react';
import { BaseURL } from './BaseUrl';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const notify = (message, type = 'success') => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BaseURL}/enterstaffsemail`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setOtpSent(true);
        notify('OTP sent to your email');
      } else {
        notify('Failed to send OTP. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      notify('Error sending OTP. Please try again later.', 'error');
    }
  };

  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BaseURL}/enterstaffsotp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp }),
      });
      if (response.ok) {
        setOtpVerified(true);
        notify('OTP verified successfully');
      } else {
        notify('Invalid OTP. Please enter the correct OTP.', 'error');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      notify('Error verifying OTP. Please try again later.', 'error');
    }
  };

  const handleSubmitResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      notify('Passwords do not match. Please re-enter your passwords.', 'error');
      return;
    }
    try {
      const response = await fetch(`${BaseURL}/enterstaffsnewpassword`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPassword }),
      });
      if (response.ok) {
        notify('Password reset successfully');
        alert("Password Reset Sucessfully")
        navigate("/");
      } else {
        notify('Failed to reset password. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      notify('Error resetting password. Please try again later.', 'error');
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f8f9fa' }}>
      <div className="forgot-password-form bg-white p-4 rounded" style={{ minHeight: '40vh', width: '300px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <ToastContainer />
        {!otpSent ? (
          <form onSubmit={handleSubmitEmail} className="text-center">
            <h2 className="mb-4">Forgot Password</h2>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Send OTP
            </button>
          </form>
        ) : otpVerified ? (
          <form onSubmit={handleSubmitResetPassword} className="text-center">
            <h2 className="mb-4">Reset Password</h2>
            <p>{email}</p>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Reset Password
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmitOTP} className="text-center">
            <h2 className="mb-4">Enter OTP</h2>
            <p>{email}</p>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                maxLength="6"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Verify OTP
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
