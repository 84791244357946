import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import RestoreIcon from "@mui/icons-material/Restore";
import { Button } from "@mui/material";

function ArchivedTable({ archivedStaffs, handleView, handleUnarchive }) {
  const columns = [
    { field: "serialId", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 160 },
    { field: "address", headerName: "Address", width: 160 },
    {
      field: "actions",
      headerName: "Action",
      width: 180,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="primary"
            onClick={() => handleUnarchive(params.row.staffId)}
          >
            
            <Button variant="outlined" color="success" startIcon={<RestoreIcon />}>
              UnArchive
            </Button>

          </IconButton>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => handleUnarchive(params.row.staffId)}
            style={{ marginLeft: 16 }}
          >
            Unarchive
          </Button> */}
        </div>
      ),
    },
  ];

  // Add serialId to each archived staff object
  const archivedStaffsWithSerialId = archivedStaffs.map((staff, index) => ({
    ...staff,
    serialId: index + 1,
  }));

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={archivedStaffsWithSerialId} // Use archivedStaffsWithSerialId with serialId added
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default ArchivedTable;
