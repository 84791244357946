import React from 'react'

function DeletePropertyModal({ isOpen, setIsOpen, property, handleDelete }) {
  const handleConfirmDelete = async () => {
    if (property && property.id) {
      await handleDelete(property.id);
      setIsOpen(false); // Close the modal after deletion
    }
  };
  return (
    <div
    className={`modal ${isOpen ? "show" : ""}`}
    style={{ display: isOpen ? "block" : "none" }}
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Confirm Deletion</h5>
          <button
            type="button"
            className="close"
            onClick={() => setIsOpen(false)}
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "#000",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {property && (
            <p>
              Are you sure you want to delete <strong>{property.title}</strong>?
            </p>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleConfirmDelete}
            disabled={!property || !property.id}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default DeletePropertyModal