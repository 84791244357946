// GalleryImages.jsx

import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { Button, Grid, IconButton } from "@mui/material";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";

export default function GalleryImages() {
  const [galleryPhotos, setGalleryPhotos] = useState([]);
  const [selectedGalleryFiles, setSelectedGalleryFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchImages(setGalleryPhotos);
  }, []);

  const fetchImages = async (setImages) => {
    try {
      const response = await axios.get(`${BaseURL}/getallimagesfromgallery`);
      setImages(response.data.results || []);
    } catch (error) {
      console.error(`Error fetching gallery photos:`, error);
      setImages([]);
    }
  };

  const handleFileChange = (e) => {
    setSelectedGalleryFiles([...e.target.files]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    selectedGalleryFiles.forEach((file) => {
      formData.append("photos", file);
    });

    try {
        setIsSubmitting(true);
      await axios.post(`${BaseURL}/addimagesintogallery`, formData);
      alert("Images Uploaded Successfully");
      fetchImages(setGalleryPhotos);
      setSelectedGalleryFiles([]);
    } catch (error) {
      console.error(`Error uploading gallery photos:`, error);
    }finally {
        setIsSubmitting(false);
      };
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseURL}/deleteimagefromgallery/${id}`);
      fetchImages(setGalleryPhotos);
    } catch (error) {
      console.error(`Error deleting gallery photo:`, error);
    }
  };

  const renderPhotos = (photos) => (
    <Grid container spacing={2}>
      {photos && photos.map((photo) => (
        <Grid item key={photo.id} xs={4}>
          <div style={{ position: "relative" }}>
            <img src={photo.image} style={{ width: "100%", borderRadius: "8px" }} />
            <IconButton
              color="secondary"
              onClick={() => handleDelete(photo.id)}
              style={{ position: "absolute", top: "0px", right: "1px", background: "rgba(255, 255, 255, 0.8)", fontSize: '12px' }}
            >
              X
            </IconButton>
          </div>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
                  <div style={{ flex: 1, margin: "0 10px", padding: "10px", borderRadius: "8px" }}>
                    <h4>Upload Gallery Photos</h4>
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      style={{
                        margin: "10px 0",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        display: "block",
                        maxWidth: "250px",
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpload}
                      style={{
                        display: "block",
                        marginBottom: "20px",
                        backgroundColor: "#3f51b5",
                        color: "#fff",
                      }}
                      disabled={isSubmitting}
                    >
                     {isSubmitting ? "Submitting..." : "Upload"}
                      
                    </Button>
                    {selectedGalleryFiles.length > 0 && (
                      <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                        {Array.from(selectedGalleryFiles).map((file, index) => (
                          <div key={index} style={{ margin: "5px" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "8px" }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {renderPhotos(galleryPhotos)}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
