import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import profileImage from "../assets/images/faces/face1.jpg";
import logo from "../assets/images/logo.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStaffDetails,
  setStaffDetails,
} from "../../../redux/actions/staffActions";
import { BaseURL } from "../../BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import Notification from "./Notification";
import  Logout from '../../Logout';

export default function Header() {
  const dispatch = useDispatch();
  const staffDetails = useSelector((state) => state.staff.staffDetails);
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState("");
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [isOffcanvasVisible, setIsOffcanvasVisible] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  const handleSignOut = () => {
    performLogout(dispatch, navigate);
  };

  const performLogout = (dispatch, navigate) => {
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    localStorage.removeItem("permissions");
    dispatch(clearStaffDetails());
    navigate("/");
  };


  const toggleSidebar = () => {
    setIsSidebarMinimized(!isSidebarMinimized);
    document.body.classList.toggle("sidebar-icon-only");
  };

  const toggleOffcanvas = () => {
    const offcanvasElement = document.getElementById("sidebar");
    if (offcanvasElement) {
      const bsOffcanvas =
        window.bootstrap.Offcanvas.getOrCreateInstance(offcanvasElement);
      bsOffcanvas.toggle();
    }
    setIsOffcanvasVisible(!isOffcanvasVisible);
  };

  useEffect(() => {
    const decodeStaffToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken && decodedToken._id) {
            dispatch(setStaffDetails({ id: decodedToken._id }));
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };
    decodeStaffToken();
  }, [dispatch]);

  useEffect(() => {
    const getStaffName = async () => {
      if (staffDetails?.id) {
        try {
          const response = await axios.get(
            `${BaseURL}/getStaffNameInHeader/${staffDetails.id}`
          );
          if (
            response.status === 200 &&
            response.data.message === "Staff details found successfully"
          ) {
            dispatch(
              setStaffDetails({
                name: response.data.staffs.name,
                email: response.data.staffs.email,
              })
            );
          } else {
            console.error("Error fetching staff details:", response.data.message);
            // performLogout(dispatch, navigate); 
          }
        } catch (error) {
          console.error("Error fetching staff details:", error);
          performLogout(dispatch, navigate); 
        }
      } else {
        
        // performLogout(dispatch, navigate); 
      }
    };

    getStaffName();
  }, [staffDetails?.id, dispatch, navigate]);


  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good Morning");
      } else if (currentHour < 18) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    updateGreeting();
  }, []);

  useEffect(() => {
    const fetchLogoDetails = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getlogodetails`);
        console.log(response); // Log the entire response to debug
        if (response.status === 200 && response.data.success) {
          console.log("Logo URL:", response.data.results[0].logo); // Log the logo URL to verify
          setLogoUrl(response.data.results[0].logo);
        } else {
          console.error("Failed to fetch logo details:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching logo details:", error);
      }
    };

    fetchLogoDetails();
  }, []);

  // Ensure that offcanvas is closed when the component mounts
  useEffect(() => {
    const offcanvasElement = document.getElementById("sidebar");
    if (offcanvasElement) {
      const bsOffcanvas =
        window.bootstrap.Offcanvas.getOrCreateInstance(offcanvasElement);
      if (isOffcanvasVisible) {
        bsOffcanvas.hide();
      }
    }
  }, [isOffcanvasVisible]);

  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <div className="me-3">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={toggleSidebar}
          >
            <span className="icon-menu" />
          </button>
        </div>
        <div>
          <a className="navbar-brand brand-logo" href="#">
            {logoUrl ? (
              <img src={logoUrl} alt="logo" />
            ) : (
              <div>Loading logo...</div>
            )}
          </a>
          <a className="navbar-brand brand-logo-mini" href="/">
            {/* <img src="assets/images/logo-mini.svg" alt="logo" /> */}
          </a>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-top">
        <ul className="navbar-nav">
          {staffDetails && staffDetails.name && (
            <li className="nav-item fw-semibold d-none d-lg-block ms-0">
              <h2>
                {greeting},{" "}
                <span className="text-black fw-bold">{staffDetails.name}</span>
              </h2>
              <h3 className="welcome-sub-text">
                Your performance summary this week
              </h3>
            </li>
          )}
        </ul>
        {/* <Notification/> */}
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown d-none d-lg-block user-dropdown">
            <a
              className="nav-link"
              id="UserDropdown"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src={profileImage}
                alt="Profile image"
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              {staffDetails && staffDetails.id && (
                <div className="dropdown-header text-center">
                  <img
                    className="img-md rounded-circle"
                    src={profileImage}
                    alt="Profile image"
                  />
                  {staffDetails.name && (
                    <p className="mb-1 mt-3 fw-semibold">{staffDetails.name}</p>
                  )}
                  <p className="fw-light text-muted mb-0">
                    {staffDetails.email}
                  </p>
                </div>
              )}
              <Link className="dropdown-item" to="/profile">
                <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2" />
                My Profile
              </Link>
              <button className="dropdown-item" onClick={handleSignOut}>
                <i className="dropdown-item-icon mdi mdi-power text-primary me-2" />
                Sign Out
              </button>
            </div>
          </li>
        </ul>

        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebar"
          aria-controls="sidebar"
          aria-expanded={isOffcanvasVisible}
          aria-label="Toggle navigation"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}
