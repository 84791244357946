import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import axios from "axios";
import Propertiestable from "./Propertiestable";
import DeletePropertyModal from "./DeletePropertyModal";
import ViewPropertyModal from "./ViewPropertyModal";
import Footer from "../common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { BaseURL } from "../../BaseUrl";

function Properties() {
  const [propertiesArray, setPropertiesArray] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isDeletePropertyModalOpen, setIsDeletePropertyModalOpen] =
    useState(false);
  const [isViewPropertyModalOpen, setIsViewPropertyModalOpen] = useState(false);
  const [propertyTypeFilter, setPropertyTypeFilter] = useState("All");
  const navigate = useNavigate();

  
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getAllproperties`);
        if (
          response.status === 201 &&
          response.data.message === "Property Details collected successfully"
        ) {
          setPropertiesArray(response.data.properties);
          setFilteredProperties(response.data.properties);
        } else if (
          response.status === 200 &&
          response.data.message === "No properties found"
        ) {
          alert(response.data.message);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 500 &&
          error.response.data.message === "Failed to fetch properties"
        ) {
          alert(error.response.data.message);
        }
        console.error("Error fetching properties:", error);
      }
    };
    useEffect(() => {

    fetchData();
  }, []);

  const handleEdit = (propertyId) => {
    const selected = propertiesArray.find(
      (property) => property.id === propertyId
    );
    if (selected) {
      setSelectedProperty(selected);
      navigate(`/editProperty/${propertyId}`);
    } else {
      console.error(`Property with ID ${propertyId} not found.`);
    }
  };

  const handleView = (propertyId) => {
    const selected = propertiesArray.find(
      (property) => property.id === propertyId
    );
    setSelectedProperty(selected);
    setIsViewPropertyModalOpen(true);
  };

  const handleAddProperty = () => {
    navigate("/registerProperty");
  };

  const handleDeletePropertyModalOpen = (propertyId) => {
    const selected = propertiesArray.find(
      (property) => property.id === propertyId
    );
    setSelectedProperty(selected);
    setIsDeletePropertyModalOpen(true);
  };

  const handleFeaturedChange = async (propertyId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      const response = await axios.post(`${BaseURL}/changetofeatured`, {
        id: propertyId,
        featured: newStatus,
      });
      if (
        response.status === 201 &&
        response.data.message ===
          "Property featured status updated successfully"
      ) {
        setPropertiesArray(
          propertiesArray.map((property) =>
            property.id === propertyId
              ? { ...property, featured: newStatus }
              : property
          )
        );
        setFilteredProperties(
          filteredProperties.map((property) =>
            property.id === propertyId
              ? { ...property, featured: newStatus }
              : property
          )
        );
        alert("Property status updated successfully!");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Error updating property status!");
    }
  };

  const handleApprovalChange = async (propertyId, currentApprovalStatus) => {
    const newApprovalStatus = currentApprovalStatus === 1 ? 0 : 1;
    const newStatus = newApprovalStatus === 1 ? 1 : 0; // Change status only if approval is 1

    try {
      const response = await axios.post(`${BaseURL}/changetoapprovalstatus`, {
        id: propertyId,
        approval: newApprovalStatus,
      });
      if (
        response.status === 201 &&
        response.data.message ===
          "Property approval status updated successfully"
      ) {
        setPropertiesArray(
          propertiesArray.map((property) =>
            property.id === propertyId
              ? {
                  ...property,
                  approval: newApprovalStatus,
                  ...(newStatus !== undefined && { status: newStatus }),
                }
              : property
          )
        );
        setFilteredProperties(
          filteredProperties.map((property) =>
            property.id === propertyId
              ? {
                  ...property,
                  approval: newApprovalStatus,
                  ...(newStatus !== undefined && { status: newStatus }),
                }
              : property
          )
        );
        alert("Property approval status updated successfully!");
        fetchData(); 
      }
    } catch (error) {
      console.error("Error updating approval status:", error);
      alert("Error updating property approval status!");
    }
  };

  const handleStatusChange = async (propertyId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
        const response = await axios.put(
            `${BaseURL}/changepropertystatus`, {
                id: propertyId,
                status: newStatus
            }
        );
        if (response.status === 201) {
            setPropertiesArray(propertiesArray.map(property =>
                property.id === propertyId ? { ...property, status: newStatus } : property
            ));
            setFilteredProperties(filteredProperties.map(property =>
                property.id === propertyId ? { ...property, status: newStatus } : property
            ));
            alert("Property status updated successfully!");
        } else {
            console.error("Unexpected response:", response);
            alert("Failed to update property status. Please try again later.");
        }
    } catch (error) {
        console.error("Error updating status:", error);
        alert("Error updating property status!");
    }
};

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterProperties(query, propertyTypeFilter);
  };

  const handlePropertyTypeChange = (event) => {
    const selectedType = event.target.value;
    setPropertyTypeFilter(selectedType);
    filterProperties(searchQuery, selectedType);
  };

  const filterProperties = (query, type) => {
    let filtered = propertiesArray;
    if (type !== "All") {
      filtered = filtered.filter(
        (property) => property.property_type === parseInt(type)
      );
    }
    if (query) {
      filtered = filtered.filter(
        (property) =>
          property.title.toLowerCase().includes(query) ||
          property.address.toLowerCase().includes(query) ||
          property.state.toLowerCase().includes(query) ||
          property.country.toLowerCase().includes(query)
      );
    }
    setFilteredProperties(filtered);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>Property Management</h4>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        placeholder="Search properties"
                        value={searchQuery}
                        onChange={handleSearch}
                        style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "200px", 
                          fontSize: "16px",
                        }}
                      />
                      <select
                        value={propertyTypeFilter}
                        onChange={handlePropertyTypeChange}
                        style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "150px", 
                          fontSize: "16px",
                        }}
                      >
                        <option value="All">All</option>
                        <option value="0">Residential</option>
                        <option value="1">Commercial</option>
                        {/* <option value="2">Plot</option> */}
                      </select>
                      <button
                        className="btn btn-primary rounded"
                        onClick={handleAddProperty}
                        style={{ marginRight: "10px" }}
                      >
                        Add Property
                      </button>
                      <Link
                        to="/archivedproperties"
                        className="btn btn-primary rounded"
                      >
                        Archive
                      </Link>
                    </div>
                  </div>
                  <Propertiestable
                    propertiesArray={filteredProperties}
                    handleEdit={handleEdit}
                    handleDelete={handleDeletePropertyModalOpen}
                    handleFeaturedChange={handleFeaturedChange}
                    handleView={handleView}
                    handleApprovalChange={handleApprovalChange}
                    handleStatusChange={handleStatusChange}
                  />
                  <DeletePropertyModal
                    isOpen={isDeletePropertyModalOpen}
                    setIsOpen={setIsDeletePropertyModalOpen}
                    property={selectedProperty}
                    handleDelete={async (propertyId) => {
                      try {
                        const response = await axios.put(
                          `${BaseURL}/movetoarchieved/${propertyId}`
                        );
                        if (response.status === 200) {
                          setPropertiesArray(
                            propertiesArray.filter(
                              (property) => property.id !== propertyId
                            )
                          );
                          setFilteredProperties(
                            filteredProperties.filter(
                              (property) => property.id !== propertyId
                            )
                          );
                          setIsDeletePropertyModalOpen(false);
                        } else {
                          console.error(
                            "Error deleting property:",
                            response.data.message
                          );
                        }
                      } catch (error) {
                        console.error("Error deleting property:", error);
                      }
                    }}
                  />
                  <ViewPropertyModal
                    isOpen={isViewPropertyModalOpen}
                    setIsOpen={setIsViewPropertyModalOpen}
                    property={selectedProperty}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Properties;
