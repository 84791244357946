import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import Userstable from "./Userstable";
import EditUserModal from "./EditUserModal";
import DeleteModal from "./DeleteModal";
import AddUserModal from "./AddUserModal";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";

export default function Users() {
  const [usersArray, setUsersArray] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BaseURL}/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (
        response.status === 201 &&
        response.data.message === "All User Details Collected"
      ) {
        setUsersArray(response.data.users);
        setFilteredUsers(response.data.users);
      } else if (
        response.status === 200 &&
        response.data.message === "No users found"
      ) {
        alert(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 500 &&
        error.response.data.message === "Internal server error"
      ) {
        alert(error.response.data.message);
      } else {
        console.error("Error fetching users:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  const handleEdit = (userId) => {
    const selected = usersArray.find((user) => user.id == userId);
    setSelectedUser(selected);
    setIsEditModalOpen(true);
  };


  const handleAddUser = () => {
    setIsAddUserModalOpen(true);
  };

  const handleSave = async (updatedUser) => {
    console.log(updatedUser,"---updatedUser-------");
    try {
      const response = await axios.put(`${BaseURL}/editUser`, updatedUser);
      if (
        response.status === 201 &&
        response.data.message === "User details updated successfully"
      ) {
        const updatedUsers = usersArray.map((user) =>
          user.id === updatedUser.id ? { ...user, ...updatedUser } : user
        );
        console.log(updatedUsers,"----------------------");
        setUsersArray(updatedUsers);
        setIsEditModalOpen(false);
      } else {
        console.error("Error updating user:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDeleteModalOpen = (userId) => {
    const selected = usersArray.find((user) => user.id === userId);
    setSelectedUser(selected);
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    const filtered = usersArray.filter((user) => {
      const searchString = searchTerm.toLowerCase();
      return (
        user.name.toLowerCase().includes(searchString) ||
        user.email.toLowerCase().includes(searchString) ||
        user.mobile.toLowerCase().includes(searchString)
      );
    });
    setFilteredUsers(filtered);
  }, [searchTerm, usersArray]);

  return (
    <div>
      <div className={`container-scroller ${isEditModalOpen ? 'blur-background' : ''}`}>
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>User Management</h4>
                    <div>
                      <input
                        type="text"
                        placeholder="Search Users"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "200px", 
                          fontSize: "16px",
                        }}
                      />
                      <button
                        className="btn btn-primary rounded"
                        onClick={handleAddUser}
                        style={{ marginRight: "10px" }}
                      >
                        Add User
                      </button>
                      <Link
                        to="/archivedusers"
                        className="btn btn-primary rounded"
                      >
                        Archive Users
                      </Link>
                    </div>
                  </div>

                  <AddUserModal
                    isOpen={isAddUserModalOpen}
                    onClose={() => setIsAddUserModalOpen(false)}
                    setUsersArray={setUsersArray}
                    fetchData={fetchData}
                  />

                  <Userstable
                    usersArray={filteredUsers}
                    handleEdit={handleEdit}
                    handleDelete={handleDeleteModalOpen}
                    setUsersArray={setUsersArray}
                  />

                  <EditUserModal
                    isOpen={isEditModalOpen}
                    setIsEditModalOpen={setIsEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    user={selectedUser}
                    handleSave={handleSave}
                  />
                  <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setIsOpen={setIsDeleteModalOpen}
                    user={selectedUser}
                    handleDelete={async (userId) => {
                      try {
                        const response = await axios.put(
                          `${BaseURL}/archiveuser/${userId}`
                        );
                        if (
                          response.status === 200 &&
                          response.data.message ===
                            "User deleted from users table and archived successfully"
                        ) {
                          const updatedUsers = usersArray.filter(
                            (user) => user.id !== userId
                          );
                          setUsersArray(updatedUsers);
                          setIsDeleteModalOpen(false);
                        } else {
                          console.error(
                            "Error Archive user:",
                            response.data.message
                          );
                        }
                      } catch (error) {
                        console.error("Error Archive user:", error);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
