import React, { useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";

export default function AddTeams() {
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    description: "",
    linkedin_url: "",
    twitter_url: "",
    facebook_url: "",
    instagram_url: "",
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("role", formData.role);
    data.append("description", formData.description);
    data.append("linkedin_url", formData.linkedin_url);
    data.append("twitter_url", formData.twitter_url);
    data.append("facebook_url", formData.facebook_url);
    data.append("instagram_url", formData.instagram_url);
    if (formData.image) {
      data.append("image", formData.image);
    }

    try {
      const response = await axios.post(`${BaseURL}/teammemberdetails`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Team member added successfully:", response.data);
      alert("Team member added successfully");
      // Reset form after successful submission
      setFormData({
        name: "",
        role: "",
        description: "",
        linkedin_url: "",
        twitter_url: "",
        facebook_url: "",
        instagram_url: "",
        image: null,
      });
      setImagePreview(null);
    } catch (error) {
      console.error("Error adding team member:", error);
    }finally {
      setIsSubmitting(false);
    };
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                <div style={{ display: "flex", justifyContent:"space-between" }}> 
                <h3>Add Team Members</h3>
                <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        className="btn btn-secondary rounded"
                        to="/teams"
                        style={{ marginRight: "10px" }}
                      >
                      Back &nbsp;
                       <i class='fa fa-reply'></i>
                        
                      </Link>
                     
                     
                    </div>
                </div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <input
                        type="text"
                        className="form-control"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="linkedin_url">LinkedIn URL</label>
                      <input
                        type="url"
                        className="form-control"
                        id="linkedin_url"
                        name="linkedin_url"
                        value={formData.linkedin_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="twitter_url">Twitter URL</label>
                      <input
                        type="url"
                        className="form-control"
                        id="twitter_url"
                        name="twitter_url"
                        value={formData.twitter_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="facebook_url">Facebook URL</label>
                      <input
                        type="url"
                        className="form-control"
                        id="facebook_url"
                        name="facebook_url"
                        value={formData.facebook_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="instagram_url">Instagram URL</label>
                      <input
                        type="url"
                        className="form-control"
                        id="instagram_url"
                        name="instagram_url"
                        value={formData.instagram_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="image">Image</label>
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        required
                      />
                      {imagePreview && (
                        <div className="mt-2">
                          <img
                            src={imagePreview}
                            alt="Image Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      
                      {isSubmitting ? "Submitting..." : "Add Team Member"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <Footer />
      </div>
    </div>
  );
}
