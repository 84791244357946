// import logo from './logo.svg';
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// -------------------------------------Admin Pages--------------------------------------------------------------------------
import Dashboard from "./components/AdminSide/dashboard/Dashboard";
import Staffs from "./components/AdminSide/staffs/Staffs";
import Settings from "./components/AdminSide/settings/Settings";
import Login from "./components/AdminSide/login/Login";
import Profile from "./components/AdminSide/profile/Profile";
import Register from "./components/AdminSide/register/Register";
import Properties from "./components/AdminSide/properties/Properties";
import RegisterProperty from "./components/AdminSide/properties/RegisterProperty";
import EditProperty from "./components/AdminSide/properties/EditProperty";
import Users from './components/AdminSide/users/Users';
import UserProfile from "./components/AdminSide/users/UserProfile";
import LoadProperties from "./components/AdminSide/users/LoadProperties";
import Brokers from "./components/AdminSide/brokers/Brokers";
import Services from "./components/AdminSide/service/Services";
import Tickets from "./components/AdminSide/tickets/Tickets";
import AdminChat from "./components/AdminSide/tickets/AdminChat";
import AddService from "./components/AdminSide/addservice/AddService";
import AddPackages from "./components/AdminSide/addservice/AddPackages";
import AllService from "./components/AdminSide/addservice/AllService";
import Package from "./components/AdminSide/addservice/Package";
import useAutoLogout from "./components/useAutoLogout";
import Logout from "./components/Logout";
import Rewards from "./components/AdminSide/rewards/Rewards";
import AddRewards from "./components/AdminSide/rewards/AddRewards";
import PaymentsPage from "./components/AdminSide/payments/PaymentsPage";
import Archived from "./components/AdminSide/properties/Archived";
import { PrivateRoutes } from "./PrivateRoutes";
import ArchivedUser from "./components/AdminSide/users/ArchivedUser";
import ForgotPassword from "./components/ForgotPassword";
import ArchivedStaffs from "./components/AdminSide/staffs/ArchivedStaffs";
import "./assets/css/style.css"
import Activities from "./components/AdminSide/activities/Activities";
import ViewAllActivity from "./components/AdminSide/activities/ViewAllActivity";
import Setting from "./components/AdminSide/setting/Setting";
import Category from "./components/AdminSide/category/Category";
import AddCategories from "./components/AdminSide/category/AddCategories";
import ArchiveCat from "./components/AdminSide/category/ArchiveCat";
import SubCategories from "./components/AdminSide/subcategories/SubCategories";
import ArchiveSubCat from "./components/AdminSide/subcategories/ArchiveSubCat";
import AddSubCat from "./components/AdminSide/subcategories/AddSubCat";
import UserQuery from "./components/AdminSide/query/UserQuery";
import ArchiveServices from "./components/AdminSide/addservice/ArchiveServices";
import ViewUserDetails from "./components/AdminSide/users/ViewUserModal";
import UserPayments from "./components/AdminSide/users/UserPayments";
import UserService from "./components/AdminSide/users/UserService";
import UserProperties from "./components/AdminSide/users/UserProperties";
import Testimonial from "./components/AdminSide/testimonial/Testimonial";
import CouponsTable from "./components/AdminSide/coupons/CouponsTable";
import AddTestimonial from "./components/AdminSide/testimonial/AddTestimonial";
import EditTestimonial from "./components/AdminSide/testimonial/EditTestimonial";
import Agents from "./components/AdminSide/agents/Agents";
import AddTeams from "./components/AdminSide/teams/AddTeams";
import Teams from "./components/AdminSide/teams/Teams";
import AddAgents from "./components/AdminSide/agents/AddAgents";
import About from "./components/AdminSide/about/about";
import GalleryImages from "./components/AdminSide/gallary/GallaryImages";
import Hero from "./components/AdminSide/hero/Hero";
import PaymentReport from "./components/AdminSide/paymentreport/PaymentReport";
import PaymentDetails from "./components/AdminSide/payments/PaymentDetails";
import RefundPaymentReport from "./components/AdminSide/paymentreport/RefundPaymentReport";
import Advertisement from "./components/AdminSide/hero/Advertisement";
import AddAdvertisement from "./components/AdminSide/hero/AddAdvertisement";
import StaffSalesReport from "./components/AdminSide/paymentreport/StaffSalesReport";


function App() {
  const permissions = localStorage.getItem("permissions")
  const type = localStorage.getItem("type")
  console.log(permissions);
  console.log(type);
  useAutoLogout();
  // const [properties, setProperties] = useState([]);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/dashboard" element={<PrivateRoutes Component={Dashboard} />} />
          <Route path="/staffs" element={<PrivateRoutes Component={Staffs} />} />
          <Route path="/users" element={<PrivateRoutes Component={Users} />} />
          <Route path="/properties" element={<PrivateRoutes Component={Properties} />} />
          {/* <Route path="/settings" element={<PrivateRoutes Component={Settings} />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/profile" element={<PrivateRoutes Component={Profile} />} />
          <Route path="/usersprofile" element={<PrivateRoutes Component={UserProfile} />} />

          <Route path="/viewuserdetails/:id" element={<ViewUserDetails/>} />
          <Route path="/userpayments/:id" element={<UserPayments/>} />
          <Route path="/userservices/:id" element={<UserService/>} />
          <Route path="/userproperties/:id" element={<UserProperties/>} />
          
          <Route path="/registerProperty" element={<PrivateRoutes Component={RegisterProperty} />} />
          <Route path="/editProperty/:propertyId" element={<PrivateRoutes Component={EditProperty} />} />
          <Route path="/loadproperties" element={<PrivateRoutes Component={LoadProperties} />} />
          <Route path="/brokers" element={<PrivateRoutes Component={Brokers} />} />
          <Route path="/services" element={<PrivateRoutes Component={AllService} />} />
          <Route path="/addservices" element={<PrivateRoutes Component={AddService} />} />
          <Route path="/archiveservices" element={<PrivateRoutes Component={ArchiveServices} />} />
          <Route path="/packages" element={<PrivateRoutes Component={Package} />} />
          <Route path="/addpackages" element={<PrivateRoutes Component={AddPackages} />} />
          <Route path="/tickets" element={<PrivateRoutes Component={Tickets} />} />
          <Route path="/rewards" element={<PrivateRoutes Component={Rewards} />} />
          <Route path="/addrewards" element={<PrivateRoutes Component={AddRewards} />} />
          <Route path="/payments" element={<PrivateRoutes Component={PaymentsPage} />} />
          <Route path="/archivedproperties" element={<PrivateRoutes Component={Archived} />} />
          <Route path="/archivedusers" element={<PrivateRoutes Component={ArchivedUser} />} />
          <Route path="/archivestaffs" element={<PrivateRoutes Component={ArchivedStaffs} />} />
          <Route path="/activities" element={<PrivateRoutes Component={Activities} />} />
          <Route path="/tickets/:ticketId" element={<PrivateRoutes Component={AdminChat} />} />
          <Route path="/view-all-activity/:email" element={<PrivateRoutes Component={ViewAllActivity} />} />

          <Route path="/settings" element={<PrivateRoutes Component={Setting} />} />
          <Route path="/categories" element={<PrivateRoutes Component={Category} />} />
          <Route path="/addcategories" element={<PrivateRoutes Component={AddCategories} />} />
          <Route path="/archivecategories" element={<PrivateRoutes Component={ArchiveCat} />} />

          <Route path="/subcategories" element={<PrivateRoutes Component={SubCategories} />} />
          <Route path="/addsubcategories" element={<PrivateRoutes Component={AddSubCat} />} />
          <Route path="/archivesubcategories" element={<PrivateRoutes Component={ArchiveSubCat} />} />

          <Route path="/coupons" element={<PrivateRoutes Component={CouponsTable}/>}/>

          <Route path="/userquery" element={<PrivateRoutes Component={UserQuery} />} />
          <Route path="/testimonial" element={<PrivateRoutes Component={Testimonial} />} />
          <Route path="/add-testimonial" element={<PrivateRoutes Component={AddTestimonial} />} />
          <Route path="/edit-testimonial/:id" element={<PrivateRoutes Component={EditTestimonial} />} />

          <Route path="/agents" element={<PrivateRoutes Component={Agents} />} />
          <Route path="/addagents" element={<PrivateRoutes Component={AddAgents} />} />


          <Route path="/addteams" element={<PrivateRoutes Component={AddTeams} />} />
          <Route path="/teams" element={<PrivateRoutes Component={Teams} />} />

          <Route path="/about" element={<PrivateRoutes Component={About} />} />

          <Route path="/advertisement" element={<PrivateRoutes Component={Advertisement} />} />
          <Route path="/advertisement/add-adv" element={<PrivateRoutes Component={AddAdvertisement} />} />


          <Route path="/gallaryimages" element={<PrivateRoutes Component={GalleryImages} />} />


          <Route path="/dashboard/paymentreport" element={<PrivateRoutes Component={PaymentReport} />} />
          <Route path="/dashboard/staffsalesreport" element={<PrivateRoutes Component={StaffSalesReport} />} />

          <Route path="/dashboard/refundreport" element={<PrivateRoutes Component={RefundPaymentReport} />} />

          <Route path="/payment-details/:paymentId" element={<PrivateRoutes Component={PaymentDetails} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
