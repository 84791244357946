import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { BaseURL } from "../../BaseUrl";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ActiveInactiveChart = () => {
  const [chartData, setChartData] = useState({
    labels: ["Active Services", "Inactive Services"],
    datasets: [
      {
        label: "Service Count",
        data: [0, 0], // Default values
        backgroundColor: ["#42A5F5", "#FF7043"],
        borderColor: ["#1E88E5", "#FF5722"],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/totalActiveInactiveServices`);
        if (response.data.success) {
          const { totalActiveServices, totalInactiveServices } = response.data;

          setChartData({
            labels: ["Active Services", "Inactive Services"],
            datasets: [
              {
                label: "Service Count",
                data: [parseInt(totalActiveServices, 10), parseInt(totalInactiveServices, 10)],
                backgroundColor: ["#42A5F5", "#FF7043"],
                borderColor: ["#1E88E5", "#FF5722"],
                borderWidth: 1,
              },
            ],
          });
        } else {
          console.error("Failed to fetch chart data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();
  }, []);

  // Inline styles
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "600px",
  };

  const headingStyle = {
    marginBottom: "20px",
    color: "#333",
    fontSize: "24px",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Active or Inactive Services</h2>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.dataset.label || "";
                  const value = context.raw;
                  return `${label}: ${value}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Service Status",
              },
              grid: {
                display: false, // Hide grid lines
              },
              // Adjust bar width
              categoryPercentage: 0.5, // Reduces the width of each category
              barPercentage: 0.8, // Reduces the width of each bar within a category
            },
            y: {
              title: {
                display: true,
                text: "Count",
              },
              beginAtZero: true,
              grid: {
                display: false, // Hide grid lines
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ActiveInactiveChart;
