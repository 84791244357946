import React, { useEffect, useState, useRef, useMemo } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  isValidDate,
  isValidIndianPIN,
  isValidPrice,
  isValidSquareFeet,
} from "../../../utils/validations";

import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BaseURL } from "../../BaseUrl";

function EditProperty() {
  const { propertyId } = useParams();
  const id = propertyId
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [selectedStateIso2, setSelectedStateIso2] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [activeTab, setActiveTab] = useState("basic_informations");
  const [basicInfo, setBasicInfo] = useState({
    title: "",
    property_type: "",
    description: "",
    available_from: "",
    address: "",
    property_status: "",
    city: "",
    sqft: "",
    state: "",
    price: "",
    country: "",
    zipcode: "",
    locality: "",
    negotiate: "",
  });
  const [additionalInfo, setAdditionalInfo] = useState({
    parking_space: "",
    furnishing_type: "",
    property_for: "",
    remark: "",
    preferred_business: "",
    water_supply: "",
    municipal_authority: "",
    boundary_wall: "",
    prefered_tenants: "",
    bhk_type: "",
    residential_type: "",
    commertial_type: "",
    available_visiting_days: "",
    floor_no: "",
    visitor_parking_type: "",
    amenities: [],
  });

  const bannerInputRef = useRef(null);
  const imagesArrayInputRef = useRef(null);
  const [propertyTab, setPropertyTab] = useState(0);
  const [tabCompletion, setTabCompletion] = useState([false, false, false]);
  const [isModified, setIsModified] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [images, setImages] = useState([]);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [imagesArray, setImagesArray] = useState([]);
  const [imagesArrayPreviews, setImagesArrayPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${BaseURL}/showproperty/${id}`);
        const data = response.data;
        console.log(data, "params fetch data");
        setBasicInfo({
          title: data.property.title,
          property_type: data.property.property_type,
          description: data.property.description,
          available_from: data.property.available_from,
          address: data.property.address,
          property_status: data.property.property_status,
          city: data.property.city,
          locality: data.property.locality,
          sqft: data.property.sqft,
          state: data.property.state,
          price: data.property.price,
          country: data.property.country,
          zipcode: data.property.zipcode,
          negotiate: data.property.negotiable,
        });
        setAdditionalInfo({
         parking_space: data.property.parking_space,
          furnishing_type: data.property.furnishing_type,
          property_for: data.property.property_for,
          remark: data.property.remarks_on_furnishing,
          preferred_business: data.property.preferred_business,
          water_supply: data.property.water_supply,
          municipal_authority: data.property.municipal_authority,
          boundary_wall: data.property.boundary_wall,
          prefered_tenants: data.property.prefered_tenants,
          bhk_type: data.property.bhk_type,
          residential_type: data.property.residential_type,
          commertial_type: data.property.commertial_type,
          available_visiting_days: data.property.available_visiting_days,
          floor_no: data.property.floor_no,
          visitor_parking_type: data.property.visitor_parking_type,
          amenities: data.property.amenities
            ? data.property.amenities.split(",")
            : [],
        });
        setBannerImage(data.property.bannerimage_name);
  setBannerImagePreview(data.property.bannerimage_name);
  setImages(data.property.images || []);
  setImagesArrayPreviews(data.property.images || []);
} catch (error) {
  console.error("Error fetching property:", error);
  setError("Failed to fetch property details. Please try again later.");
} finally {
  setIsLoading(false);
}}
    fetchProperty();
  }, [id]);

  const handleChange = (e, setInfo, info) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setInfo({
        ...info,
        amenities: checked
          ? [...info.amenities, value]
          : info.amenities.filter((amenity) => amenity !== value),
      });
    } else if (type === "radio") {
      setInfo({
        ...info,
        [name]: parseInt(value, 10), // Ensure the value is a number for radio buttons
      });
    } else {
      setInfo({
        ...info,
        [name]: value,
      });
    }
  };


  const handleBannerImageChange = (e) => {
    const file = e.target.files[0];
    setBannerImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setBannerImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImagesArrayChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 10) {
      alert("You can only upload a maximum of 10 files");
      return;
    }
    setImagesArray(files);

    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(previews).then((imagePreviews) => {
      setImagesArrayPreviews(imagePreviews);
    });
  };

  const handleBasicInfoSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [
      // "title",
      // "property_type",
      // "description",
      // "available_from",
      // "address",
      // "property_status",
      // "city",
      // "locality",
      // "sqft",
      // "state",
      // "price",
      // "country",
      // "zipcode",
    ];

    const isValid = requiredFields.every((field) => field in basicInfo);

    if (isValid) {
      setActiveTab("additional_informations");
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const handleRemoveImage = (index, type) => {
    if (type === "banner") {
      setBannerImage(null);
      setBannerImagePreview(null);
      if (bannerInputRef.current) {
        bannerInputRef.current.value = "";
      }
    } else if (type === "array") {
      const newImagesArray = [...imagesArray];
      newImagesArray.splice(index, 1);
      setImagesArray(newImagesArray);

      const newImagesArrayPreviews = [...imagesArrayPreviews];
      newImagesArrayPreviews.splice(index, 1);
      setImagesArrayPreviews(newImagesArrayPreviews);

      if (imagesArrayInputRef.current) {
        imagesArrayInputRef.current.value = "";
      }
    }
  };

  const handleAdditionalInfoSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [];
    // 'parking_space', 'furnishing_type', 'property_for', 'water_supply', 'boundary_wall', 'prefered_tenants', 'bhk_type', 'residential_type', 'commertial_type', 'available_visiting_days'
    const isValid = requiredFields.every(
      (field) => additionalInfo[field].trim() !== ""
    );
    if (isValid) {
      setActiveTab("images");
    } else {
      alert("Please fill in all required fields.");
    }
  };

  useMemo(() => {
    if (countries.length) {
      const result = countries.find(
        (country) => country.name === basicInfo.country
      );
      console.log(result);
      setSelectedCountryIso2(result.iso2||"");
    }
  }, [countries, basicInfo.country]);

  useMemo(() => {
    if (states.length) {
      console.log("states",states)
      console.log(basicInfo.city)
      const result = states.find((state) => state.name === basicInfo.state);
      console.log("rsult",result)
      setSelectedStateIso2(result?.iso2||"")
    }
  }, [states, basicInfo.state]);

  useEffect(() => {
    const loadCountries = () => {
      axios
        .get("https://api.countrystatecity.in/v1/countries", {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        })
        .then((response) => {
          setCountries(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error loading countries:", error);
        });
    };

    loadCountries();
  }, []);

  const onChangeCountryHandler = (e) => {
    const selectedCountry = e.target.value;
    const country = countries.find(
      (country) => country.name === selectedCountry
    );
    if (country) {
      setBasicInfo((prev) => ({
        ...prev,
        country: country.name,
        state: "", // Reset state when country changes
        city: "", // Reset city when country changes
      }));
      setSelectedCountryIso2(country.iso2);
      // loadStates(country.iso2);
    }
  };
 
  useEffect(() => {
    if (selectedCountryIso2) loadStates(selectedCountryIso2);
  }, [selectedCountryIso2]);

  // Fetch states based on selected country
  const loadStates = (iso2) => {
    axios
      .get(`https://api.countrystatecity.in/v1/countries/${iso2}/states`, {
        headers: {
          "X-CSCAPI-KEY":
            "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
        },
      })
      .then((response) => {
        console.log("response state",response.data)
        setStates(response.data||[]);
      })
      .catch((error) => {
        console.error("Error loading states:", error);
      });
  };

  // Handle state selection
  const onChangeStateHandler = (e) => {
    const selectedState = e.target.value;
    const state = states.find((state) => state.name === selectedState);
    if (state) {
      setBasicInfo((prev) => ({
        ...prev,
        state: state.name,
        city: "",
      }));
      setSelectedStateIso2(state.iso2);
    }
  };

  // Fetch cities based on selected state and country
  const loadCities = (countryIso2, stateIso2) => {
    axios
      .get(
        `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        }
      )
      .then((response) => {
        setCities(response.data);
      })
      .catch((error) => {
        console.error("Error loading cities:", error);
      });
  };

  useEffect(() => {
    if (selectedCountryIso2 && selectedStateIso2)
      loadCities(selectedCountryIso2, selectedStateIso2);
  }, [selectedCountryIso2, selectedStateIso2]);

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    const userId = localStorage.getItem("staffId");
  
    if (!userId) {
      alert("Staff ID is not found. Please log in again.");
      return;
    }
  
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("id", id);
  
    // Append basicInfo and additionalInfo to formData
    Object.entries(basicInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Object.entries(additionalInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });
  
    // Append images if available
    formData.append("banner_image", bannerImage);
    let images_array = []
    if (imagesArray.length > 0) {
      imagesArray.forEach((images, index) => {
        formData.append("images_array", images);
        // formData.append("images_array", image);


        
      });
    }

    console.log(formData)
  
    try {
      setIsLoading(true);
      const response = await axios.put(
        `${BaseURL}/editproperty/${propertyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Property edited successfully!", response.data);

      if (response.data.message === "Property details updated successfully") {
        alert(response.data.message);
        navigate("/");
      } else {
        console.error("Error registering property:", response.data.message);
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error editing property:", error);
      alert("An error occurred while editing property. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Edit Property</h4>
                  <ul className="nav nav-tabs" id="myTab">
                  <li className="nav-item">
                      <button
                        className={`nav-link ${
                          activeTab === "basic_informations" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("basic_informations")}
                      >
                        Basic Informations
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          activeTab === "additional_informations"
                            ? "active"
                            : ""
                        }`}
                        onClick={() => setActiveTab("additional_informations")}
                      >
                        Additional Informations
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          activeTab === "images" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("images")}
                      >
                        Images
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "basic_informations" ? "show active" : ""
                      }`}
                      id="basic_informations"
                    >
                      <div className="mt-3">
                        <form onSubmit={handleBasicInfoSubmit}>
                          <div className="row">
                            <div className="col-md-4 mb-4">
                              <label>Title</label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                placeholder={basicInfo.title}
                                value={basicInfo.title}
                                required
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                              />
                            </div>
                            <div className="col-md-4 mb-4">
                              <label>Property Type</label>
                              <select
                                className="form-select form-control"
                                name="property_type"
                                value={basicInfo.property_type}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="0">Residential</option>
                                <option value="1">Commercial</option>
                              </select>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label>Description</label>
                              <input
                                type="text"
                                className="form-control"
                                name="description"
                                placeholder="Enter Description"
                                value={basicInfo.description}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                                minlength={10}
                              />
                            </div>
                            <div className="col-md-4 mb-4">
                              <label>Property Status</label>
                              <select
                                className="form-select form-control"
                                name="property_status"
                                value={basicInfo.property_status}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="0">Ready to Move In </option>
                                <option value="1">Under Construction</option>
                              </select>
                            </div>

                            {basicInfo.property_status != 0 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Date of Availability</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="available_from"
                                    value={basicInfo.available_from}
                                    onChange={(e) =>
                                      handleChange(e, setBasicInfo, basicInfo)
                                    }
                                    required
                                  />
                                </div>
                              </>
                            )}
                            <div className="col-md-4 mb-4">
                              <label>Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                placeholder="Enter Address"
                                value={basicInfo.address}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                              />
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Select Country:</label>
                              <select
                                name="country"
                                className="form-control"
                                value={basicInfo.country}
                                onChange={onChangeCountryHandler}
                                required
                              >
                                <option value="">Select Country</option>
                                {countries.map((country) => (
                                  <option
                                    key={country.iso2}
                                    value={country.name}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label>Select State:</label>
                              <select
                                name="state"
                                className="form-control"
                                value={basicInfo.state}
                                onChange={onChangeStateHandler}
                                required
                                disabled={!basicInfo.country}
                              >
                                <option value="">Select State</option>
                                {states.map((state) => (
                                  <option key={state.name} value={state.name}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Select City:</label>
                              <select
                                name="city"
                                className="form-control"
                                value={basicInfo.city}
                                onChange={(e) =>
                                  setBasicInfo((prev) => ({
                                    ...prev,
                                    city: e.target.value,
                                  }))
                                }
                                required
                                disabled={!basicInfo.state}
                              >
                                <option value="">Select City</option>
                                {cities.map((city) => (
                                  <option key={city.name} value={city.name}>
                                    {city.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Locality</label>
                              <input
                                type="text"
                                className="form-control"
                                name="locality"
                                placeholder="Enter Locality name"
                                value={basicInfo.locality}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                              />
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Square Feet</label>
                              <input
                                type="number"
                                className="form-control"
                                name="sqft"
                                placeholder="Enter Square Feet"
                                value={basicInfo.sqft}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                              />
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Price</label>
                              <input
                                type="number"
                                className="form-control"
                                name="price"
                                placeholder="Enter Price"
                                value={basicInfo.price}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                                min={5000}
                              />
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Zipcode</label>
                              <input
                                type="text"
                                className="form-control"
                                name="zipcode"
                                placeholder="Enter Zipcode"
                                value={basicInfo.zipcode}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                              />
                            </div>
                            <div className="col-md-4 mb-4">
                              <label>Ready to Negotiate</label>
                              <select
                                className="form-select form-control"
                                name="negotiate"
                                value={basicInfo.negotiate}
                                onChange={(e) =>
                                  handleChange(e, setBasicInfo, basicInfo)
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                            <div className="col-md-12 mt-2">
                              <input
                                type="submit"
                                value="Submit Basic Informations"
                                className="btn btn-primary"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "additional_informations"
                          ? "show active"
                          : ""
                      }`}
                      id="additional_informations"
                    >
                      <div className="mt-3">
                        <form onSubmit={handleAdditionalInfoSubmit}>
                          <div className="row">
                            {basicInfo.property_type != 2 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Parking Space</label>
                                  <select
                                    className="form-select form-control"
                                    id="parking_space"
                                    name="parking_space"
                                    value={additionalInfo.parking_space}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">2 Wheeler</option>
                                    <option value="1">4 Wheeler</option>
                                    <option value="2">
                                      Both 2 Wheeler and 4 Wheeler
                                    </option>
                                    <option value="3">
                                      No Parking Available
                                    </option>
                                  </select>
                                </div>
                              </>
                            )}

                            {basicInfo.property_type != 2 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Furnishing Type</label>
                                  <select
                                    className="form-select form-control"
                                    name="furnishing_type"
                                    value={additionalInfo.furnishing_type}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">Fully Furnished</option>
                                    <option value="1">Semi Furnished</option>
                                    <option value="2">Non Furnished</option>
                                  </select>
                                </div>
                              </>
                            )}

                            {additionalInfo.furnishing_type != 1 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Remark for Fully Furnished</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="remark"
                                    placeholder="Remark for Fully Furnished"
                                    value={additionalInfo.remark}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  />
                                </div>
                              </>
                            )}
                            {additionalInfo.furnishing_type != 2 &&
                              additionalInfo.furnishing_type != 0 && (
                                <>
                                  <div className="col-md-4 mb-4">
                                    <label>Remark for Semi Furnished</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="remark"
                                      placeholder="Remark for Semi Furnished"
                                      value={additionalInfo.remark}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          setAdditionalInfo,
                                          additionalInfo
                                        )
                                      }
                                      required
                                    />
                                  </div>
                                </>
                              )}

                            {basicInfo.property_type != 2 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Property For</label>
                                  <select
                                    className="form-select form-control"
                                    name="property_for"
                                    value={additionalInfo.property_for}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">Rent</option>
                                    <option value="1">Sell</option>
                                    <option value="2">Lease</option>
                                  </select>
                                </div>
                              </>
                            )}
                            {basicInfo.property_type == 2 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Property For</label>
                                  <select
                                    className="form-select form-control"
                                    name="property_for"
                                    value={additionalInfo.property_for}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="1">Sell</option>
                                    <option value="2">lease</option>
                                  </select>
                                </div>
                              </>
                            )}

                            <div className="col-md-4 mb-4">
                              <label>Water Supply</label>
                              <select
                                className="form-select form-control"
                                name="water_supply"
                                value={additionalInfo.water_supply}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    setAdditionalInfo,
                                    additionalInfo
                                  )
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="0">Corporation</option>
                                <option value="1">Borewell</option>
                                <option value="2">
                                  Available from Both Cooperation and Borewell
                                </option>
                                <option value="3">
                                  No Water Supply Available Now
                                </option>
                              </select>
                            </div>

                            <div className="col-md-4 mb-4">
                              <label>Muncipal Authority</label>
                              <select
                                className="form-select form-control"
                                name="municipal_authority"
                                value={additionalInfo.municipal_authority}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    setAdditionalInfo,
                                    additionalInfo
                                  )
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="BBMP">BBMP</option>
                                <option value="BDA">BDA</option>
                                <option value="PANCHAYAT">PANCHAYAT</option>
                                <option value="NAGARSABHA">NAGARSABHA</option>
                                <option value="PURASABHE">PURASABHE</option>
                                <option value="PATTANA_PANCHAYAT">
                                  PATTANA_PANCHAYAT
                                </option>
                                <option value="OTHERS">OTHERS</option>
                              </select>
                            </div>

                            {basicInfo.property_type == 1 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Commercial Property Category</label>
                                  <select
                                    className="form-select form-control"
                                    name="commertial_type"
                                    value={additionalInfo.commertial_type}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">OfficeSpace</option>
                                    <option value="1">Shop/Showroom</option>
                                    <option value="2">WareHouse/Godown</option>
                                    <option value="3">
                                      Industrial Building
                                    </option>
                                    <option value="4">Mall</option>
                                    <option value="5">Theatre</option>
                                    <option value="6">Land/Plot</option>
                                  </select>
                                </div>
                              </>
                            )}

                            {basicInfo.property_type != 1 &&
                              additionalInfo.property_for != 1 && (
                                <>
                                  <div className="col-md-4 mb-4">
                                    <label>Preferred Tenants</label>
                                    <select
                                      className="form-select form-control"
                                      name="prefered_tenants"
                                      value={additionalInfo.prefered_tenants}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          setAdditionalInfo,
                                          additionalInfo
                                        )
                                      }
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Bachelor Male</option>
                                      <option value="2">Bachelor Female</option>
                                      <option value="3">Family</option>
                                      <option value="0">Anyone</option>
                                    </select>
                                  </div>
                                </>
                              )}
                            {basicInfo.property_type != 0 &&
                              additionalInfo.property_for != 1 &&
                              additionalInfo.commertial_type != 6 && (
                                <>
                                  <div className="col-md-4 mb-4">
                                    <label>Preferred Business</label>
                                    <select
                                      className="form-select form-control"
                                      name="preferred_business"
                                      value={additionalInfo.preferred_business}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          setAdditionalInfo,
                                          additionalInfo
                                        )
                                      }
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="0">IT</option>
                                      <option value="1">Medical</option>
                                      <option value="2">Press</option>
                                      <option value="3">Resturent</option>
                                    </select>
                                  </div>
                                </>
                              )}
                            {basicInfo.property_type == 0 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>BHK Type</label>
                                  <select
                                    className="form-select form-control"
                                    name="bhk_type"
                                    value={additionalInfo.bhk_type}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">1 BHK</option>
                                    <option value="1">2 BHK</option>
                                    <option value="2">3 BHK</option>
                                    <option value="3">4 BHK</option>
                                    <option value="4">5 BHK</option>
                                    <option value="5">+5 BHK</option>
                                  </select>
                                </div>
                              </>
                            )}
                            {basicInfo.property_type == 0 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Residential Property Category</label>
                                  <select
                                    className="form-select form-control"
                                    name="residential_type"
                                    value={additionalInfo.residential_type}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">Apartment/Flat</option>
                                    <option value="1">
                                      Independent House/Villa
                                    </option>
                                    <option value="2">
                                      Gated Community Villa
                                    </option>
                                    <option value="3">Plot/Land</option>
                                  </select>
                                </div>
                              </>
                            )}
                            {additionalInfo.residential_type != 3 &&
                              additionalInfo.commertial_type != 6 && (
                                <>
                                  <div className="col-md-4 mb-4">
                                    <label>Floor Number</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="floor_no"
                                      placeholder="Enter floor no."
                                      value={additionalInfo.floor_no}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          setAdditionalInfo,
                                          additionalInfo
                                        )
                                      }
                                      required
                                    />
                                  </div>
                                </>
                              )}

                            {basicInfo.property_type == 2 && (
                              <>
                                <div className="col-md-4 mb-4">
                                  <label>Boundary Wall</label>
                                  <select
                                    className="form-select form-control"
                                    name="boundary_wall"
                                    value={additionalInfo.boundary_wall}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="0">Yes</option>
                                    <option value="1">No</option>
                                  </select>
                                </div>
                              </>
                            )}
                            <div className="col-md-4 mb-4">
                              <label>Available Visiting Days</label>
                              <select
                                className="form-select form-control"
                                name="available_visiting_days"
                                value={additionalInfo.available_visiting_days}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    setAdditionalInfo,
                                    additionalInfo
                                  )
                                }
                                required
                              >
                                <option value="">Select</option>
                                <option value="0">Monday</option>
                                <option value="1">Tuesday</option>
                                <option value="2">Wednesday</option>
                                <option value="3">Thursday</option>
                                <option value="4">Friday</option>
                                <option value="5">Satarday</option>
                                <option value="6">Sunday</option>
                                <option value="7">Everyday</option>
                              </select>
                            </div>

                            <div style={{ display: "flex" }}>
                              <div className="col-md-4 mb-4">
                                <div>
                                  <label>Amenities</label>&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="Lift"
                                    checked={additionalInfo.amenities.includes(
                                      "Lift"
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                  />
                                  <label className="form-check-label">
                                    Lift
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="AC"
                                    checked={additionalInfo.amenities.includes(
                                      "AC"
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                  />
                                  <label className="form-check-label">AC</label>
                                </div>
                                {basicInfo.property_type != 1 && (
                                  <>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="Children's Play Area"
                                        checked={additionalInfo.amenities.includes(
                                          "Children's Play Area"
                                        )}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            setAdditionalInfo,
                                            additionalInfo
                                          )
                                        }
                                      />
                                      <label className="form-check-label">
                                        Children's Play Area
                                      </label>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div>
                                <h6>Visitor Parking</h6>

                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="visitor_parking_type"
                                    value="1"
                                    checked={
                                      additionalInfo.visitor_parking_type === 1
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                  />
                                  <label className="form-check-label">
                                    Paid
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="visitor_parking_type"
                                    value="0"
                                    checked={
                                      additionalInfo.visitor_parking_type === 0
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        setAdditionalInfo,
                                        additionalInfo
                                      )
                                    }
                                  />
                                  <label className="form-check-label">
                                    Unpaid
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Render amenities list */}
                            <ul>
                              {additionalInfo.amenities.map(
                                (amenity, index) => (
                                  <li key={index}>{amenity}</li>
                                )
                              )}
                            </ul>

                            <div className="col-md-12 mt-2">
                              <input
                                type="submit"
                                value="Save Additional Informations"
                                className="btn btn-primary"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "images" ? "show active" : ""
                      }`}
                      id="images"
                    >
                      <div className="mt-3">
                        <form onSubmit={handleImageSubmit}>
                          <div className="row">
                            <div className="col-md-6 mb-4">
                              <label>Banner Image (JPG, PNG) Max 5 MB</label>
                              <input
                                className="form-control"
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={handleBannerImageChange}
                                ref={bannerInputRef}
                                // required
                              />
                              {bannerImagePreview && (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={bannerImagePreview}
                                    alt="Banner Preview"
                                    style={{
                                      marginTop: "10px",
                                      maxWidth: "200px",
                                      height: "auto",
                                    }}
                                  />
                                  {/* <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveImage(null, "banner")
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      background: "red",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    X
                                  </button> */}
                                </div>
                              )}
                            </div>
                            
                            <div className="col-md-6 mb-4">
                              <label>Images Array (JPG, PNG, GIF, etc.) MAX 20 MB</label>
                              <input
                                className="form-control"
                                type="file"
                                accept="image/jpeg, image/png, image/gif, image/svg+xml"
                                multiple
                                onChange={handleImagesArrayChange}
                                ref={imagesArrayInputRef}
                                // required
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                }}
                              >
                                {imagesArrayPreviews.map((preview, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img
                                      src={preview}
                                      alt={`Preview ${index}`}
                                      style={{
                                        maxWidth: "200px",
                                        height: "auto",
                                      }}
                                    />
                                    {/* <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveImage(index, "array")
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        background: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                    >
                                      X
                                    </button> */}
                                  </div>
                                ))}
                              </div>
                              {/* Show fetched images if any */}
                              {/* {images.length > 0 && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                  }}
                           col-md-6 mb-4     >
                                  {images.map((image, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <img
                                        src={`${BaseUrl}/public/uploads/${image}`}
                                        alt={`Property ${index}`}
                                        style={{
                                          maxWidth: "200px",
                                          height: "auto",
                                        }}
                                      />
                                      <input type="hidden" name="fetched_images[]" value={image} />
                                    </div>
                                  ))}
                                </div>
                              )} */}
                            </div>
                            <div className="col-md-12 mt-2">
                            <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    
  );
};

export default EditProperty;
