import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";

export default function UserPayments() {
  const [payments, setPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsPerPage] = useState(10); // Number of payments per page
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${BaseURL}/getallpaymentsofuser/${id}`)
      .then((response) => {
        if (response.data.success) {
          setPayments(response.data.searchPaymentsResults);
        } else {
          setPayments([]);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the payments data!", error);
        setPayments([]);
      });
  }, [id]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  // Get current payments
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = payments.slice(indexOfFirstPayment, indexOfLastPayment);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">User Payments</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  ></div>
                  <div style={{ overflowX: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Amount</th>
                          <th>Invoice</th>
                          <th>Order ID</th>
                          <th>Payment ID</th>
                          <th>Status</th>
                          <th>Method</th>
                          <th>Created At</th>
                          {/* <th>Timestamp</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {currentPayments.length > 0 ? (
                          currentPayments.map((payment) => (
                            <tr key={payment.id}>
                              <td>{payment.id}</td>
                              <td>{payment.payment_amount}</td>
                              <td>
                                {payment.invoice_name ? (
                                  <a
                                    href={payment.invoice_name}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Invoice
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>{payment.order_id}</td>
                              <td>{payment.payment_id || "N/A"}</td>
                              <td>{payment.status}</td>
                              <td>{payment.payment_method || "N/A"}</td>
                              <td>{formatDate(payment.created_at)}</td>
                              {/* <td>{formatDate(payment.timestamp)}</td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9" style={{ textAlign: "center" }}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {payments.length > paymentsPerPage && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <nav>
                        <ul className="pagination">
                          {Array.from({ length: Math.ceil(payments.length / paymentsPerPage) }, (_, i) => (
                            <li key={i} className="page-item">
                              <button onClick={() => paginate(i + 1)} className="page-link">
                                {i + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
