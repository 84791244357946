import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useParams, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const EditTestimonial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [testimonial, setTestimonial] = useState({
    name: "",
    description: "",
    rating: "",
    designation: "",
    image: "",
  });

  useEffect(() => {
    fetchTestimonial();
  }, []);

  const fetchTestimonial = async () => {
    try {
      const response = await axios.get(`${BaseURL}/gettestimonial/${id}`);
      setTestimonial(response.data); // Set testimonial data from API response
    } catch (error) {
      console.error("Error fetching testimonial:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestimonial((prevTestimonial) => ({
      ...prevTestimonial,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${BaseURL}/updatetestimonial/${id}`, testimonial);
      navigate("/"); // Redirect to testimonial list page after successful update
    } catch (error) {
      console.error("Error updating testimonial:", error);
    }
  };

  return (
    <div className="container">
      <h2>Edit Testimonial</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          value={testimonial.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="description"
          label="Description"
          variant="outlined"
          value={testimonial.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="rating"
          type="number"
          label="Rating"
          variant="outlined"
          value={testimonial.rating}
          onChange={handleChange}
          fullWidth
          margin="normal"
          inputProps={{
            min: 1,
            max: 5,
            inputMode: "numeric", // Ensures numeric keyboard on mobile devices
          }}
        />
        <TextField
          name="designation"
          label="Designation"
          variant="outlined"
          value={testimonial.designation}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="image"
          label="Image URL"
          variant="outlined"
          value={testimonial.image}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Update Testimonial
        </Button>
      </form>
    </div>
  );
};

export default EditTestimonial;
