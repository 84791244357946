import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const htmlToText = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

export default function Rewards() {
  const [rewards, setRewards] = useState([]);
  const [editReward, setEditReward] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    // Fetch all rewards data from the API
    axios.get(`${BaseURL}/allrewards`)
      .then(response => {
        setRewards(response.data.results);
      })
      .catch(error => {
        console.error('Error fetching rewards:', error);
      });
  }, []);

  const handleStatusChange = async (id, currentStatus) => {
    // Toggle the status value between 0 and 1
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      // Send the updated reward status to the backend API
      const response = await axios.put(
        `${BaseURL}/changestatus?id=${id}&status=${newStatus}`
      );
      if (
        response.status === 201 &&
        response.data.message === "Status updated successfully"
      ) {
        // Update the state to reflect the new status
        setRewards(rewards.map(reward =>
          reward.id === id ? { ...reward, status: newStatus } : reward
        ));
        setSnackbarMessage("Reward status updated successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setSnackbarMessage("Error updating reward status!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Send delete request to backend API
      const response = await axios.delete(`${BaseURL}/deletereward/${id}`);
      if (response.status === 201 && response.data.message === "Reward deleted successfully") {
        // Remove the deleted reward from state
        setRewards(rewards.filter(reward => reward.id !== id));
        setSnackbarMessage("Reward deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting reward:", error);
      setSnackbarMessage("Error deleting reward!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (reward) => {
    // Set the reward to be edited
    setEditReward(reward);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditReward(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSave = async () => {
    console.log("Save button clicked");
    try {
      console.log("Sending request to backend API");
      // Send the updated reward data to the backend API
      const response = await axios.put(`${BaseURL}/editreward/${editReward.id}`, editReward);
      console.log("Response received:", response);
  
      // Check the response status and message
      console.log("Response status:", response.status);
      console.log("Response data message:", response.data.message);
  
      if (response.status === 201 && response.data.message === "Reward edited successfully") {
        console.log("Reward edited successfully in backend");
  
        // Update the state to reflect the edited reward
        setRewards(rewards.map(reward =>
          reward.id === editReward.id ? editReward : reward
        ));
  
        console.log("Reward updated in state");
  
        handleClose(); // Close the dialog after saving
        console.log("Dialog closed");
  
        setSnackbarMessage("Reward edited successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        console.log("Snackbar set to open with success message");
      } else {
        console.log("Response did not match expected values");
      }
    } catch (error) {
      console.error("Error updating reward:", error);
      setSnackbarMessage("Error updating reward!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  const formatDateToIndia = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-IN', options);
  };
  
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      renderCell: (params) => (
        <div>{truncateText(htmlToText(params.row.description), 20)}</div>
      ),
    },
    { field: "coins", headerName: "Coins", type: "number", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={() => handleStatusChange(params.row.id, params.row.status)}
          />
        </div>
      ),
    },
    { field: "created_date", headerName: "Created Date", width: 150, renderCell: (params) => (
      <div>{formatDateToIndia(params.row.created_date)}</div>
    ) },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 className="card-title">All Rewards</h4>
                    <div>
                    <button style={{
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}>
                      <Link to="/addrewards" style={{ textDecoration: "none", color: "white" }}>Add Reward</Link>
                    </button>
                  </div>
                  </div>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={rewards}
                      columns={columns}
                      pageSize={5}
                      pagination
                      rowsPerPageOptions={[5, 10]}
                    />
                  </div>
                </div>
              </div>
           
            </div>
            <Footer/>
          </div>
        </div>
      </div>

      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Reward</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit this reward, please modify the fields below and click save.
          </DialogContentText>
          {editReward && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Name (Not Changable)"
                type="text"
                fullWidth
                value={editReward.name}
                disabled
                // onChange={(e) => setEditReward({ ...editReward, name: e.target.value })}
              
              />
              <TextField
                margin="dense"
                label="Description (Not Changable)"
                type="text"
                fullWidth
                value={editReward.description}
                disabled
                // onChange={(e) => setEditReward({ ...editReward, description: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Coins"
                type="number"
                fullWidth
                value={editReward.coins}
                onChange={(e) => setEditReward({ ...editReward, coins: e.target.value })}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
