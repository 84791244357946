import React, { useState, useEffect } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Button, Modal, TextField, Box } from "@mui/material";
import { Link } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function Teams() {
  const [teams, setTeams] = useState([]);
  const [editData, setEditData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${BaseURL}/getallmembers`);
      const dataWithSN = response.data.results.map((team, index) => ({
        ...team,
        sn: index + 1,
      }));
      setTeams(dataWithSN);
    } catch (error) {
      console.error("Error fetching teams data:", error);
    }
  };

  const handleEditOpen = (rowData) => {
    setEditData(rowData);
    setImagePreview(rowData.image);
    setOpen(true);
  };

  const handleEditClose = () => {
    setEditData(null);
    setImagePreview(null);
    setOpen(false);
    setIsImageUpdated(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEditData({ ...editData, image: file });
    setImagePreview(URL.createObjectURL(file));
    setIsImageUpdated(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", editData.name);
    data.append("role", editData.role);
    data.append("description", editData.description);
    data.append("linkedin_url", editData.linkedin_url);
    data.append("twitter_url", editData.twitter_url);
    data.append("facebook_url", editData.facebook_url);
    data.append("instagram_url", editData.instagram_url);
    if (isImageUpdated) {
      data.append("image", editData.image);
    }

    try {
      const response = await axios.put(
        `${BaseURL}/editteammember/${editData.id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Team member updated successfully:", response.data);
      fetchTeams();
      handleEditClose();
    } catch (error) {
      console.error("Error updating team member:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseURL}/deleteteammember/${id}`);
      console.log("Team member deleted successfully");
      fetchTeams();
    } catch (error) {
      console.error("Error deleting team member:", error);
    }
  };

  const columns = [
    { field: "sn", headerName: "S/N", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "role", headerName: "Role", width: 130 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "linkedin_url", headerName: "LinkedIn", width: 200 },
    { field: "twitter_url", headerName: "Twitter", width: 200 },
    { field: "facebook_url", headerName: "Facebook", width: 200 },
    { field: "instagram_url", headerName: "Instagram", width: 200 },
    {
      field: "image",
      headerName: "Image",
      width: 130,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="team member"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleEditOpen(params.row)}>Edit</Button>
          <Button onClick={() => handleDelete(params.row.id)}>Delete</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Team Management</h3>
                    <button
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        textDecoration: "none",
                        marginLeft: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Link
                        to="/addteams"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Add Team Members
                      </Link>
                    </button>
                  </div>
                  <DataGrid
                    rows={teams}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />

                  <Modal open={open} onClose={handleEditClose}>
                    <Box
                      component="form"
                      onSubmit={handleEditSubmit}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 4,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        maxWidth: 500,
                        mx: "auto",
                        mt: 4,
                        height: "80vh",
                        overflowY: "auto",
                      }}
                    >
                      <h2>Edit Team Members</h2>
                      <TextField
                        label="Name"
                        name="name"
                        value={editData?.name || ""}
                        onChange={handleEditChange}
                        required
                      />
                      <TextField
                        label="Role"
                        name="role"
                        value={editData?.role || ""}
                        onChange={handleEditChange}
                        required
                      />
                      <TextField
                        label="Description"
                        name="description"
                        value={editData?.description || ""}
                        onChange={handleEditChange}
                        required
                        multiline
                        rows={3}
                      />
                      <TextField
                        label="LinkedIn URL"
                        name="linkedin_url"
                        value={editData?.linkedin_url || ""}
                        onChange={handleEditChange}
                      />
                      <TextField
                        label="Twitter URL"
                        name="twitter_url"
                        value={editData?.twitter_url || ""}
                        onChange={handleEditChange}
                      />
                      <TextField
                        label="Facebook URL"
                        name="facebook_url"
                        value={editData?.facebook_url || ""}
                        onChange={handleEditChange}
                      />
                      <TextField
                        label="Instagram URL"
                        name="instagram_url"
                        value={editData?.instagram_url || ""}
                        onChange={handleEditChange}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ padding: "40px" }}
                      />
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <Footer />
      </div>
    </div>
  );
}
