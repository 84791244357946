import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import axios from "axios";
import ArchivedTable from "./ArchivedTable";
import ViewPropertyModal from "./ViewPropertyModal";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";

function Archived() {
  const [archivedProperties, setArchivedProperties] = useState([]);
  const [filteredArchivedProperties, setFilteredArchivedProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isViewPropertyModalOpen, setIsViewPropertyModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [propertyTypeFilter, setPropertyTypeFilter] = useState("All");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getarchievedproperties`);
        if (
          response.status === 201 &&
          response.data.message === "Archived properties found successfully"
        ) {
          setArchivedProperties(response.data.properties);
          setFilteredArchivedProperties(response.data.properties);
        } else if (
          response.status === 200 &&
          response.data.message === "No archived properties found"
        ) {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching archived properties:", error);
      }
    };

    fetchData();
  }, []);

  const handleView = (propertyId) => {
    const selected = archivedProperties.find(
      (property) => property.id === propertyId
    );
    setSelectedProperty(selected);
    setIsViewPropertyModalOpen(true);
  };

  const handleUnarchive = async (propertyId) => {
    try {
      const response = await axios.put(`${BaseURL}/unarchieve/${propertyId}`);
      if (response.status === 200 && response.data.message === "Property moved from archived_properties to properties table successfully") {
        setArchivedProperties(prevProperties =>
          prevProperties.filter(property => property.propertyId !== propertyId)
        );
        setFilteredArchivedProperties(prevProperties =>
          prevProperties.filter(property => property.propertyId !== propertyId)
        );
        alert("Property unarchived successfully!");
      } else {
        console.error("Unexpected response:", response);
        alert("Unexpected response from server.");
      }
    } catch (error) {
      console.error("Error unarchiving property:", error);
      alert("Error unarchiving property!");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterProperties(query, propertyTypeFilter);
  };

  const handlePropertyTypeChange = (event) => {
    const selectedType = event.target.value;
    setPropertyTypeFilter(selectedType);
    filterProperties(searchQuery, selectedType);
  };

  const filterProperties = (query, type) => {
    let filtered = archivedProperties;
    if (type !== "All") {
      filtered = filtered.filter(property => property.property_type === parseInt(type));
    }
    if (query) {
      filtered = filtered.filter(property =>
        property.title.toLowerCase().includes(query) ||
        property.address.toLowerCase().includes(query) ||
        property.state.toLowerCase().includes(query) ||
        property.country.toLowerCase().includes(query)
      );
    }
    setFilteredArchivedProperties(filtered);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>Archived Properties</h4>
                  
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      placeholder="Search properties"
                      value={searchQuery}
                      onChange={handleSearch}
                      style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "200px", 
                          fontSize: "16px",
                        }}
                    />
                    <select
                      value={propertyTypeFilter}
                      onChange={handlePropertyTypeChange}
                      style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "150px", 
                          fontSize: "16px",
                        }}
                    >
                      <option value="All">All</option>
                      <option value="0">Residential</option>
                      <option value="1">Commercial</option>
                      {/* <option value="2">Plot</option> */}
                    </select>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        className="btn btn-secondary rounded"
                        to="/properties"
                        style={{ marginRight: "10px" }}
                      >
                      Back &nbsp;
                       <i class='fa fa-reply'></i>
                        
                      </Link>
                     
                     
                    </div>
                    </div>
                  </div>
                  <ArchivedTable
                    archivedProperties={filteredArchivedProperties}
                    handleView={handleView}
                    handleUnarchive={handleUnarchive}
                  />
                  <ViewPropertyModal
                    isOpen={isViewPropertyModalOpen}
                    setIsOpen={setIsViewPropertyModalOpen}
                    property={selectedProperty}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Archived;
