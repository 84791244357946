import React from 'react';
import { Link } from 'react-router-dom';
// import { FaHeart } from 'react-icons/fa';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
        Engineered by 
          <Link 
            to="https://www.vyqda.com" 
            target="_blank"
            style={{ textDecoration: 'none', color: 'blue', marginLeft: '5px' }}
          > 
            Vyqda
          </Link>  
          &nbsp; Excellence
        </span>
        <span className="float-none float-sm-end d-block mt-1 mt-sm-0 text-center">
          Copyright © {currentYear}. HSAH Group. All rights reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
