import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestoreIcon from "@mui/icons-material/Restore";
import { Button } from "@mui/material";

function ArchivedTable({ archivedProperties, handleView, handleUnarchive }) {
  // Add serialId to each archived property object
  const archivedPropertiesWithSerialId = archivedProperties.map((property, index) => ({
    ...property,
    serialId: index + 1,
  }));

  const columns = [
    { field: "serialId", headerName: "ID", width: 70 },
    { field: "title", headerName: "Title", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    { field: "state", headerName: "State", width: 130 },
    { field: "country", headerName: "Country", width: 70 },
    { field: "price", headerName: "Price", type: "number", width: 130 },
    {
      field: "property_type",
      headerName: "Property Type",
      width: 150,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          {params.value === 0 && "Residential"}
          {params.value === 1 && "Commercial"}
          {params.value === 2 && "Plot"}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          <IconButton color="primary" onClick={() => handleView(params.row.id)}>
            
            <Button variant="outlined" color="success" startIcon={<VisibilityIcon />}>
              View
            </Button>
          </IconButton>
          <IconButton color="primary" onClick={() => handleUnarchive(params.row.propertyId)}>
            
            <Button variant="outlined" startIcon={<RestoreIcon />}>
              UnArchive
            </Button>
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={archivedPropertiesWithSerialId} // Use archivedPropertiesWithSerialId with serialId added
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default ArchivedTable;
