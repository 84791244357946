import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Switch,
  Container,
  Modal,
  Box,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import { format } from "date-fns";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getallcategories`);
        setCategories(response.data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(
        `${BaseURL}/changecategorystatus?id=${id}&status=${newStatus}`
      );
      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category.id === id ? { ...category, status: newStatus } : category
        )
      );
      setSnackbarMessage(`Status changed successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleArchive = async (id) => {
    try {
      await axios.put(`${BaseURL}/archivecategory/${id}`);
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category.id !== id)
      );
      setSnackbarMessage(`Category archived successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error archiving category:", error);
    }
  };

  const handleEditClick = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId);
    setSelectedCategory(category);
    setOpenEditModal(true);
    setEditedData({ ...category });
    // Set image preview with current category's image URL
    setImagePreview(category.category_image);
    setNewImage(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategory(null);
    setEditedData({});
    setImagePreview(null);
    setNewImage(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      // Keep current image preview if no new image selected
      setImagePreview(selectedCategory.category_image); // Assuming `category_image` is the field with the image path
      setNewImage(null);
    }
  };

  const handleSaveChanges = async () => {
    if (!editedData.name) {
      setSnackbarMessage("Name is required.");
      setSnackbarOpen(true);
      return;
    }
    const categoryId = selectedCategory.id;
    const formData = new FormData();
    formData.append("name", editedData.name);
    if (newImage) {
      formData.append("categoryImage", newImage);
    } else {
      formData.append("categoryImage", selectedCategory.category_image);
    }

    try {
      const response = await axios.put(
        `${BaseURL}/editcategory/${categoryId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Successfully saved changes:", response.data);

      const updatedCategories = categories.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              ...editedData,
              category_image:
                response.data.categoryImagePath || category.category_image,
            }
          : category
      );
      setCategories(updatedCategories);
      setSnackbarMessage("Changes saved successfully.");
      setSnackbarOpen(true);
      handleCloseEditModal();
    } catch (error) {
      console.error("Error saving changes:", error);
      setSnackbarMessage("Error saving changes.");
      setSnackbarOpen(true);
    } finally {
      // Reset image preview and new image state after save
      setImagePreview(null);
      setNewImage(null);
    }
  };

  const handleAddCategories = () => {
    navigate("/addcategories");
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    {
      field: "sn",
      headerName: "S/N",
      width: 70,
      renderCell: (params) => categories.indexOf(params.row) + 1,
    },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      renderCell: (params) => {
        const timestampValue = params.value ? new Date(params.value) : null;
        return timestampValue
          ? format(timestampValue, "dd/MM/yyyy HH:mm:ss")
          : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Switch
          checked={params.value === 1}
          onChange={() =>
            handleStatusChange(params.row.id, params.value === 1 ? 0 : 1)
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            onClick={() => handleEditClick(params.row.id)}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleArchive(params.row.id)}
            startIcon={<DeleteIcon />}
          >
            Archive
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <h4 className="card-title">Categories Management</h4>
                      <div>
                        <TextField
                          label="Search by Name"
                          variant="outlined"
                          size="small"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          style={{ marginRight: "10px" }}
                        />
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          <Link
                            to="/addcategories"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Add Categories
                          </Link>
                        </button>
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            textDecoration: "none",
                            marginLeft: "20px",
                          }}
                        >
                          <Link
                            to="/archivecategories"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Archive
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                  <Container>
                    <div style={{ height: 600, width: "100%" }}>
                      <DataGrid
                        rows={filteredCategories}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    </div>

                    <Modal
                      open={openEditModal}
                      onClose={handleCloseEditModal}
                      aria-labelledby="edit-category-modal"
                      aria-describedby="edit-category-modal-description"
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          p: 4,
                        }}
                      >
                        <Typography variant="h6" id="modal-modal-title">
                          Edit Category
                        </Typography>
                        <Box component="form" sx={{ mt: 2 }}>
                          <TextField
                            label="Name"
                            fullWidth
                            value={editedData.name || ""}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                name: e.target.value,
                              })
                            }
                            variant="outlined"
                            margin="normal"
                            required
                            error={!editedData.name}
                            helperText={
                              !editedData.name ? "Name is required" : ""
                            }
                          />
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="upload-image"
                            type="file"
                            onChange={handleImageChange}
                          />
                          <label htmlFor="upload-image">
                            <Button
                              variant="contained"
                              component="span"
                              sx={{ mt: 2 }}
                            >
                              Upload Image
                            </Button>
                          </label>
                          {imagePreview && (
                            <Box
                              component="img"
                              src={imagePreview}
                              alt="Image Preview"
                              sx={{
                                width: "100%",
                                height: "auto",
                                mt: 2,
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 2,
                            }}
                          >
                            <Button
                              onClick={handleCloseEditModal}
                              color="primary"
                              sx={{ mr: 1 }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleSaveChanges}
                              variant="contained"
                              color="primary"
                            >
                              Save Changes
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
