import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export default function PaymentsPage() {
  const [payments, setPayments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [staffData, setStaffData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const navigate = useNavigate();
  const [validPaymentIds, setValidPaymentIds] = useState({});


  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${BaseURL}/allpayments`)
      .then((response) => {
        const paymentsData = response.data.results || [];
        setPayments(paymentsData);
        setFilteredPayments(paymentsData);

        // Update validPaymentIds with valid payment_ids
        const validIds = paymentsData.reduce((acc, payment) => {
          if (payment.payment_id) {
            acc[payment.payment_id] = true;
          }
          return acc;
        }, {});
        setValidPaymentIds(validIds);
      })
      .catch((error) => {
        console.error("Error fetching payments:", error);
        setSnackbarMessage("Error fetching payments!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });

      axios
      .get(`${BaseURL}/getAllStaffsExceptLoggedInStaff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStaffData(response.data.staffs || []);
      })
      .catch((error) => {
        console.error("Error fetching staff data:", error);
        setSnackbarMessage("Error fetching staff data!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });

  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredPayments(payments);
    } else {
      const lowerCaseQuery = query.toLowerCase();
      const filtered = payments.filter(
        (payment) =>
          (payment.order_id &&
            payment.order_id.toLowerCase().includes(lowerCaseQuery)) ||
          (payment.payment_id &&
            payment.payment_id.toLowerCase().includes(lowerCaseQuery)) ||
          (payment.user_id &&
            payment.user_id.toString().toLowerCase().includes(lowerCaseQuery))
      );
      setFilteredPayments(filtered);
    }
  };

  const handleViewClick = (paymentId) => {
    navigate(`/payment-details/${paymentId}`);
  };

  const handleAssignStaffClick = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setAssignModalOpen(true);
  };


  const handleAssignStaff = () => {
    if (selectedStaff && selectedPaymentId) {
      axios
        .post(`${BaseURL}/assignStaffToPayment`, {
          payment_id: selectedPaymentId,
          staff_id: selectedStaff
        })
        .then(() => {
          setSnackbarMessage("Staff assigned successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setAssignModalOpen(false);

        })
        .catch((error) => {
          console.error("Error assigning staff:", error);
          setSnackbarMessage("Error assigning staff!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    }
  };


  const columns = [
    { field: "user_id", headerName: "User ID", width: 80 },
    { field: "user_name", headerName: "Name", width: 150 },
    { field: "user_email", headerName: "Email", width: 200 },
    // { field: "order_id", headerName: "Order Id", width: 200 },
    // { field: "payment_id", headerName: "Payment ID", width: 200 },
    { field: "payment_amount", headerName: "Amount", width: 120 },
    // { field: "payment_method", headerName: "Method", width: 80 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "timestamp",
      headerName: "Payment Date",
      width: 170,
      renderCell: (params) => formatTimestamp(params.value),
    },
    // { field: "service_names", headerName: "Service Name", width: 400 },
    {
      field: "actions",
      headerName: "Actions",
      width: 500, // Adjusted width to fit the content
      renderCell: (params) => {
        const { payment_id, invoice_name, invoice_number, assign_staff_id, staff_name } = params.row;
        const hasValidPaymentId = validPaymentIds[payment_id];
        const staffAssigned = Boolean(assign_staff_id);
    
        return hasValidPaymentId ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewClick(payment_id)}
            >
              View
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.open(invoice_name, '_blank', 'noopener,noreferrer')}
              style={{ marginLeft: 8 }} // Add some space between buttons
            >
              Invoice
            </Button>
            {staffAssigned ? (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ marginLeft: 8 }} // Add some space between buttons
            >
              Already Assigned {staff_name}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleAssignStaffClick(payment_id)}
              style={{ marginLeft: 8 }} // Add some space between buttons
            >
              Assign Staff
            </Button>
          )}

            
            
            {invoice_name && (
              <div style={{ marginTop: 8 }}>
                <a href={invoice_name} target="_blank" rel="noopener noreferrer">
                  {invoice_number}
                </a>
              </div>
            )}
          </>
        ) : null;
      },
    }
    
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 className="card-title">Payments</h4>
                    <TextField
                      label="Search by Order ID, Payment ID, or User ID"
                      variant="outlined"
                      value={searchQuery}
                      onChange={handleSearch}
                      style={{ marginRight: "8rem", width: "20rem" }}
                    />
                  </div>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={filteredPayments}
                      columns={columns}
                      pageSize={5}
                      pagination
                      rowsPerPageOptions={[5, 10]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>


      <Dialog
  open={assignModalOpen}
  onClose={() => setAssignModalOpen(false)}
>
  <DialogTitle style={{ backgroundColor: '#f5f5f5', color: '#333', width:'300px' }}>Assign Staff</DialogTitle>
  <DialogContent style={{ padding: '20px' }}>
    <Select
      fullWidth
      value={selectedStaff}
      onChange={(e) => setSelectedStaff(e.target.value)}
      displayEmpty
      style={{ color: '#333' }} // Text color for the Select component
    >
      <MenuItem value="" disabled>Select Staff</MenuItem>
      {staffData.map((staff) => (
        <MenuItem key={staff.id} value={staff.id}>
          {staff.name}
        </MenuItem>
      ))}
    </Select>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'space-between' }}>
    <Button onClick={() => setAssignModalOpen(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={handleAssignStaff} color="primary">
      Assign
    </Button>
  </DialogActions>
</Dialog>



    </div>
  );
}
