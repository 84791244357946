import React, { useEffect, useState } from "react";
import { isValidMobile } from "../../../utils/validations";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../BaseUrl";

function AddUserModal({ isOpen, onClose, setUsersArray, fetchData }) {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    country: "",
  });
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);


  const onChangeCountryHandler = (e) => {
    const selectedCountry = e.target.value;
    const country = countries.find(
      (country) => country.name === selectedCountry
    );
    if (country) {
      setUserDetails((prev) => ({
        ...prev,
        country: country.name,
        // state: "", 
        // city: "", 
      }));
      // setSelectedCountryIso2(country.iso2);
      // loadStates(country.iso2);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Ensure mobile field only takes numbers and has a maximum length of 10
    if (name === "mobile") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: cleanedValue,
      }));
    } else {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: value,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isValidMobile(userDetails.mobile)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }

      const response = await axios.post(`${BaseURL}/registerUser`, userDetails);

      if (response.status === 201) {
        alert("User registered successfully!");
        setUserDetails({
          name: "",
          email: "",
          mobile: "",
          password: "",
        });
        onClose(); // Close the modal
        fetchData(); // Fetch updated user list
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("There was an error registering the User:", error);
    }
  };

  useEffect(() => {
    const loadCountries = () => {
      axios
        .get("https://api.countrystatecity.in/v1/countries", {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        })
        .then((response) => {
          setCountries(response.data);
        })
        .catch((error) => {
          console.error("Error loading countries:", error);
        });
    };

    loadCountries();
  }, []);

  return (
    <div>
      {/* Background blur effect */}
      <div
        className="modal-background"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
          backdropFilter: "blur(1px)", // CSS backdrop-filter for blur effect
          zIndex: 1000, // Ensure this covers the entire screen
          display: isOpen ? "block" : "none", // Show only if modal is open
        }}
      ></div>

      {/* Modal */}
      <div
        className={`modal ${isOpen ? "show" : ""}`}
        style={{ display: isOpen ? "block" : "none", zIndex: 1100 }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ top: "10px" }}>
            <div className="modal-header">
              <h5 className="modal-title">Add User</h5>
              <button
                type="button"
                className="close"
                onClick={onClose}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: 0,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#000",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    value={userDetails.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={userDetails.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Mobile"
                    value={userDetails.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={userDetails.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Select Country:</label>
                  <select
                    name="country"
                    className="form-control form-select"
                    value={userDetails.country}
                    onChange={onChangeCountryHandler}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.iso2} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" className="btn btn-primary btn-block">
                    Add User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUserModal;
