import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestoreIcon from "@mui/icons-material/Restore";
import { Button } from "@mui/material";

function ArchivedUserTable({ archivedUsers, handleView, handleUnarchive }) {
  // Add serialNo to each archived user object
  const archivedUsersWithSerialNo = archivedUsers.map((user, index) => ({
    ...user,
    serialNo: index + 1,
  }));

  const columns = [
    { field: "serialNo", headerName: "S/N", width: 70 },
    // { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 170 },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <div style={{ textAlign: "left" }}>
          <IconButton color="primary" onClick={() => handleView(params.row.userId)}>
            {/* <VisibilityIcon /> */}
            <Button variant="outlined" color="success" startIcon={<VisibilityIcon />}>
              View
            </Button>

          </IconButton>
          <IconButton color="primary" onClick={() => handleUnarchive(params.row.userId)}>
           
            <Button variant="outlined"  startIcon={ <RestoreIcon />}>
              Unarchive
            </Button>

          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={archivedUsersWithSerialNo} // Use archivedUsersWithSerialNo with serialNo added
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default ArchivedUserTable;
