import React, { useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

export default function AddTestimonial() {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    rating: 1,
    designation: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    setImagePreview(URL.createObjectURL(file));
  };

  const handleRatingChange = (rating) => {
    setFormData({ ...formData, rating });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await axios.post(`${BaseURL}/addtestimonial`, data);
      if (response.data.success) {
        setFormData({
          name: "",
          description: "",
          rating: 0,
          designation: "",
          image: null,
        });
        setImagePreview(null);
        alert("Testimonial added successfully");
      } else {
        alert("Failed to add testimonial. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting testimonial:", error);
      alert("An error occurred while submitting the testimonial. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit} style={{ width: "50%", margin: "0 auto", padding:"20px 20px", borderRadius:"15px", background:"#efeded", border:"1px solid #c4c4c4"}}>
                    <h3 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>Add Testimonial</h3>
                    <div style={{ marginBottom: "15px" }}>
                      <label style={{ color: "#666" }}>Name:</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        style={{ width: "100%", padding: "10px", margin: "5px 0", borderRadius: "5px", border: "1px solid #ccc" }}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <label style={{ color: "#666" }}>Description:</label>
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                        style={{ width: "100%", padding: "10px", margin: "5px 0", borderRadius: "5px", border: "1px solid #ccc" }}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <label style={{ color: "#666" }}>Rating:</label>
                      <div style={{ display: "flex", gap: "5px" }}>
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            style={{
                              cursor: "pointer",
                              color: formData.rating >= star ? "#FFD700" : "#ccc",
                              fontSize: "20px",
                            }}
                            onClick={() => handleRatingChange(star)}
                          >
                            ★
                          </span>
                        ))}
                      </div>
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <label style={{ color: "#666" }}>Designation:</label>
                      <input
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={handleInputChange}
                        required
                        style={{ width: "100%", padding: "10px", margin: "5px 0", borderRadius: "5px", border: "1px solid #ccc" }}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <label style={{ color: "#666" }}>Image:</label>
                      <input
                        type="file"
                        name="image"
                        onChange={handleImageChange}
                        required
                        style={{ width: "100%", padding: "10px", margin: "5px 0", borderRadius: "5px", border: "1px solid #ccc" }}
                      />
                      {imagePreview && (
                        <div style={{ marginTop: "10px" }}>
                          <img src={imagePreview} alt="Preview" style={{ width: "30%", maxHeight: "300px", borderRadius: "5px" }} />
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        backgroundColor: "#4CAF50",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: isSubmitting ? "not-allowed" : "pointer",
                        width: "100%",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
