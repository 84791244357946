import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

export default function PaymentReport() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  

  const [startDate, setStartDate] = useState(formatDate(today));
  const [endDate, setEndDate] = useState(formatDate(tomorrow));
  const [reportData, setReportData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);


  const formatDate1 = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedStartDate = formatDate1(startDate);
  const formattedEndDate = formatDate1(endDate);

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      const total = reportData.reduce(
        (acc, item) => acc + parseFloat(item.paymentAmount),
        0
      );
      setTotalAmount(total);
    } else {
      setTotalAmount(0);
    }
  }, [reportData]);

  const fetchReport = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/getsalesreport?startDate=${startDate}&endDate=${endDate}`
      );
      if (response.data.success) {
        setReportData(response.data.salesReport);
      } else {
        setReportData([]);
        console.warn("No payments found");
      }
    } catch (error) {
      console.error("Error fetching report data", error);
      setReportData([]);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Sales Report", 14, 20);
    doc.text(`From: ${formattedStartDate} To: ${formattedEndDate}`, 14, 30);
    doc.autoTable({
      startY: 40,
      head: [
        [
          "Order ID",
          "Payment ID",
          "Service Name",
          "Payment Method",
          "Amount",
          "Timestamp",
        ],
      ],
      body: [
        ...reportData.map((row) => [
          row.orderId,
          row.paymentId,
          row.serviceNames && row.serviceNames.length > 0
            ? row.serviceNames.join(", ")
            : "N/A",
          row.paymentMethod,
          row.paymentAmount,
          new Date(row.timestamp).toLocaleString(),
        ]),
        ["", "", "", "Total Amount", totalAmount.toFixed(2), ""],
      ],
    });
    doc.save("sales_report.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet([
      {
        "Order ID": "Sales Report",
        "Payment ID": "",
        "Service Name": "",
        "Payment Method": `From: ${formattedStartDate} To: ${formattedEndDate}`,
        Amount: "",
        Timestamp: "",
      },
      ...reportData.map((row) => ({
        "Order ID": row.orderId,
        "Payment ID": row.paymentId,
        "Service Name":
          row.serviceNames && row.serviceNames.length > 0
            ? row.serviceNames.join(", ")
            : "N/A",
        "Payment Method": row.paymentMethod,
        Amount: row.paymentAmount,
        Timestamp: new Date(row.timestamp).toLocaleString(),
      })),
      {
        "Order ID": "",
        "Payment ID": "",
        "Service Name": "",
        "Payment Method": "Total Amount",
        Amount: totalAmount.toFixed(2),
        Timestamp: "",
      },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sales Report");
    XLSX.writeFile(wb, "sales_report.xlsx");
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={fetchReport}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={generatePDF}
                    >
                      Generate PDF
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={exportToExcel}
                    >
                      Export to Excel
                    </Button>
                    <Link
                      to="/dashboard/refundreport"
                      variant="contained"
                      color="primary"
                      className="btn btn-secondary"
                      style={{ background: "#536493" }}
                    >
                      REFUND REPORT
                    </Link>
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Order ID</TableCell>
                          <TableCell>Payment ID</TableCell>
                          <TableCell>Service Name</TableCell>
                          <TableCell>Payment Method</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Timestamp</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportData && reportData.length > 0 ? (
                          reportData.map((row) => (
                            <TableRow key={row.orderId}>
                              <TableCell>{row.orderId}</TableCell>
                              <TableCell>{row.paymentId}</TableCell>
                              <TableCell>
                                {row.serviceNames && row.serviceNames.length > 0
                                  ? row.serviceNames.join(", ")
                                  : "N/A"}
                              </TableCell>
                              <TableCell>{row.paymentMethod}</TableCell>
                              <TableCell>{row.paymentAmount}</TableCell>
                              <TableCell>
                                {new Date(row.timestamp).toLocaleString()}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ marginTop: "20px", fontWeight: "bold" }}>
                    Total Amount: ₹{totalAmount.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
