import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Switch demo" } };

function Userstable({ usersArray, handleEdit, handleDelete, setUsersArray }) {
  const navigate = useNavigate();

  // Add serialId to each user object
  const usersWithSerialId = usersArray.map((user, index) => ({
    ...user,
    serialId: index + 1,
  }));

  const handleView = (id) => {
    navigate(`/viewuserdetails/${id}`);
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      const response = await axios.put(
        `${BaseURL}/changeStatusUserSide?id=${id}&status=${newStatus}`
      );
      if (
        response.status === 201 &&
        response.data.message === "Status updated successfully"
      ) {
        setUsersArray(usersArray.map((user) =>
          user.id === id ? { ...user, status: newStatus } : user
        ));
        console.log("Status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const columns = [
    { field: "serialId", headerName: "S/N", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "mobile", headerName: "Mobile", type: "number", width: 160 },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={() => handleStatusChange(params.row.id, params.row.status)}
          />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleEdit(params.row.id)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => handleDelete(params.row.id)}
          >
            Archive
          </button>
          <button
            className="btn btn-sm btn-info ms-2"
            onClick={() => handleView(params.row.id)}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DataGrid
        rows={usersWithSerialId} // Use usersWithSerialId with serialId added
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default Userstable;
