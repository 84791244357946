import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Container,
  Modal,
  Box,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import { format } from "date-fns";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from '@mui/icons-material/Restore';
import { Link, useNavigate } from "react-router-dom";

export default function ArchiveCat() {
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getarchivedcategories`);
        setCategories(response.data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleArchive = async (categoryId) => {
    try {
      await axios.put(`${BaseURL}/unarchivecategory/${categoryId}`);
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category.categoryId !== categoryId)
      );
      setSnackbarMessage(`Category unarchived successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error unarchiving category:", error);
    }
  };

  const handleDelete = async (categoryId) => {
    try {
      await axios.delete(`${BaseURL}/deletecategory/${categoryId}`);
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category.categoryId !== categoryId)
      );
      setSnackbarMessage(`Category Delete successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error Deleting category:", error);
    }
  };
  

  const handleAddCategories = () => {
    navigate("/addcategories");
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    {
      field: "sn",
      headerName: "S/N",
      width: 70,
      renderCell: (params) => categories.indexOf(params.row) + 1,
    },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      renderCell: (params) => {
        const timestampValue = params.value ? new Date(params.value) : null;
        return timestampValue
          ? format(timestampValue, "dd/MM/yyyy HH:mm:ss")
          : "";
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleArchive(params.row.categoryId)}
            startIcon={<RestoreIcon />}
          >
            UnArchive
          </Button>
          {/* <Button
            variant="outlined"
            color="error"
            onClick={() => handleDelete(params.row.categoryId)}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button> */}
        </Box>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <h4 className="card-title">Archive Categories</h4>
                      <div>
                        <TextField
                          label="Search by Name"
                          variant="outlined"
                          size="small"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          style={{ marginRight: "10px" }}
                        />
                        
                        <button
                          onClick={() => navigate(-1)}
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            textDecoration: "none",
                            marginLeft: "20px",
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <Container>
                    <div style={{ height: 600, width: "100%" }}>
                      <DataGrid
                        rows={filteredCategories}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
