import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Snackbar,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

export default function ArchiveServices() {
  const [services, setServices] = useState([]);
  const [search, setSearch] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getallarchivedservices`);
        setServices(response.data.archived_services);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  }, []);

  const handleRestore = async (service_id) => {
    try {
      await axios.put(`${BaseURL}/unarchiveservice/${service_id}`);
      setServices((prevServices) =>
        prevServices.filter((service) => service.service_id !== service_id)
      );
      setSnackbarMessage(`Service Unarchive successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error Unarchive service:", error);
    }
  };

  const handleDelete = async (service_id) => {
    try {
      await axios.delete(`${BaseURL}/deleteservice/${service_id}`);
      setServices((prevServices) =>
        prevServices.filter((service) => service.service_id !== service_id)
      );
      setSnackbarMessage(`Service Delete successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error Deleting service:", error);
    }
  };

  const filteredServices = services.filter((service) =>
    service.name.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    {
      field: "sn",
      headerName: "S/N",
      width: 70,
      renderCell: (params) => services.indexOf(params.row) + 1,
    },
    { field: "name", headerName: "Name", width: 200 },
    { field: "actual_price", headerName: "Actual Price", width: 100 },
    { field: "discounted_price", headerName: "Discounted Price", width: 100 },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      renderCell: (params) => {
        const timestampValue = params.value ? new Date(params.value) : null;
        return timestampValue
          ? format(timestampValue, "dd/MM/yyyy HH:mm:ss")
          : "";
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{ __html: params.value }}
          style={{ whiteSpace: "pre-wrap" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleRestore(params.row.service_id)}
            startIcon={<RestoreIcon />}
          >
            Unarchive
          </Button>
          {/* <Button
            variant="outlined"
            color="error"
            onClick={() => handleDelete(params.row.service_id)}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button> */}
        </Box>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <h4 className="card-title">Archive Services</h4>
                      <div>
                        <TextField
                          label="Search by Name"
                          variant="outlined"
                          size="small"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          style={{ marginRight: "10px" }}
                        />
                        <button
                          onClick={() => navigate(-1)}
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            textDecoration: "none",
                            marginLeft: "20px",
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <Container>
                    <div style={{ height: 600, width: "100%" }}>
                      <DataGrid
                        rows={filteredServices}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
