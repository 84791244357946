import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

export default function RefundPaymentReport() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const [startDate, setStartDate] = useState(formatDate(today));
  const [endDate, setEndDate] = useState(formatDate(tomorrow));
  const [reportData, setReportData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);



  const formatDate1 = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedStartDate = formatDate1(startDate);
  const formattedEndDate = formatDate1(endDate);

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      const total = reportData.reduce(
        (acc, item) =>
          acc +
          item.manual_refund_service_details.reduce(
            (subAcc, subItem) => subAcc + parseFloat(subItem.refundAmount),
            0
          ),
        0
      );
      setTotalAmount(total);
    } else {
      setTotalAmount(0);
    }
  }, [reportData]);

  const fetchReport = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/getrefundeport?startDate=${startDate}&endDate=${endDate}`
      );
      if (response.data.success) {
        setReportData(response.data.results);
        console.log(response.data.results, "reportdata");
      } else {
        setReportData([]);
        console.warn("No refunds found");
      }
    } catch (error) {
      console.error("Error fetching report data", error);
      setReportData([]);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Refund Report", 14, 20);
    doc.text(`From: ${formattedStartDate} To: ${formattedEndDate}`, 14, 30);
    doc.autoTable({
      startY: 40,
      head: [
        [
          "Payment ID",
          "Refund ID",
          "Service Name",
          "Refund Method",
          "Amount",
          "Timestamp",
        ],
      ],
      body: [
        ...reportData.flatMap((row) =>
          row.manual_refund_service_details.map((subRow) => [
            row.payment_id,
            subRow.manual_refund_ref_id,
            subRow.serviceName,
            row.refund_method,
            subRow.refundAmount,
            new Date(row.timestamp).toLocaleString(),
          ])
        ),
        ["", "", "", "Total Amount", totalAmount.toFixed(2), ""],
      ],
    });
    doc.save("refund_report.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet([
      {
        "Payment ID": "Refund Report",
        "Refund ID": "",
        "Service Name": "",
        "Refund Method": `From: ${formattedStartDate} To: ${formattedEndDate}`,
        Amount: "",
        Timestamp: "",
      },
      ...reportData.flatMap((row) =>
        row.manual_refund_service_details.map((subRow) => ({
          "Payment ID": row.payment_id,
          "Refund ID": subRow.manual_refund_ref_id,
          "Service Name": subRow.serviceName,
          "Refund Method": row.refund_method,
          Amount: subRow.refundAmount,
          Timestamp: new Date(row.timestamp).toLocaleString(),
        }))
      ),
      {
        "Payment ID": "",
        "Refund ID": "",
        "Service Name": "",
        "Refund Method": "Total Amount",
        Amount: totalAmount.toFixed(2),
        Timestamp: "",
      },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Refund Report");
    XLSX.writeFile(wb, "refund_report.xlsx");
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={fetchReport}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={generatePDF}
                    >
                      Generate PDF
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={exportToExcel}
                    >
                      Export to Excel
                    </Button>
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Payment ID</TableCell>
                          <TableCell>Refund ID</TableCell>
                          <TableCell>Service Name</TableCell>
                          <TableCell>Refund Method</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Timestamp</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportData && reportData.length > 0 ? (
                          reportData.flatMap((row) =>
                            row.manual_refund_service_details.map((subRow) => (
                              <TableRow key={subRow.manual_refund_ref_id}>
                                <TableCell>{row.payment_id}</TableCell>
                                <TableCell>{subRow.manual_refund_ref_id}</TableCell>
                                <TableCell>{subRow.serviceName}</TableCell>
                                <TableCell>{row.refund_method}</TableCell>
                                <TableCell>{subRow.refundAmount}</TableCell>
                                <TableCell>
                                  {new Date(row.timestamp).toLocaleString()}
                                </TableCell>
                              </TableRow>
                            ))
                          )
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ marginTop: "20px", fontWeight: "bold" }}>
                    Total Amount: ₹{totalAmount.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
