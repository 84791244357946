import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { format } from "date-fns";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";

export default function CouponsTable() {
  const [coupons, setCoupons] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState(null);
  const [newCouponData, setNewCouponData] = useState({
    code: "",
    discountType: "",
    discountValue: "",
    usageLimit: "",
    expiry_date: "",
  });

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(`${BaseURL}/coupons`);
      setCoupons(response.data.results);
    } catch (error) {
      console.error("Error fetching coupons", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const validateCouponData = (data) => {
    const { code, discountType, discountValue, usageLimit, expiry_date } = data;

    // Validate that all fields are filled
    if (!code || !discountType || !discountValue || !usageLimit || !expiry_date) {
      alert("Please fill in all fields.");
      return false;
    }

    // Validate discount value based on discount type
    if (discountType === "percentage" && discountValue > 100) {
      alert("Discount value cannot exceed 100% for percentage discounts.");
      return false;
    }

    return true;
  };

  const handleAddCoupon = async () => {
    if (!validateCouponData(newCouponData)) {
      return; // Exit the function if validation fails
    }

    try {
      await axios.post(`${BaseURL}/addcoupon`, newCouponData);
      await fetchCoupons(); 
      setOpenAddModal(false);
      setNewCouponData({
        code: "",
        discountType: "",
        discountValue: "",
        usageLimit: "",
        expiry_date: "",
      });
    } catch (error) {
      console.error("Error adding coupon", error);
      // Display an alert with the error message
      window.alert("Error adding coupon: " + (error.response?.data?.error || "An unknown error occurred"));
    }
    
  };

  const handleEditCoupon = async () => {
    if (!validateCouponData(newCouponData)) {
      return; // Exit the function if validation fails
    }

    try {
      await axios.put(`${BaseURL}/editcoupon/${currentCoupon.id}`, newCouponData);
      await fetchCoupons(); // Fetch the updated list of coupons
      setOpenEditModal(false);
      setNewCouponData({
        code: "",
        discountType: "",
        discountValue: "",
        usageLimit: "",
        expiry_date: "",
      });
      setCurrentCoupon(null);
    } catch (error) {
      console.error("Error editing coupon", error);
    }
  };

  const handleDeleteCoupon = async (id) => {
    try {
      await axios.delete(`${BaseURL}/deletecoupon/${id}`);
      await fetchCoupons(); // Fetch the updated list of coupons
    } catch (error) {
      console.error("Error deleting coupon", error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S/N",
      width: 70,
      renderCell: (params) => coupons.indexOf(params.row) + 1,
    },
    { field: "code", headerName: "Code", width: 200 },
    { field: "discount_type", headerName: "Discount Type", width: 150 },
    { field: "discount_value", headerName: "Discount Value", width: 150 },
    { field: "usage_limit", headerName: "Usage Limit", width: 150 },
    { field: "expiry_date", headerName: "Expiry Date", width: 150 },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      renderCell: (params) => {
        const timestampValue = params.value ? new Date(params.value) : null;
        return timestampValue
          ? format(timestampValue, "dd/MM/yyyy HH:mm:ss")
          : "";
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setCurrentCoupon(params.row);
              setNewCouponData({
                code: params.row.code,
                discountType: params.row.discount_type,
                discountValue: params.row.discount_value,
                usageLimit: params.row.usage_limit,
                expiry_date: params.row.expiry_date,
              });
              setOpenEditModal(true);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDeleteCoupon(params.row.id)}
            style={{ marginLeft: "10px", backgroundColor: "red" }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <h4 className="card-title">Coupon Management</h4>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAddModal(true)}
                      >
                        Add Coupon
                      </Button>
                    </div>

                    <Container>
                      <div style={{ height: 600, width: "100%" }}>
                        <DataGrid
                          rows={coupons}
                          columns={columns}
                          pageSize={10}
                          rowsPerPageOptions={[10]}
                          disableSelectionOnClick
                          getRowId={(row) => row.id}
                        />
                      </div>

                      <Modal
                        open={openAddModal}
                        onClose={() => setOpenAddModal(false)}
                        aria-labelledby="add-coupon-modal"
                        aria-describedby="add-coupon-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <Typography variant="h6" id="modal-modal-title">
                            Add New Coupon
                          </Typography>
                          <Box component="form" sx={{ mt: 2 }}>
                            <FormControl fullWidth margin="normal">
                              <TextField
                                label="Code"
                                fullWidth
                                type="text"
                                value={newCouponData.code}
                                onChange={(e) =>
                                  setNewCouponData({
                                    ...newCouponData,
                                    code: e.target.value,
                                  })
                                }
                                variant="outlined"
                                margin="normal"
                                required
                              />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                              <InputLabel>Discount Type</InputLabel>
                              <Select
                                label="Discount Type"
                                value={newCouponData.discountType}
                                onChange={(e) =>
                                  setNewCouponData({
                                    ...newCouponData,
                                    discountType: e.target.value,
                                  })
                                }
                                variant="outlined"
                                required
                              >
                                <MenuItem value="percentage">
                                  Percentage
                                </MenuItem>
                                <MenuItem value="fixed">Fixed</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              label="Discount Value"
                              fullWidth
                              type="number"
                              value={newCouponData.discountValue}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (
                                  newCouponData.discountType === "percentage" &&
                                  value > 100
                                ) {
                                  alert("Discount value cannot exceed 100% for percentage discounts.");
                                  return;
                                }
                                setNewCouponData({
                                  ...newCouponData,
                                  discountValue: value,
                                });
                              }}
                              variant="outlined"
                              margin="normal"
                              required
                            />
                            <TextField
                              label="Usage Limit"
                              fullWidth
                              type="number"
                              value={newCouponData.usageLimit}
                              onChange={(e) =>
                                setNewCouponData({
                                  ...newCouponData,
                                  usageLimit: parseInt(e.target.value, 10),
                                })
                              }
                              variant="outlined"
                              margin="normal"
                              required
                            />
                            <TextField
                              label="Expiry Date"
                              fullWidth
                              type="date"
                              value={newCouponData.expiry_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                setNewCouponData({
                                  ...newCouponData,
                                  expiry_date: e.target.value,
                                })
                              }
                              variant="outlined"
                              margin="normal"
                            />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mt: 2,
                              }}
                            >
                              <Button
                                onClick={() => setOpenAddModal(false)}
                                color="primary"
                                sx={{ mr: 1 }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleAddCoupon}
                                variant="contained"
                                color="primary"
                              >
                                Add Coupon
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>

                      <Modal
                        open={openEditModal}
                        onClose={() => setOpenEditModal(false)}
                        aria-labelledby="edit-coupon-modal"
                        aria-describedby="edit-coupon-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <Typography variant="h6" id="modal-modal-title">
                            Edit Coupon
                          </Typography>
                          <Box component="form" sx={{ mt: 2 }}>
                            <FormControl fullWidth margin="normal">
                              <TextField
                                label="Code"
                                fullWidth
                                type="text"
                                value={newCouponData.code}
                                onChange={(e) =>
                                  setNewCouponData({
                                    ...newCouponData,
                                    code: e.target.value,
                                  })
                                }
                                variant="outlined"
                                margin="normal"
                              />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                              <InputLabel>Discount Type</InputLabel>
                              <Select
                                label="Discount Type"
                                value={newCouponData.discountType}
                                onChange={(e) =>
                                  setNewCouponData({
                                    ...newCouponData,
                                    discountType: e.target.value,
                                  })
                                }
                                variant="outlined"
                              >
                                <MenuItem value="percentage">
                                  Percentage
                                </MenuItem>
                                <MenuItem value="fixed">Fixed</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              label="Discount Value"
                              fullWidth
                              type="number"
                              value={newCouponData.discountValue}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (
                                  newCouponData.discountType === "percentage" &&
                                  value > 100
                                ) {
                                  alert("Discount value cannot exceed 100% for percentage discounts.");
                                  return;
                                }
                                setNewCouponData({
                                  ...newCouponData,
                                  discountValue: value,
                                });
                              }}
                              variant="outlined"
                              margin="normal"
                            />
                            <TextField
                              label="Usage Limit"
                              fullWidth
                              type="number"
                              value={newCouponData.usageLimit}
                              onChange={(e) =>
                                setNewCouponData({
                                  ...newCouponData,
                                  usageLimit: parseInt(e.target.value, 10),
                                })
                              }
                              variant="outlined"
                              margin="normal"
                            />
                            <TextField
                              label="Expiry Date"
                              fullWidth
                              type="date"
                              value={newCouponData.expiry_date}
                              onChange={(e) =>
                                setNewCouponData({
                                  ...newCouponData,
                                  expiry_date: e.target.value,
                                })
                              }
                              variant="outlined"
                              margin="normal"
                            />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mt: 2,
                              }}
                            >
                              <Button
                                onClick={() => setOpenEditModal(false)}
                                color="primary"
                                sx={{ mr: 1 }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleEditCoupon}
                                variant="contained"
                                color="primary"
                              >
                                Save Changes
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
