import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

export default function Sidebar() {
  const [staffDetails, setStaffDetails] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const decodeStaffToken = () => {
      const token = localStorage.getItem("token");
      console.log("Token retrieved:", token); // Log the token
  
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          console.log("Decoded Token:", decodedToken); // Log the decoded token
  
          // Handle permissions based on its type
          const permissions = typeof decodedToken.permissions === 'string'
            ? decodedToken.permissions.split(',').map(Number) // Convert string to array of numbers
            : (Array.isArray(decodedToken.permissions) ? decodedToken.permissions.map(Number) : []); // Handle array case
  
          console.log("Parsed Permissions:", permissions); // Log the parsed permissions
  
          setStaffDetails({
            id: decodedToken._id,
            email: decodedToken.email,
            name: decodedToken.name,
            mobile: decodedToken.mobile,
            type: decodedToken.type,
            permissions,
          });
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      } else {
        console.warn("No token found in localStorage");
      }
    };
  
    decodeStaffToken();
  }, []);
  
  

  if (!staffDetails) {
    console.log('No staff details available');
    return <div>Loading...</div>; // Or any other placeholder
  }

  const { type, permissions } = staffDetails;

  const isActiveLink = (path) => location.pathname.startsWith(path);

  const renderLink = (to, iconClass, label, requiredPermission) => {
    const hasPermission = requiredPermission === undefined || permissions.includes(requiredPermission);
    console.log(`Rendering link: ${label}, Required Permission: ${requiredPermission}, Has Permission: ${hasPermission}`);
    return hasPermission && (
      <li className={`nav-item ${isActiveLink(to) ? "active" : ""}`}>
        <Link className="nav-link" to={to}>
          <i className={iconClass} style={{ fontSize: "24px", marginRight: "10px" }}></i>
          <span className="menu-title">{label}</span>
        </Link>
      </li>
    );
  };

  console.log('Rendering sidebar for type:', type);
  console.log('User Permissions:', permissions);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{ height: "90vh", overflowY: "scroll" }}>
      <ul className="nav">
        {type === 1 && (
          <>
            {renderLink("/dashboard", "mdi mdi-chart-bar", "Dashboard")}
            {renderLink("/staffs", "fa fa-user-plus", "Staffs")}
            {renderLink("/properties", "fa fa-building-o", "Properties")}
            {renderLink("/users", "fa fa-users", "Users")}
            {renderLink("/services", "fa fa-plus", "Services")}
            {renderLink("/tickets", "fa fa-commenting", "Tickets")}
            {renderLink("/rewards", "fa fa-trophy", "Rewards")}
            {renderLink("/payments", "fa fa-credit-card", "Payments")}
            {renderLink("/activities", "fa fa-tasks", "Activities")}
            {renderLink("/userquery", "fa fa-question", "User Query")}
            {renderLink("/coupons", "fa fa-tag fa-lg", "Coupons")}
            {renderLink("/testimonial", "fa fa-quote-left", "Testimonials")}
            {renderLink("/agents", "fa fa-user-secret", "Agents")}
            {renderLink("/about", "fa fa-info-circle", "About")}
            {renderLink("/teams", "fa fa-sitemap", "Teams")}
            {renderLink("/settings", "fa fa-gear", "Settings")}
          </>
        )}
        {type === 0 && (
          <>
            {renderLink("/properties", "fa fa-building-o", "Properties", 0)}
            {renderLink("/users", "fa fa-users", "Users", 1)}
            {renderLink("/services", "fa fa-plus", "Services", 2)}
            {renderLink("/tickets", "fa fa-commenting", "Tickets", 4)}
            {renderLink("/rewards", "fa fa-trophy", "Rewards", 5)}
            {renderLink("/payments", "fa fa-credit-card", "Payments", 6)}
            {renderLink("/activities", "fa fa-tasks", "Activities", 7)}
            {renderLink("/userquery", "fa fa-question", "User Query", 8)}
            {renderLink("/coupons", "fa fa-tag fa-lg", "Coupons", 9)}
            {renderLink("/testimonial", "fa fa-quote-left", "Testimonials", 10)}
            {renderLink("/agents", "fa fa-user-secret", "Agents", 11)}
            {renderLink("/about", "fa fa-info-circle", "About", 12)}
            {renderLink("/teams", "fa fa-sitemap", "Teams", 13)}
          </>
        )}
      </ul>
    </nav>
  );
}
