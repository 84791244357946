import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../BaseUrl";
import { TextField, Button, Alert } from "@mui/material";
import { styled } from "@mui/system";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { useDropzone } from "react-dropzone";

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
});

export default function AddCategories() {
  const [category, setCategory] = useState("");
  const [ip, setIP] = useState("");
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [categoryImage, setCategoryImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        setIP(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIP();
  }, []);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCategoryImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (category.trim() === "") {
      setAlert({
        show: true,
        type: "error",
        message: "Category name cannot be empty.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", category);
    formData.append("ip", ip);
    if (categoryImage) {
      formData.append("categoryImage", categoryImage);
    }

    try {
      const response = await axios.post(`${BaseURL}/addcategory`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      if (response.data.success === true) {
        console.log("Category added:", response.data);
        setAlert({
          show: true,
          type: "success",
          message: response.data.message,
        });
        setCategory("");
        setCategoryImage(null);
        setImagePreview(null);
      } else {
        throw new Error(response.data.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error adding category:", error);
      setAlert({
        show: true,
        type: "error",
        message: error.message || "Failed to add category.",
      });
    }finally {
      setIsSubmitting(false);
    };

  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <h3>Add Categories</h3>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </Row>
                  {alert.show && (
                    <Alert
                      severity={alert.type}
                      onClose={() =>
                        setAlert({ show: false, type: "", message: "" })
                      }
                    >
                      {alert.message}
                    </Alert>
                  )}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      label="Category"
                      variant="outlined"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      sx={{
                        flex: 1,
                        marginRight: "20px",
                        maxWidth: "300px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                  <div
                    {...getRootProps()}
                    style={{
                      border: "2px dashed #ccc",
                      padding: "20px",
                      textAlign: "center",
                      marginTop: "20px",
                      cursor: "pointer",
                      width:"300px"
                    }}
                  >
                    <input {...getInputProps()} />
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{ width: "50%", maxHeight: "300px" }}
                      />
                    ) : (
                      <p>Drag 'n' drop an image here, or click to select one</p>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{
                      marginTop: "20px",
                      padding: "10px 20px",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#115293",
                      },
                    }}
                    disabled={isSubmitting}
                  >
                    
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
