import React, { useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import {
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

export default function AddAdvertisement() {
  const [newAd, setNewAd] = useState({
    title: "",
    url: "",
    start_date: "",
    end_date: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAd({ ...newAd, [name]: value });
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!newAd.title) formErrors.title = "Ads Name is required";
    if (!newAd.url) formErrors.url = "Ads Link is required";
    if (!newAd.start_date) formErrors.start_date = "Start Date is required";
    if (!newAd.end_date) formErrors.end_date = "End Date is required";
    if (!selectedImage) formErrors.ad_image = "Ads Image is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleAddAdvertisement = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append("title", newAd.title);
    formData.append("url", newAd.url);
    formData.append("start_date", newAd.start_date);
    formData.append("end_date", newAd.end_date);
    if (selectedImage) {
      formData.append("ad_image", selectedImage);
    }

    try {
      const response = await axios.post(`${BaseURL}/postAd`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert(response.data.message || "Advertisement Added Successfully");
      navigate("/advertisement");
    } catch (error) {
      alert(error.response?.data?.message || "Error adding advertisement");
    }
  };

  return (
    <div className="container-scroller">
      <UITheam />
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <Paper style={{ margin: "auto", padding: "20px", maxWidth: "500px", overflowY:'auto', height:'80vh' }}>
          <Typography variant="h6" style={{marginTop:'5px'}}>Add Advertisement</Typography>
          <form>
            <TextField
              label="Ads Name"
              name="title"
              value={newAd.title}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              error={!!errors.title}
              helperText={errors.title}
            />
            <TextField
              label="Ads Link"
              name="url"
              value={newAd.url}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              error={!!errors.url}
              helperText={errors.url}
            />
            <Typography>Select Ads Images</Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ margin: "20px 0" }}
            />
            {errors.ad_image && (
              <Typography color="error" style={{ marginBottom: "10px" }}>
                {errors.ad_image}
              </Typography>
            )}
            {selectedImage && (
              <div style={{ marginBottom: "20px" }}>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "8px" }}
                />
              </div>
            )}
            <TextField
              label="Start Date"
              name="start_date"
              type="date"
              value={newAd.start_date}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              error={!!errors.start_date}
              helperText={errors.start_date}
            />
            <TextField
              label="End Date"
              name="end_date"
              type="date"
              value={newAd.end_date}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              error={!!errors.end_date}
              helperText={errors.end_date}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAdvertisement}
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </form>
        </Paper>
      </div>
      <Footer />
    </div>
  );
}
