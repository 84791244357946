import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function UserQuery() {
  const [queries, setQueries] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredQueries, setFilteredQueries] = useState([]);

  useEffect(() => {
    fetchQueries();
  }, []);

  useEffect(() => {
    // Filter queries whenever search input changes
    if (searchInput.trim() === "") {
      setFilteredQueries(queries);
    } else {
      const filtered = queries.filter(
        (query) =>
          query.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          query.email.toLowerCase().includes(searchInput.toLowerCase()) ||
          query.phone.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredQueries(filtered);
    }
  }, [searchInput, queries]);

  const fetchQueries = async () => {
    try {
      const response = await axios.get(`${BaseURL}/getallqueries`);
      if (response.data.success) {
        setQueries(response.data.results);
        setFilteredQueries(response.data.results); // Initially set filtered queries to all queries
      } else {
        console.error("Failed to fetch queries:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching queries:", error);
    }
  };

  const deleteQuery = async (id) => {
    try {
      const response = await axios.delete(`${BaseURL}/deletequery/${id}`);
      if (response.data.success) {
        setQueries(queries.filter((query) => query.id !== id));
        setFilteredQueries(filteredQueries.filter((query) => query.id !== id));
        alert(response.data.message);
      } else {
        console.error("Failed to delete query:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting query:", error);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const dateObj = new Date(timestamp);
    const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
    const formattedTime = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const handleViewOpen = (query) => {
    setSelectedQuery(query);
    setOpen(true);
  };

  const handleViewClose = () => {
    setOpen(false);
    setSelectedQuery(null);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const columns = [
    { field: 'sn', headerName: 'S/N', width: 70 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    {
      field: 'subject', headerName: 'Subject', width: 150,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'query', headerName: 'Query', width: 150,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'timestamp', headerName: 'Timestamp', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleViewOpen(params.row)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => deleteQuery(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = filteredQueries.map((query, index) => ({
    id: query.id,
    sn: index + 1,
    name: query.name,
    email: query.email,
    phone: query.phone,
    subject: query.subject,
    query: query.query,
    timestamp: query.timestamp ? formatDate(query.timestamp) : '',
  }));

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <h4 className="card-title">User Queries</h4>
                  
                  {/* Search Input */}
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchInput}
                    onChange={handleSearchChange}
                    style={{ marginBottom: 10, width:'250px', height:'50px'  }}
                  />
                  </div>
                  <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={5}
                      pagination
                      rowsPerPageOptions={[5, 10]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Dialog open={open} onClose={handleViewClose} aria-labelledby="view-dialog-title">
        <DialogTitle id="view-dialog-title">Query Details</DialogTitle>
        <DialogContent>
          {selectedQuery && (
            <>
              <DialogContentText><strong>Name:</strong> {selectedQuery.name}</DialogContentText>
              <DialogContentText><strong>Email:</strong> {selectedQuery.email}</DialogContentText>
              <DialogContentText><strong>Phone:</strong> {selectedQuery.phone}</DialogContentText>
              <DialogContentText><strong>Subject:</strong> {selectedQuery.subject}</DialogContentText>
              <DialogContentText><strong>Query:</strong> {selectedQuery.query}</DialogContentText>
              <DialogContentText><strong>Timestamp:</strong> {selectedQuery.timestamp}</DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
