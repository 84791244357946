import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";

export default function UserService() {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [servicesPerPage] = useState(10); // Number of services per page
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${BaseURL}/servicespurchased/${id}`)
      .then((response) => {
        if (response.data.message === "Services found") {
          setServices(response.data.results);
        } else {
          setServices([]);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the services data!", error);
        setServices([]);
      });
  }, [id]);

  // Pagination logic
  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = services.slice(indexOfFirstService, indexOfLastService);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (date) => {
    const d = new Date(date);
    const formattedDate = `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getFullYear()}`;
    return formattedDate;
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Services Purchased</h4>
                  <div style={{ overflowX: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Service Name</th>
                          <th>Service Price</th>
                          <th>Category</th>
                          <th>Subcategory</th>
                          <th>Date of Purchase</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentServices.length > 0 ? (
                          currentServices.map((service, index) => (
                            <tr key={index}>
                              <td>{service.service_id}</td>
                              <td>{service.service_name}</td>
                              <td>{service.service_price}</td>
                              <td>{service.category_name || "N/A"}</td>
                              <td>{service.subcategory_name || "N/A"}</td>
                              <td>{formatDate(service.date_of_purchase)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: "center" }}>
                              No services purchased found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  
                  {/* Pagination */}
                  {services.length > servicesPerPage && (
                    <nav>
                      <ul className="pagination">
                        {Array(Math.ceil(services.length / servicesPerPage))
                          .fill()
                          .map((_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                              <button className="page-link" onClick={() => paginate(index + 1)}>
                                {index + 1}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
