import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const htmlToText = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

const formatDate = (dateString) => {
  const date = new Date(dateString.split("T")[0]); // Remove time part and parse
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // Ensure day and month are always two digits
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
};

export default function Package() {
  const [packages, setPackages] = useState([]);
  const [editPackage, setEditPackage] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch all packages data from the API
    axios
      .get(`${BaseURL}/getallpackages`)
      .then((response) => {
        setPackages(response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  const handleStatusChange = async (id, currentStatus) => {
    // Toggle the status value between 0 and 1
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      // Send the updated package status to the backend API
      const response = await axios.put(
        `${BaseURL}/changestatusofpackage?id=${id}&status=${newStatus}`
      );
      if (
        response.status === 201 &&
        response.data.message === "Package status changed successfully"
      ) {
        // Update the state to reflect the new status
        setPackages(
          packages.map((pack) =>
            pack.id === id ? { ...pack, status: newStatus } : pack
          )
        );
        setSnackbarMessage("Package status updated successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setSnackbarMessage("Error updating package status!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Send delete request to backend API
      const response = await axios.delete(`${BaseURL}/deletepackage/${id}`);
      if (
        response.status === 201 &&
        response.data.message === "Package deleted successfully"
      ) {
        // Remove the deleted package from state
        setPackages(packages.filter((pack) => pack.id !== id));
        setSnackbarMessage("Package deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting package:", error);
      setSnackbarMessage("Error deleting package!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (pack) => {
    // Set the package to be edited
    setEditPackage(pack);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditPackage(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSave = async () => {
    console.log("Save button clicked");
    try {
      console.log("Sending request to backend API");
      // Send the updated package data to the backend API
      const response = await axios.patch(
        `${BaseURL}/editpackage/${editPackage.id}`,
        editPackage
      );
      console.log("Response received:", response);

      // Check the response status and message
      console.log("Response status:", response.status);
      console.log("Response data message:", response.data.message);

      if (
        response.status === 201 &&
        response.data.message === "Package edited successfully"
      ) {
        console.log("Package edited successfully in backend");

        // Update the state to reflect the edited package
        setPackages(
          packages.map((pack) =>
            pack.id === editPackage.id ? editPackage : pack
          )
        );
        console.log("Package updated in state");

        handleClose(); // Close the dialog after saving
        console.log("Dialog closed");

        setSnackbarMessage("Package edited successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        console.log("Snackbar set to open with success message");
      } else {
        console.log("Response did not match expected values");
      }
    } catch (error) {
      console.error("Error updating package:", error);
      setSnackbarMessage("Error updating package!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  const columns = [
    { field: "name", headerName: "Name", width: 130 },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      renderCell: (params) => (
        <div>{truncateText(htmlToText(params.row.description), 20)}</div>
      ),
    },
    {
      field: "actual_price",
      headerName: "Actual Price",
      type: "number",
      width: 130,
    },
    {
      field: "discounted_price",
      headerName: "Discounted Price",
      type: "number",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={() =>
              handleStatusChange(params.row.id, params.row.status)
            }
          />
        </div>
      ),
    },
    { 
      field: "created_date", 
      headerName: "Created Date", 
      width: 150, 
      renderCell: (params) => formatDate(params.row.created_date),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
          <Button variant="outlined"  startIcon={<EditIcon />}>
              Edit
            </Button>

          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.id)}
          >
             <Button variant="outlined" color="error" startIcon={<DeleteIcon />}>
              Archive
            </Button>
          </IconButton>
        </div>
      ),
    },
  ];
  // Filtering packages based on search term
  const filteredPackages = packages.filter((pack) =>
    pack.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
            <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 className="card-title">All Packages</h4>
                    <div>
                      {/* Search Box */}
                      <TextField
                        id="search"
                        label="Search by Name"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginRight: "10px" }}
                      />

                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#007bff",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        <Link
                          to="/addpackages"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Add Package
                        </Link>
                      </button>
                    </div>
                  </div>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={filteredPackages} // Render filtered packages
                      columns={columns}
                      pageSize={5}
                      pagination
                      rowsPerPageOptions={[5, 10]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Package</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit this package, please modify the fields below and click save.
          </DialogContentText>
          {editPackage && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                value={editPackage.name}
                onChange={(e) =>
                  setEditPackage({ ...editPackage, name: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                value={editPackage.description}
                onChange={(e) =>
                  setEditPackage({
                    ...editPackage,
                    description: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                label="Actual Price"
                type="number"
                fullWidth
                value={editPackage.actual_price}
                onChange={(e) =>
                  setEditPackage({
                    ...editPackage,
                    actual_price: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                label="Discounted Price"
                type="number"
                fullWidth
                value={editPackage.discounted_price}
                onChange={(e) =>
                  setEditPackage({
                    ...editPackage,
                    discounted_price: e.target.value,
                  })
                }
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

