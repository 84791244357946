import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import UITheam from "../common/UITheam";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto"; // Required for Chart.js v3
import PropertyModal from "./ViewPropertyModal";
import { Link } from "react-router-dom";
import PaymentChart from "./PaymentChart";
import ActiveInactiveChart from "./ActiveInactiveChart";

export default function Dashboard() {
  const [data, setData] = useState({
    totalActiveProperties: 0,
    totalActiveResidentialProperties: 0,
    totalActiveCommercialProperties: 0,
    totalPropertiesCount: 0,
    totalActiveUsers: 0,
    totalClosedProperties: 0,
    totalInProgressProperties: 0,
  });

  const convertToIST = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const [chartData, setChartData] = useState({
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        label: "Active",
        data: Array(12).fill(0),
        backgroundColor: "#2ec4ff",
      },
      {
        label: "Closed",
        data: Array(12).fill(0),
        backgroundColor: "blue",
      },
    ],
  });

  const [latestProperties, setLatestProperties] = useState([]);
  const [filter, setFilter] = useState("All");

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewProperty = (property) => {
    setSelectedProperty(property);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProperty(null);
  };

  
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const totalActivePropertiesResponse = await axios.get(
          `${BaseURL}/gettotalcountofactiveproperties`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const typesOfPropertiesResponse = await axios.get(
          `${BaseURL}/getcountoftypesofactiveproperties`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const activeUsersResponse = await axios.get(
          `${BaseURL}/getcountofactiveusers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setData((prevData) => ({
          ...prevData,
          totalActiveProperties:
            totalActivePropertiesResponse.data.totalActiveProperties,
          totalActiveResidentialProperties:
            typesOfPropertiesResponse.data.counts
              .totalActiveResidentialProperties,
          totalActiveCommercialProperties:
            typesOfPropertiesResponse.data.counts
              .totalActiveCommercialProperties,
          totalPropertiesCount:
            typesOfPropertiesResponse.data.counts.totalPropertiesCount,
          totalActiveUsers: activeUsersResponse.data.data[0].totalActiveUsers,
          totalClosedProperties: 0, // Initialize with zero, will update later
          totalInProgressProperties: 0, // Initialize with zero, will update later
        }));

        let chartEndpoint = "/getdataforchart";
        if (filter === "Residential") {
          chartEndpoint = "/getresidentialdataforchart";
        } else if (filter === "Commercial") {
          chartEndpoint = "/getcommertialdataforchart";
        }

        const chartResponse = await axios.get(`${BaseURL}${chartEndpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const inProgress = Array(12).fill(0);
        const closed = Array(12).fill(0);

        const monthMap = {
          "2024-01": "JAN",
          "2024-02": "FEB",
          "2024-03": "MAR",
          "2024-04": "APR",
          "2024-05": "MAY",
          "2024-06": "JUN",
          "2024-07": "JUL",
          "2024-08": "AUG",
          "2024-09": "SEP",
          "2024-10": "OCT",
          "2024-11": "NOV",
          "2024-12": "DEC",
        };

        let totalClosedProperties = 0;
        let totalInProgressProperties = 0;

        chartResponse.data.data.forEach((item) => {
          const monthIndex = chartData.labels.indexOf(monthMap[item.month]);
          if (monthIndex !== -1) {
            inProgress[monthIndex] = parseInt(item.inProgress);
            closed[monthIndex] = parseInt(item.closed);
            totalInProgressProperties += parseInt(item.inProgress);
            totalClosedProperties += parseInt(item.closed);
          }
        });

        setData((prevData) => ({
          ...prevData,
          totalClosedProperties,
          totalInProgressProperties,
        }));

        setChartData({
          labels: chartData.labels,
          datasets: [
            {
              label: "Active",
              data: inProgress,
              backgroundColor: "#2ec4ff",
            },
            {
              label: "Closed",
              data: closed,
              backgroundColor: "blue",
            },
          ],
        });

        const latestPropertiesResponse = await axios.get(
          `${BaseURL}/findallpendingproperties`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (latestPropertiesResponse.data && Array.isArray(latestPropertiesResponse.data.results)) {
          setLatestProperties(latestPropertiesResponse.data.results);
        } else {
          console.warn("No pending properties found or unexpected response structure");
          setLatestProperties([]); // Set to an empty array or handle as needed
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    useEffect(() => {

    fetchData();
  }, []);

  useEffect(() => {
    const totalPages = Math.ceil(latestProperties.length / itemsPerPage);
    setCurrentPage(1); // Reset currentPage whenever latestProperties changes
  }, [latestProperties, itemsPerPage]);

  const handleApprovalChange = async (propertyId, newApprovalStatus) => {
    try {
      const response = await axios.post(`${BaseURL}/changetoapprovalstatus`, {
        id: propertyId,
        approval: newApprovalStatus,
      });
      if (
        response.status === 201 &&
        response.data.message ===
          "Property approval status updated successfully"
      ) {
        setLatestProperties((prevProperties) =>
          prevProperties.map((property) =>
            property.id === propertyId
              ? { ...property, approval: newApprovalStatus }
              : property
          )
        );
        alert("Property approval status updated successfully!");
        fetchData();
      }
    } catch (error) {
      console.error("Error updating approval status:", error);
      alert("Error updating property approval status!");
    }
  };

  const handleReject = async (propertyId) => {
    const reason = prompt("Please provide a reason for rejection:");

    if (reason) {
      try {
        const response = await axios.post(`${BaseURL}/changetoapprovalstatus`, {
          id: propertyId,
          approval: -1, // Assuming -1 is the code for rejection
          reason: reason,
        });
        if (
          response.status === 201 &&
          response.data.message ===
            "Property approval status updated successfully"
        ) {
          setLatestProperties((prevProperties) =>
            prevProperties.map((property) =>
              property.id === propertyId
                ? { ...property, approval: -1 } // Update the approval status to rejected
                : property
            )
          );
          alert("Property rejected with reason: " + reason);
          fetchData();
        }
      } catch (error) {
        console.error("Error updating approval status:", error);
        alert("Error rejecting property approval status!");
      }
    } else {
      alert("Rejection reason is required!");
    }
  };

  const pieData = {
    labels: ["Residential", "Commercial"],
    datasets: [
      {
        data: [
          data.totalActiveResidentialProperties,
          data.totalActiveCommercialProperties,
        ],
        backgroundColor: ["#4da761", "#e29e09"],
      },
    ],
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const totalPages = Math.ceil(latestProperties.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = latestProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="home-tab"
                    style={{
                      height: "80vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                      <div className="row w-100">
                        {/* <div className="col-md-3">
                          <div className="card text-white bg-primary mb-3">
                            <div className="card-body">
                              <h5 className="card-title">Active Properties</h5>
                              <p className="card-text">
                                {data.totalActiveProperties}
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-4 mb-3">
                          <div className="card text-white bg-danger mb-3">
                            <div className="card-body">
                              <h5 className="card-title">
                                Total Posted Properties
                              </h5>
                              <p className="card-text">
                                {data.totalPropertiesCount}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="card text-white bg-success mb-3">
                            <div className="card-body">
                              <h5 className="card-title">Active Residential</h5>
                              <p className="card-text">
                                {data.totalActiveResidentialProperties}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="card text-white bg-warning mb-3">
                            <div className="card-body">
                              <h5 className="card-title">Active Commercial</h5>
                              <p className="card-text">
                                {data.totalActiveCommercialProperties}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-3">
                          <div className="card text-white bg-info mb-3">
                            <div className="card-body">
                              <h5 className="card-title">Total Active Users</h5>
                              <p className="card-text">
                                {data.totalActiveUsers}
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>


                    <div style={{display:'flex', justifyContent:'center'}}>
                    <Link
                          className="btn btn-primary"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            padding: "20px",
                          }}
                          to="/dashboard/paymentreport"
                        >
                          Payments Report
                        </Link>
                        <Link
                          className="btn btn-secondary"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            padding: "20px",
                          }}
                          to="/dashboard/staffsalesreport"
                        >
                          staff-wise payments Report
                        </Link>

                    </div>

                    <div className="row mt-4" >
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <h5 className="card-title mb-0">
                                Approved Properties:{" "}
                                {data.totalClosedProperties +
                                  data.totalInProgressProperties}
                              </h5>
                              <div>
                                <label
                                  htmlFor="filter"
                                  className="form-label me-2 mb-0"
                                >
                                  Filter:
                                </label>
                                <select
                                  id="filter"
                                  className="form-select"
                                  value={filter}
                                  onChange={handleFilterChange}
                                >
                                  <option value="All">All</option>
                                  <option value="Residential">
                                    Residential
                                  </option>
                                  <option value="Commercial">Commercial</option>
                                </select>
                              </div>
                            </div>
                            <div style={{ width: "100%", margin: "0 auto" }}>
                              <Bar
                                data={chartData}
                                options={{
                                  responsive: true,
                                  scales: {
                                    x: {
                                      stacked: true,
                                      grid: {
                                        drawBorder: false,
                                        drawOnChartArea: false, // Remove horizontal grid lines
                                        drawTicks: true,
                                        color: (context) => {
                                          if (
                                            context.tick &&
                                            context.tick.value === "JAN"
                                          ) {
                                            return "#000"; // Draw the vertical line at the first tick (JAN)
                                          }
                                          return "transparent"; // Make other lines transparent
                                        },
                                      },
                                    },
                                    y: {
                                      stacked: true,
                                      grid: {
                                        drawBorder: false,
                                        drawOnChartArea: false, // Remove horizontal grid lines
                                        drawTicks: true,
                                        color: "transparent", // Remove horizontal lines
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <ActiveInactiveChart />
                      </div>
                     
                      <div className="col-md-4" style={{marginTop:'60px'}}>
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">
                              Active Property Types
                            </h5>
                            <Pie data={pieData} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8" style={{marginTop:'60px'}}>
                      <PaymentChart/>
                      </div>
                   
                    </div>


                    

                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">
                              All Pending Properties
                            </h5>
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Created Date</th>
                                    <th scope="col">Approval</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentItems.map((property) => (
                                    <tr key={property.id}>
                                      <td>
                                        <img
                                          src={property.bannerimage_name}
                                          alt={property.title}
                                          style={{
                                            maxWidth: "100px",
                                            maxHeight: "90px",
                                          }}
                                        />
                                      </td>
                                      <td>{property.title}</td>
                                      <td>
                                        {property.description.slice(0, 50)}
                                        {property.description.length > 50
                                          ? "..."
                                          : ""}
                                      </td>
                                      <td>
                                        {convertToIST(property.timestamp)}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-success"
                                          onClick={() =>
                                            handleApprovalChange(property.id, 1)
                                          }
                                        >
                                          Approve
                                        </button>
                                        <button
                                          className="btn btn-warning"
                                          onClick={() =>
                                            handleReject(property.id)
                                          }
                                        >
                                          Reject
                                        </button>
                                      </td>

                                      <td>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            handleViewProperty(property)
                                          }
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            <nav className="mt-3">
                              <ul className="pagination justify-content-end">
                                <li
                                  className={`page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={prevPage}
                                    tabIndex="-1"
                                  >
                                    Prev
                                  </button>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                  <li
                                    key={index}
                                    className={`page-item ${
                                      currentPage === index + 1 ? "active" : ""
                                    }`}
                                  >
                                    <button
                                      className="page-link"
                                      onClick={() => goToPage(index + 1)}
                                    >
                                      {index + 1}
                                    </button>
                                  </li>
                                ))}
                                <li
                                  className={`page-item ${
                                    currentPage === totalPages ? "disabled" : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={nextPage}
                                  >
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                    <PropertyModal
                      isOpen={isModalOpen}
                      setIsOpen={setIsModalOpen}
                      property={selectedProperty}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
