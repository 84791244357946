import React from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import UITheam from "../common/UITheam";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string"; // Import queryString library

function UserProfile() {
  const location = useLocation();
  const user = location.state?.user;
  const navigate = useNavigate();

  const handleLoadProperties = (user) => {
    navigate(`/loadproperties`);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 style={{ marginTop: "0" }}>
                        User Profile 
                      </h4>
                      {/* <button
                        className="btn btn-secondary"
                        onClick={handleLoadProperties}
                      >
                        Load Properties
                      </button>
                      <button className="btn btn-danger">
                        Load My Favorites
                      </button>
                      <button className="btn btn-primary">Add Property</button> */}
                    </div>

                    {user ? (
                      <div className="container mt-5">
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Field</th>
                                <th scope="col">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Name</strong>
                                </td>
                                <td>{user.name}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Email</strong>
                                </td>
                                <td>{user.email}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Mobile</strong>
                                </td>
                                <td>{user.mobile}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Status</strong>
                                </td>
                                <td>
                                  {user.status === 1 ? "Active" : "Inactive"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <p>No user data available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
