import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

const ViewUserModal = ({ isOpen, setIsOpen, user }) => {
  if (!user) return null;

  const getStatusLabel = (status) => {
    return status === 1 ? "Active" : "Inactive";
  };

  const renderUserTypeCheckbox = (userType) => {
    const userTypesMap = {
      1: "Seller",
      2: "Buyer",
      3: "Owner to Rent",
      4: "Owner to Lease",
      5: "Looking for Rental",
      6: "Looking for Lease",
      7: "Others",
    };

    return Object.keys(userTypesMap).map((key) => (
      <Box key={key} sx={{ display: "inline-flex", alignItems: "center", mr: 2 }}>
        <Checkbox
          checked={userType.includes(parseInt(key))}
          disabled
          color="primary"
        />
        <Typography>{userTypesMap[key]}</Typography>
      </Box>
    ));
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="view-user-modal-title"
      aria-describedby="view-user-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="view-user-modal-title" variant="h6" component="h2">
          User Details
        </Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
          <Table aria-label="user details table">
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{user.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mobile</TableCell>
                <TableCell>{user.mobile}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Occupation</TableCell>
                <TableCell>{user.occupation}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Budget</TableCell>
                <TableCell>{user.budget}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gender</TableCell>
                <TableCell>{user.gender}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>{getStatusLabel(user.status)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><span>User Type</span></TableCell>
                <TableCell>{renderUserTypeCheckbox(user.usertype)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

export default ViewUserModal;
