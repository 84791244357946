import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useParams, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero indexed
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export default function PaymentDetails() {
  const { paymentId } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [razorRefundOpen, setRazorRefundOpen] = useState(false);
  const [manualRefundOpen, setManualRefundOpen] = useState(false);
  const [addRefOpen, setAddRefOpen] = useState(false);
  const [newRefId, setNewRefId] = useState("");
  const [refundRemarks, setRefundRemarks] = useState("");
  const [manualRefundMethod, setManualRefundMethod] = useState("");
  const [refId, setRefId] = useState("");
  const [refundAmounts, setRefundAmounts] = useState({});
  const [totalRefund, setTotalRefund] = useState(0);
  const [selectedService, setSelectedService] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const staffId = localStorage.getItem("staffId");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${BaseURL}/getallpaymentanduserdetails/${paymentId}`)
      .then((response) => {
        setPaymentDetails(response.data.results);

        const initialRefundAmounts = {};
        response.data.results.services.forEach((service) => {
          initialRefundAmounts[service.serviceId] = 0; // Default refund amount to 0
        });
        setRefundAmounts(initialRefundAmounts);
        calculateTotalRefund(initialRefundAmounts, response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching payment details:", error);
        setSnackbarMessage("Error fetching payment details!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  }, [paymentId]);

  const handleRazorRefund = () => {
    if (refundRemarks.trim() === "") {
      setSnackbarMessage("Refund remarks are required.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    setIsSubmitting(true);
    axios
      .post(`${BaseURL}/razorpayrefund`, {
        payment_id: paymentId,
        refund_amount: paymentDetails.payment.amount,
        refund_staffId: staffId,
        refund_remarks: refundRemarks,
      })
      .then((response) => {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setRazorRefundOpen(false);
        axios
          .get(`${BaseURL}/getallpaymentanduserdetails/${paymentId}`)
          .then((response) => {
            setPaymentDetails(response.data.results);

            const initialRefundAmounts = {};
            response.data.results.services.forEach((service) => {
              initialRefundAmounts[service.serviceId] = 0; // Default refund amount to 0
            });
            setRefundAmounts(initialRefundAmounts);
            calculateTotalRefund(initialRefundAmounts, response.data.results);
          })
          .catch((error) => {
            console.error("Error fetching payment details:", error);
            setSnackbarMessage("Error fetching payment details!");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      })
      .catch((error) => {
        console.error("Error processing Razor refund:", error);
        setSnackbarMessage("Error processing Razor refund!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleManualRefund = () => {
    if (refundRemarks.trim() === "" || manualRefundMethod.trim() === "") {
      // || refId.trim() === ""
      setSnackbarMessage("All fields are required.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (totalRefund <= 0) {
      setSnackbarMessage("Total refund amount must be greater than zero.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    let totalRemainsDiscount = 0;
    const servicesToRefund = paymentDetails.nonRefundedServices
      .filter((service) => parseFloat(refundAmounts[service.serviceId]) > 0)
      .map((service) => {
        const serviceDiscount = calculateServiceDiscount(
          service,
          totalServicePrice
        );
        const customerPaid = service.discounted_price - serviceDiscount;
        const remainsDiscount =
          paymentDetails.payment.discount_remains -
          (service.discounted_price - customerPaid);
        totalRemainsDiscount += remainsDiscount;

        return {
          serviceName: service.serviceName,
          refundAmount: refundAmounts[service.serviceId],
          serviceId: service.serviceId,
          
        };
      });
      setIsSubmitting(true);
    axios
      .post(`${BaseURL}/manualrefund`, {
        payment_id: paymentId,
        refund_amount: totalRefund,
        manual_refund_method: manualRefundMethod,
        refund_staffId: staffId,
        refund_remarks: refundRemarks,
        services: servicesToRefund,
        RefId: refId,
        remainsDiscount: totalRemainsDiscount.toFixed(2),
      })
      .then((response) => {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setManualRefundOpen(false);
        setRefundRemarks("");
        setManualRefundMethod("");
        setRefId("");
        setRefundAmounts({});
        setTotalRefund(0);
        
        axios
          .get(`${BaseURL}/getallpaymentanduserdetails/${paymentId}`)
          .then((response) => {
            setPaymentDetails(response.data.results);

            const initialRefundAmounts = {};
            response.data.results.services.forEach((service) => {
              initialRefundAmounts[service.serviceId] = 0; // Default refund amount to 0
            });
            setRefundAmounts(initialRefundAmounts);
            calculateTotalRefund(initialRefundAmounts, response.data.results);
          })
          .catch((error) => {
            console.error("Error fetching payment details:", error);
            setSnackbarMessage("Error fetching payment details!");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      })
      .catch((error) => {
        console.error("Error processing manual refund:", error);
        setSnackbarMessage("Error processing manual refund!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
      
  

  };

  const handleAddRef = () => {
    if (newRefId.trim() === "") {
      setSnackbarMessage("Reference ID cannot be empty.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    axios
      .post(`${BaseURL}/addreferenceid`, {
        payment_id: paymentId,
        RefId: newRefId,
        service_name: selectedService,
      })
      .then((response) => {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setAddRefOpen(false);
        setNewRefId("");
        axios
          .get(`${BaseURL}/getallpaymentanduserdetails/${paymentId}`)
          .then((response) => {
            setPaymentDetails(response.data.results);

            const initialRefundAmounts = {};
            response.data.results.services.forEach((service) => {
              initialRefundAmounts[service.serviceId] = 0; // Default refund amount to 0
            });
            setRefundAmounts(initialRefundAmounts);
            calculateTotalRefund(initialRefundAmounts, response.data.results);
          })
          .catch((error) => {
            console.error("Error fetching payment details:", error);
            setSnackbarMessage("Error fetching payment details!");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      })
      .catch((error) => {
        console.error("Error adding reference ID:", error);
        setSnackbarMessage("Error adding reference ID!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleRefundAmountChange = (serviceId, amount) => {
    const service = paymentDetails.nonRefundedServices.find(
      (s) => s.serviceId === serviceId
    );

    // Remove leading zeros and ensure amount is a number
    let cleanedAmount = amount.replace(/^0+(?!$)/, "");
    let parsedAmount = parseFloat(cleanedAmount) || 0;

    if (parsedAmount < 0) {
      setSnackbarMessage("Refund amount cannot be negative");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (parsedAmount > service.discounted_price) {
      setSnackbarMessage(
        `Refund amount cannot exceed the service price of ${service.discounted_price}`
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const newRefundAmounts = { ...refundAmounts, [serviceId]: parsedAmount };
    setRefundAmounts(newRefundAmounts);
    calculateTotalRefund(newRefundAmounts, paymentDetails);
  };

  const calculateTotalRefund = (refundAmounts, paymentDetails) => {
    let total = 0;
    Object.values(refundAmounts).forEach((amount) => {
      total += parseFloat(amount);
    });
    // total -= parseFloat(paymentDetails.payment.appliedRewards || 0);
    setTotalRefund(total);
  };

  const calculateServiceDiscount = (service, totalServicePrice) => {
    const servicePrice = parseFloat(service.discounted_price);
    const totalDiscount = parseFloat(
      paymentDetails.payment.discount_remains || 0
    );
    const discountRatio = servicePrice / totalServicePrice;
    const serviceDiscount = totalDiscount * discountRatio;
    return serviceDiscount;
  };

  const calculateTotalServicePrice = (services) => {
    return services.reduce((total, service) => {
      return total + parseFloat(service.discounted_price);
    }, 0);
  };

  if (!paymentDetails) {
    return <div>Loading...</div>;
  }

  const totalServicePrice = calculateTotalServicePrice(
    paymentDetails.nonRefundedServices
  );
  const dues =
    paymentDetails.payment.amount -
    paymentDetails.payment.refund_amount -
    totalRefund;

  const refundMethod = paymentDetails.payment.refund_method;

  const manualRefundDetails =
    paymentDetails.payment.manual_refund_service_details;

  const refundDetails = [
    ["Staff ID:", paymentDetails.payment.refund_staffId],
    ["Staff Name:", paymentDetails.payment.refund_staff_names],
    ["Refund Amount:", `₹ ${paymentDetails.payment.refund_amount}`],
    ["Refund Method:", paymentDetails.payment.refund_method],
    ["Refund Remarks:", paymentDetails.payment.refund_remarks],
    ["Razor Pay Refund Id:", paymentDetails.payment.razorpay_refund_id],
    ["Payment Refund Method:", paymentDetails.payment.manual_refund_methods],
    ["Refund Status:", paymentDetails.payment.refund_status],
    // ["Manual Reference Id:", paymentDetails.payment.manual_refund_ref_ids],
    ["Payment Date:", formatTimestamp(paymentDetails.payment.timestamp)],
    ["Refunded Service Name:", paymentDetails.payment.refunded_service_names],
  ];

  // Filter out fields with null or empty values
  const filteredRefundDetails = refundDetails.filter(
    ([label, value]) => value !== null && value !== "" && value !== undefined
  );

  const serviceNames =
    paymentDetails?.payment?.refunded_service_names?.split(",") || [];

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div
            className="main-panel"
            style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
          >
            <div
              className="content-wrapper"
              style={{ flex: 1, padding: "1rem" }}
            >
              <div
                className="card"
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  overflow: "hidden",
                  height: "100%",
                }}
              >
                <div className="card-body" style={{ padding: "1.5rem" }}>
                  {/* Payment Details Section */}
                  <h4 className="card-title" style={{ marginBottom: "1rem" }}>
                    Payment Details
                  </h4>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                      {[
                        ["User ID:", paymentDetails.payment.user_id],
                        ["Name:", paymentDetails.user.name],
                        ["Phone No.:", paymentDetails.user.mobile],

                        ["Email:", paymentDetails.user.email],
                        ["Order ID:", paymentDetails.payment.order_id],
                        ["Payment ID:", paymentDetails.payment.payment_id],
                        ["Amount:", `₹ ${paymentDetails.payment.amount}`],
                        [
                          "Applyed Reward:",
                          paymentDetails.payment.appliedRewards,
                        ],
                        ["Total Discount:", `₹ ${paymentDetails.totalDiscount}`],
                        [
                          "Invoice:",
                          <a
                        
                            href={paymentDetails.payment.invoice_name}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007bff", textDecoration: "none" }}
                          >
                            <i class="fa fa-download"></i>
                            &nbsp; {paymentDetails.payment.invoice_number}
                          </a>,
                        ],
                        ["Method:", paymentDetails.payment.payment_method],
                        ["Status:", paymentDetails.payment.status],
                        [
                          "Payment Date:",
                          formatTimestamp(paymentDetails.payment.created_at),
                        ],
                        ["Service Name:", paymentDetails.payment.service_names],
                        [
                          "Non-Refunded Service Name:",
                          paymentDetails.payment.non_refunded_service_names,
                        ],
                      ].map(([label, value], index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid #ddd",
                              fontWeight: "bold",
                            }}
                          >
                            {label}
                          </td>
                          <td
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            {value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div style={{ marginTop: "1rem" }}>
                    {paymentDetails.payment.non_refunded_service_names ===
                    "" ? null : refundMethod === // Do not show any buttons if non_refunded_service_names is empty
                      "Fully" ? null : refundMethod === "Partial" ? ( // Hide both buttons if refundMethod is "fully"
                      // Show only "Partially Manual Refund" button if refundMethod is "partial"
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setManualRefundOpen(true)}
                      >
                        Partially Refund
                      </Button>
                    ) : (
                      // Show both buttons if refundMethod is neither "fully" nor "partial"
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setRazorRefundOpen(true)}
                          style={{ marginRight: "1rem" }}
                        >
                          Full Refund
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setManualRefundOpen(true)}
                        >
                          Partial Refund
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {paymentDetails.payment.refund_method &&
              paymentDetails.payment.refund_method.trim() !== "" && (
                <div
                  className="content-wrapper"
                  style={{ flex: 1, padding: "1rem" }}
                >
                  <div
                    className="card"
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      overflow: "hidden",
                      height: "100%",
                    }}
                  >
                    <div className="card-body" style={{ padding: "1.5rem" }}>
                      {/* Refund Details Section */}
                      <h4
                        className="card-title"
                        style={{ marginBottom: "1rem" }}
                      >
                        Refund Details
                      </h4>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <tbody>
                          {filteredRefundDetails.map(
                            ([label, value], index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {label}
                                </td>
                                <td
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                  }}
                                >
                                  {value}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>


                      {paymentDetails.payment.refund_method !== 'Fully' && (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Service Name</TableCell>
          <TableCell>Refund Amount</TableCell>
          {/* <TableCell>Service ID</TableCell> */}
          <TableCell>Manual Refund Ref ID</TableCell>
          {/* <TableCell>Manual Refund Method</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {manualRefundDetails.map((detail, index) => (
          <TableRow key={index}>
            <TableCell>{detail.serviceName}</TableCell>
            <TableCell>
             ₹ {detail.refundAmount.toFixed(2)}
            </TableCell>
            {/* <TableCell>{detail.serviceId}</TableCell> */}
            <TableCell>
              {detail.manual_refund_ref_id}
            </TableCell>
            {/* <TableCell>{detail.manual_refund_method}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}




                      <div style={{ marginTop: "1rem" }}>
                        {paymentDetails.payment.refund_method === "Partial" && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setAddRefOpen(true)}
                          >
                            Update Reference Id
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <Footer />

      {/* Razor Refund Dialog */}
      <Dialog open={razorRefundOpen} onClose={() => setRazorRefundOpen(false)}>
        <DialogTitle>Razor Refund</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Refund Remarks"
            fullWidth
            value={refundRemarks}
            onChange={(e) => setRefundRemarks(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRazorRefundOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRazorRefund} color="primary" disabled={isSubmitting} >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Manual Refund Dialog */}
      <Dialog
        open={manualRefundOpen}
        onClose={() => setManualRefundOpen(false)}
      >
        <DialogTitle>Manual Refund</DialogTitle>
        <DialogContent>
          {paymentDetails.nonRefundedServices.map((service) => {
            const serviceDiscount = calculateServiceDiscount(
              service,
              totalServicePrice
            );
            const customerPaid = service.discounted_price - serviceDiscount;

            return (
              <div
                key={service.serviceId}
                style={{ marginBottom: "1rem", display: "flex" }}
              >
                <TextField
                  label="Service Name"
                  value={service.serviceName}
                  fullWidth
                  margin="dense"
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  label="Service Price"
                  value={service.discounted_price}
                  fullWidth
                  margin="dense"
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  label="Customer Paid"
                  value={customerPaid.toFixed(2)}
                  fullWidth
                  margin="dense"
                  InputProps={{ readOnly: true }}
                  style={{ marginRight: "1rem" }}
                />
                <TextField
                  label="Refund Amount"
                  value={refundAmounts[service.serviceId] || ""}
                  onChange={(e) =>
                    handleRefundAmountChange(service.serviceId, e.target.value)
                  }
                  fullWidth
                  margin="dense"
                  type="number"
                  inputProps={{ max: service.discounted_price, step: "0.01" }}
                />
              </div>
            );
          })}
          <p>
            <strong>Total Reward Applied:</strong>{" "}
            {paymentDetails.payment.appliedRewards}
          </p>
          {paymentDetails.coupon && (
            <p>
              {" "}
              <strong>Applyed Coupon Amount:</strong>{" "}
              {paymentDetails.coupon.couponAmountApplied}
              <strong> Coupon Code:</strong> {paymentDetails.coupon.code}{" "}
            </p>
          )}
          <hr />
          <p>
            <strong>Total Paid:</strong>{" "}
            {paymentDetails.payment.amount -
              paymentDetails.payment.refund_amount}
          </p>
          <p>
            <strong>Total Discount:</strong>{" "}
            {paymentDetails.payment.discount_remains}
          </p>
          <p>
            <strong>Total Refund:</strong> {totalRefund.toFixed(2)}
          </p>
          <p>
            <strong>{dues >= 0 ? "Remains:" : "Dues:"} </strong>
            {dues.toFixed(2)}
          </p>
          <TextField
            autoFocus
            margin="dense"
            label="Reference Id"
            fullWidth
            value={refId}
            onChange={(e) => setRefId(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Payment Refund Method"
            fullWidth
            value={manualRefundMethod}
            onChange={(e) => setManualRefundMethod(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Refund Remarks"
            fullWidth
            value={refundRemarks}
            onChange={(e) => setRefundRemarks(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setManualRefundOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleManualRefund} color="primary" disabled={isSubmitting} >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Reference ID Dialog */}
      <Dialog open={addRefOpen} onClose={() => setAddRefOpen(false)}>
        <DialogTitle>Add Reference ID</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Reference ID"
            fullWidth
            value={newRefId}
            onChange={(e) => setNewRefId(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Service Name</InputLabel>
            <Select
              value={selectedService}
              onChange={handleServiceChange}
              label="Service Name"
            >
              {serviceNames.map((service, index) => (
                <MenuItem key={index} value={service}>
                  {service}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddRefOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleAddRef(newRefId, selectedService); // Pass both newRefId and selectedService
              setAddRefOpen(false);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
