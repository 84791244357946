import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const permissionMap = {
  0: "/properties",
  1: "/users",
  2: "/services",
  3: "/packages",
  4: "/tickets",
  5: "/rewards",
  6: "/payments",
  7: "/activities",
  8: "/userquery",
  9: "/coupons",
  10: "/testimonial",
  11: "/agents",
  12: "/about",
  13: "/teams",
};

export const PrivateRoutes = ({ Component }) => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");
  const userType = localStorage.getItem("type");
  const userPermissions = localStorage.getItem("permissions");

  useEffect(() => {
    if (!userToken) {
      console.log("User token is null, redirecting to home.");
      navigate("/");
      return;
    }

    const currentPath = window.location.pathname;
    const permissions = userPermissions
      ? userPermissions
          .split(",")
          .map(permission => {
            const cleanedPermission = permission.trim().replace(/"/g, '');
            const numPermission = Number(cleanedPermission);
            console.log(`Parsed permission: "${permission}" => ${numPermission}`);
            return isNaN(numPermission) ? null : numPermission;
          })
          .filter(permission => permission !== null)
      : [];

    console.log("Current Path:", currentPath);
    console.log("User Type:", userType);
    console.log("User Permissions:", permissions);

    if (userType === '0') {
      if (currentPath === "/dashboard" || currentPath === "/staffs") {
        console.log("Staff member trying to access restricted global route, redirecting to /Logout.");
        navigate("/Logout");
        return;
      } else {
        // Restrict access to defined routes based on permissions for staff
        const restrictedRoute = Object.keys(permissionMap).find(key => {
          const route = permissionMap[key];
          const hasPermission = permissions.includes(Number(key));
          console.log(`Checking route: ${route}, hasPermission: ${hasPermission}`);
          return route === currentPath && !hasPermission;
        });
        if (restrictedRoute !== undefined) {
          console.log("Staff member does not have access to this route, redirecting to /");
          navigate("/Logout");
          return;
        }
      }
    } else if (userType === '1') {
      console.log("Admin user, has access to all routes.");
      // Admin users have access to all routes
    } else {
      console.log("Unknown user type, redirecting to home.");
      navigate("/");
    }
  }, [userToken, userType, userPermissions, navigate]);

  if (!userToken) {
    return null; // Redirect logic already handled in useEffect
  }

  return (
    <div>
      <Component />
      <Outlet />
    </div>
  );
};
