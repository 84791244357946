import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewUserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${BaseURL}/viewuserdetails/${id}`);
        setUser(response.data.results);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [id]);

  const getStatusLabel = (status) => {
    return status === 1 ? "Active" : "Inactive";
  };

  const renderUserTypeCheckbox = (userType) => {
    const userTypesMap = {
      // 1: "Seller",
      // 2: "Buyer",
      // 3: "Owner to Rent",
      // 4: "Owner to Lease",
      // 5: "Looking for Rental",
      // 6: "Looking for Lease",
      // 7: "Others",

      1: "Agent",
      2: "Owner",
      3: "Builders or Developers",
    };

    return Object.keys(userTypesMap).map((key) => (
      <Box key={key} sx={{ display: "inline-flex", alignItems: "center", mr: 2 }}>
        <Checkbox
          checked={userType.includes(parseInt(key))}
          disabled
          color="primary"
        />
        <Typography>{userTypesMap[key]}</Typography>
      </Box>
    ));
  };

  return (
    <Box sx={{ p: 4 }}>
      {user ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="h4">User Details</Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "10px" }}
                onClick={() => window.location.href = `/userproperties/${id}`}
              >
                View Properties
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
                onClick={() => window.location.href = `/userservices/${id}`}
              >
                View Services
              </Button>
              <Button
                variant="contained"
                onClick={() => window.location.href = `/userpayments/${id}`}
              >
                View Payments
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ mt: 4 }}>
  <Table aria-label="user details table">
    <TableBody>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Name</span>
          <span>{user.name}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Email</span>
          <span>{user.email}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Mobile</span>
          <span>{user.mobile}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Occupation</span>
          <span>{user.occupation}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Budget</span>
          <span>{user.budget}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Gender</span>
          <span>{user.gender}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <span>Status</span>
          <span>{getStatusLabel(user.status)}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          <span style={{fontSize:'20px', display:'flex'}}>User Type</span>
          <span>{renderUserTypeCheckbox(user.usertype)}</span>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

        </>
      ) : (
        <Typography>Loading user details...</Typography>
      )}
    </Box>
  );
};

export default function Services() {
  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <ViewUserDetails />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
