import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  TextField,
  Snackbar,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

export default function AddSubCat() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryImage, setSubCategoryImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getallcategories`);
        setCategories(response.data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSubCategoryNameChange = (event) => {
    setSubCategoryName(event.target.value);
  };

  const handleSubCategoryImageChange = (event) => {
    setSubCategoryImage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!selectedCategory) {
      setSnackbarMessage("Please select a category.");
      setSnackbarOpen(true);
      return;
    }

    if (!subCategoryName) {
      setSnackbarMessage("Please enter a subcategory name.");
      setSnackbarOpen(true);
      return;
    }

    if (!subCategoryImage) {
      setSnackbarMessage("Please select an image for the subcategory.");
      setSnackbarOpen(true);
      return;
    }
    setIsSubmitting(true);

    try {
      const ipAddressResponse = await axios.get("https://api64.ipify.org?format=json");
      const ipAddress = ipAddressResponse.data.ip;

      const formData = new FormData();
      formData.append("categoryId", selectedCategory);
      formData.append("name", subCategoryName);
      formData.append("subcategory_image", subCategoryImage);
      formData.append("ip", ipAddress);

      const response = await axios.post(`${BaseURL}/addsubcategory`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success === true) {
        setSnackbarMessage("Subcategory added successfully.");
        setSnackbarOpen(true);
        // Clear form fields after successful submission
        setSelectedCategory("");
        setSubCategoryName("");
        setSubCategoryImage(null);
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding subcategory:", error);
      setSnackbarMessage("Failed to add subcategory. Please try again.");
      setSnackbarOpen(true);
    }finally {
      setIsSubmitting(false);
    };
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="card-body" style={{ display: 'flex' }}>
                    <Typography variant="h6" gutterBottom>
                      Add Subcategory
                    </Typography>
                    <button
                      onClick={() => navigate(-1)}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        textDecoration: "none",
                        marginLeft: "auto",
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <Container>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <InputLabel id="category-label">Category</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        label="Category"
                        required
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.categoryId} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      fullWidth
                      id="subCategoryName"
                      label="Subcategory Name"
                      variant="outlined"
                      value={subCategoryName}
                      onChange={handleSubCategoryNameChange}
                      sx={{ marginBottom: 2 }}
                      required
                    />

                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleSubCategoryImageChange}
                      style={{ marginBottom: 2 }}
                      required
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : " Add Subcategory"}
                    
                    </Button>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
