import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BorderAllRounded } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "auto",
  borderRadius: "12px", // Rounded corners
  backgroundColor: "#fff", // White background color
  color: "#333", // Text color
  fontFamily: "'Roboto', sans-serif", // Font style
  fontSize: "16px", // Font size
  transition: "all 0.3s ease", // Smooth transition
  animation: "fadeIn 0.5s", // Fade-in animation
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
};

// CSS for the fade-in animation
const globalStyles = {
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

// Function to get string representation for enums
function getEnumString(value, enumObj) {
  return enumObj[value];
}

function ViewPropertyModal({ isOpen, setIsOpen, property }) {
  if (!property) {
    return null;
  }

  // Define enums for various fields
  const propertyType = {
    0: "Residential",
    1: "Commercial",
    2: "Plot",
  };

  const createdBy = {
    0: "User",
    1: "Staff (Admin)",
  };

  const propertyStatus = {
    0: "Ready",
    1: "Under Construction",
  };

  const parkingSpace = {
    0: "2 Wheeler",
    1: "4 Wheeler",
    2: "Both",
    3: "No Parking Space",
  };

  const bhkType = {
    0: "1 BHK",
    1: "2 BHK",
    2: "3 BHK",
    3: "4 BHK",
    4: "5 BHK",
    5: "+5 BHK",
  };

  const residentialType = {
    0: "Apartment",
    1: "Independent House/Villa",
    2: "Gated Community Villa",
  };

  const commercialType = {
    0: "Office Space",
    1: "Shop/Showroom",
    2: "Warehouse/Godown",
    3: "Industrial Building",
  };

  const furnishingType = {
    0: "Fully Furnished",
    1: "Semi Furnished",
    2: "Non Furnished",
  };

  const propertyFor = {
    0: "Rent",
    1: "Sell",
    2: "Lease",
  };

  const preferredTenants = {
    0: "Anyone",
    1: "Bachelor Male",
    2: "Bachelor Female",
    3: "Family",
  };

  const waterSupply = {
    0: "Cooperation",
    1: "Borewell",
    2: "Both",
  };

  const availableVisitingDays = {
    0: "Everyday (Mon-Sun)",
    1: "Weekdays (Mon-Fri)",
    2: "Weekends (Sat,Sun)",
  };

  const approvalStatus = {
    0: "Pending",
    1: "Approved by Admin",
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Property Details
        </Typography>

        {/* Title */}
        {property.title && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Title:</strong> {property.title}
          </Typography>
        )}

        {/* Address */}
        {property.address &&
          property.city &&
          property.state &&
          property.country &&
          property.zipcode && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <strong>Address:</strong> {property.address}, {property.city},{" "}
              {property.state}, {property.country} - {property.zipcode}
            </Typography>
          )}

        {/* Price */}
        {property.price && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Price:</strong> {property.price}
          </Typography>
        )}

        {/* Property Type */}
        {property.property_type !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Property Type:</strong>{" "}
            {getEnumString(property.property_type, propertyType)}
          </Typography>
        )}

        {/* Created By */}
        {property.created_by !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Created By:</strong>{" "}
            {getEnumString(property.created_by, createdBy)}
          </Typography>
        )}

        {/* Property Status */}
        {property.property_status !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Property Status:</strong>{" "}
            {getEnumString(property.property_status, propertyStatus)}
          </Typography>
        )}

        {/* Parking Space */}
        {property.parking_space !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Parking Space:</strong>{" "}
            {getEnumString(property.parking_space, parkingSpace)}
          </Typography>
        )}

        {/* BHK Type */}
        {property.bhk_type !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>BHK Type:</strong>{" "}
            {getEnumString(property.bhk_type, bhkType)}
          </Typography>
        )}

        {/* Residential Type */}
        {property.residential_type !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Residential Type:</strong>{" "}
            {getEnumString(property.residential_type, residentialType)}
          </Typography>
        )}

        {/* Commercial Type */}
        {property.commertial_type !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Commercial Type:</strong>{" "}
            {getEnumString(property.commertial_type, commercialType)}
          </Typography>
        )}

        {/* Furnishing Type */}
        {property.furnishing_type !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Furnishing Type:</strong>{" "}
            {getEnumString(property.furnishing_type, furnishingType)}
          </Typography>
        )}

        {/* Property For */}
        {property.property_for !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Property For:</strong>{" "}
            {getEnumString(property.property_for, propertyFor)}
          </Typography>
        )}

        {/* Preferred Tenants */}
        {property.prefered_tenants !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Preferred Tenants:</strong>{" "}
            {getEnumString(property.prefered_tenants, preferredTenants)}
          </Typography>
        )}

        {/* Description */}
        {property.description && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Description:</strong> {property.description}
          </Typography>
        )}

        {/* Amenities */}
        {property.amenities && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Amenities:</strong> {property.amenities}
          </Typography>
        )}

        {/* Approval */}
        {property.approval !== undefined && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Approval:</strong>{" "}
            {getEnumString(property.approval, approvalStatus)}
          </Typography>
        )}

        {/* Created Date */}
        {property.timestamp && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Created Date:</strong>{" "}
            {new Date(property.timestamp).toLocaleString()}
          </Typography>
        )}

        {/* Available From */}
        {property.available_from && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Available From:</strong>{" "}
            {new Date(property.available_from).toLocaleDateString()}
          </Typography>
        )}

        {/* Banner Image */}
        {property.bannerimage_name && (
          <div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <strong>Banner Image:</strong>
            </Typography>
            <img
              src={property.bannerimage_name}
              alt={`Banner Image`}
              style={{ maxWidth: "100px", maxHeight: "100px", margin: "5px" }}
            />
          </div>
        )}

        {/* Property Images */}
        {property.image_name && property.image_name.length > 0 && (
          <div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <strong>Images:</strong>
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {property.image_name.map((image_url, index) => (
                <img
                  key={index}
                  src={image_url}
                  alt={`Property Image ${index + 1}`}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    margin: "5px",
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default ViewPropertyModal;
