import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";

const ITEMS_PER_PAGE = 5; // Number of properties per page

export default function ViewAllActivity() {
  const { email } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activityDetails, setActivityDetails] = useState({});
  const [properties, setProperties] = useState([]);
  const [userFound, setUserFound] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchActivityDetails = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const userId = queryParams.get("userId");

        const response = await axios.get(
          `${BaseURL}/getvisitedproperty/${email}?userId=${userId}`
        );
        if (response.data) {
          if (response.data.results) {
            const activities = response.data.results;
            if (activities.length > 0) {
              const details = {
                name: activities[0].name,
                email: activities[0].email_posted,
                mobile: activities[0].mobile,
                alternate_mobile: activities[0].alternate_mobile,
                occupation: activities[0].occupation,
                insurance: activities[0].insurance,
                loan: activities[0].loan,
                looking_for: activities[0].looking_for,
              };
              setActivityDetails(details);
              setProperties(activities);
            }
          }
          if (response.data.userFound) {
            setUserFound(response.data.userFound);
          }
        }
      } catch (error) {
        console.error("Error fetching activity details:", error);
      }
    };

    fetchActivityDetails();
  }, [email, location.search]);

  const formatDateToIST = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const handleView = (user) => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("userId");

    if (userId === "0") {
      // Handle navigation or action when userId is 0
      console.log("User ID is 0, handle custom navigation here");
    } else {
      // Normal navigation to user profile
      navigate(`/viewuserdetails/${userId}`, { state: { user } });
      console.log(user, "3633747477");
    }
  };

  // Pagination logic
  const indexOfLastProperty = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstProperty = indexOfLastProperty - ITEMS_PER_PAGE;
  const currentProperties = properties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card" style={{height:"80vh", overflowY:"scroll"}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="card-title">Activity Details</h4>
                      <div>
                      {activityDetails.name && (
                        <p>
                          <strong>Name:</strong> {activityDetails.name}
                        </p>
                      )}
                    
                        <p>
                          <strong>Email:</strong> {email}
                        </p>
                     
                      {activityDetails.mobile && (
                        <p>
                          <strong>Mobile:</strong> {activityDetails.mobile}
                        </p>
                      )}
                        {activityDetails.alternate_mobile && (
                          <p>
                            <strong>Alternate Mobile:</strong>{" "}
                            {activityDetails.alternate_mobile}
                          </p>
                        )}
                        {activityDetails.occupation && (
                        <p>
                          <strong>Occupation: </strong>{" "}
                          {activityDetails.occupation}
                        </p>
                      )}
                      {activityDetails.insurance && (
                        <p>
                          <strong>Looking for Insurance: </strong>{" "}
                          {activityDetails.insurance}
                        </p>
                      )}
                      {activityDetails.loan && (
                        <p>
                          <strong>Looking for Loan: </strong>{" "}
                          {activityDetails.loan}
                        </p>
                      )}
                      {activityDetails.looking_for && (
                        <p>
                          <strong>Looking For: </strong>{" "}
                          {activityDetails.looking_for}
                        </p>
                      )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">User Login Details</h4>
                      <div
                        onClick={() => handleView(userFound)}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        <p>
                          <strong>Name:</strong> {userFound.name}
                        </p>
                        <p>
                          <strong>Email:</strong> {userFound.email}
                        </p>
                        <p>
                          <strong>Mobile:</strong> {userFound.mobile}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Property Title</th>
                          <th>Email</th>
                          <th>URL</th>
                          <th>Timestamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentProperties.length > 0 ? (
                          currentProperties.map((property, index) => (
                            <tr key={index}>
                              <td>{property.property_title}</td>
                              <td>{email}</td>
                              <td>
                                <a
                                  href={property.property_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {property.property_url}
                                </a>
                              </td>
                              <td>
                                {formatDateToIST(
                                  property.property_urls_timestamp
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No properties found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination */}
                  <nav>
                    <ul className="pagination">
                      {properties.length > ITEMS_PER_PAGE &&
                        Array.from(
                          {
                            length: Math.ceil(
                              properties.length / ITEMS_PER_PAGE
                            ),
                          },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                currentPage === index + 1 ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          )
                        )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
