import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Editor } from 'primereact/editor';
import { Link } from "react-router-dom";

export default function AddRewards() {
  const [name, setName] = useState('');
  const [coins, setCoins] = useState('');
  const [description, setDescription] = useState('');
  const [clientIp, setClientIp] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch the client's IP address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setClientIp(response.data.ip);
      })
      .catch(error => {
        console.error('There was an error fetching the IP address!', error);
      });
  }, []);

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "This field is required.";
    tempErrors.coins = coins ? "" : "This field is required.";
    tempErrors.description = description ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const newReward = {
        name: name,
        coins: coins,
        description: description,
        ip: clientIp
      };

      axios.post(`${BaseURL}/addreward`, newReward)
        .then(response => {
          alert('Reward added successfully!');
          // Reset form fields
          setName('');
          setCoins('');
          setDescription('');
          setErrors({});
        })
        .catch(error => {
          console.error('There was an error adding the reward!', error);
        });
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}>
                    <h4 className="card-title">Add New Reward</h4>
                    <Link to="/rewards" style={{
                      backgroundColor: "#f44336",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      textDecoration: "none",
                      fontWeight: "bold"
                    }}>
                      Back
                    </Link>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Reward Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                          fontSize: "16px"
                        }}
                      />
                      {errors.name && <div className="error" style={{ color: "red" }}>{errors.name}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="coins">Coins</label>
                      <input
                        type="number"
                        className="form-control"
                        id="coins"
                        value={coins}
                        onChange={(e) => setCoins(e.target.value)}
                        required
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                          fontSize: "16px"
                        }}
                      />
                      {errors.coins && <div className="error" style={{ color: "red" }}>{errors.coins}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <Editor
                        style={{
                          height: '320px',
                          border: "1px solid #ced4da",
                          borderRadius: "4px"
                        }}
                        value={description}
                        onTextChange={(e) => setDescription(e.htmlValue)}
                      />
                      {errors.description && <div className="error" style={{ color: "red" }}>{errors.description}</div>}
                    </div>
                    <button type="submit" className="btn btn-primary mt-3" style={{
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: "bold"
                    }}>Add Reward</button>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
