import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Paper,
  Modal,
  TextField,
  Typography,
  TablePagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Link, useNavigate } from "react-router-dom";

export default function Advertisement() {
  const [advertisements, setAdvertisements] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3); // Set the number of ads per page
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdvertisements();
  }, []);

  const fetchAdvertisements = async () => {
    try {
      const response = await axios.get(`${BaseURL}/getads`);
      setAdvertisements(response.data.results || []);
    } catch (error) {
      console.error(`Error fetching advertisements:`, error);
      setAdvertisements([]);
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      await axios.put(`${BaseURL}/adchangestatus`, null, {
        params: {
          id,
          status,
        },
      });
      alert("Status updated successfully");
      fetchAdvertisements();
    } catch (error) {
      alert("Error updating status");
      console.error("Error updating status:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseURL}/deletead/${id}`);
      alert("Advertisement deleted successfully");
      fetchAdvertisements();
    } catch (error) {
      alert("Error deleting advertisement");
      console.error("Error deleting advertisement:", error);
    }
  };

  const handleEditClick = (ad) => {
    setSelectedAd(ad);
    setSelectedImageFile(null);
    setImagePreview(null); // Reset image preview when opening the modal
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedAd({ ...selectedAd, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("title", selectedAd.title);
    formData.append("url", selectedAd.url);
    formData.append("start_date", selectedAd.start_date);
    formData.append("end_date", selectedAd.end_date);
    if (selectedImageFile) {
        formData.append("ad_image", selectedImageFile);
    }

    try {
        const response = await axios.put(`${BaseURL}/editad/${selectedAd.id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });


        fetchAdvertisements();
        handleModalClose();
    } catch (error) {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || "An error occurred";
            alert(`Error: ${errorMessage}`);
        } else {
            console.error(`Error updating advertisement:`, error);
        }
    }
};


  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page is changed
  };

  const renderAdvertisements = () => {
    // Slicing the advertisements array to get only the items for the current page
    const paginatedAds = advertisements.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <div>
        <Link to="add-adv">
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right", marginBottom: "10px" }}
          >
            Add Advertisement
          </Button>
        </Link>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAds.map((ad, index) => (
                <TableRow key={ad.id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{ad.title}</TableCell>
                  <TableCell>{ad.url}</TableCell>
                  <TableCell>
                    <img
                      src={ad.image}
                      alt={ad.name}
                      style={{ width: "100px", borderRadius: "8px" }}
                    />
                  </TableCell>
                  <TableCell>{ad.start_date}</TableCell>
                  <TableCell>{ad.end_date}</TableCell>
                  <TableCell>
                    <Switch
                      checked={ad.status === 1}
                      onChange={() =>
                        handleStatusChange(ad.id, ad.status === 1 ? 0 : 1)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(ad.timestamp).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditClick(ad)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(ad.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={advertisements.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[3, 5, 10]} // Optional: you can add more options if needed
          />
        </TableContainer>
      </div>
    );
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          {renderAdvertisements()}
          <Modal open={isModalOpen} onClose={handleModalClose}>
            <Paper
              style={{
                margin: "auto",
                padding: "20px",
                maxWidth: "500px",
                height: "85vh",
                overflowY: "auto",
                marginTop: "50px",
              }}
            >
              <Typography variant="h6">Edit Advertisement</Typography>
              {selectedAd && (
                <form>
                  <TextField
                    label="Name"
                    name="title"
                    value={selectedAd.title}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Link"
                    name="url"
                    value={selectedAd.url}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Start Date"
                    name="start_date"
                    type="date"
                    value={selectedAd.start_date}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Expiry Date"
                    name="end_date"
                    type="date"
                    value={selectedAd.end_date}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                 <img
                      src={imagePreview || selectedAd.image} // Use imagePreview if available
                      alt={selectedAd.title}
                      style={{ width: "100px", borderRadius: "8px", margin:'20px' }}
                    />
                    <input
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                      style={{ marginTop: "10px" }}
                    />
                  <Grid container justifyContent="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdate}
                      style={{marginTop:'30px'}}
                    >
                      Update Advertisement
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleModalClose}
                      style={{marginTop:'30px'}}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </form>
              )}
            </Paper>
          </Modal>
        </div>
        <Footer />
      </div>
    </div>
  );
}
