import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import Staffstable from "./Staffstable";
import EditStaffModal from "./EditStaffModal";
import DeleteModal from "./DeleteModal";
import AddStaffModal from "./AddStaffModal";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";

export default function Staffs() {
  const [staffsArray, setStaffsArray] = useState([]);
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseURL}/getAllStaffsExceptLoggedInStaff`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.status === 200 &&
        response.data.message === "Other Staff Details Collected"
      ) {
        setStaffsArray(response.data.staffs);
        setFilteredStaffs(response.data.staffs);
      } else if (
        response.status === 200 &&
        response.data.message === "No other staffs found"
      ) {
        alert(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 500 &&
        error.response.data.message === "Internal server error"
      ) {
        alert(error.response.data.message);
      } else {
        console.error("Error fetching staffs:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (staffId) => {
    const selected = staffsArray.find((staff) => staff.id === staffId);
    setSelectedStaff(selected);
    setIsEditModalOpen(true);
  };

  const handleAddStaff = () => {
    setIsAddStaffModalOpen(true);
  };

  const handleSave = async (updatedStaff) => {
    try {
      const response = await axios.put(`${BaseURL}/editStaff`, updatedStaff);
      if (
        response.status === 201 &&
        response.data.message === "Staff details updated successfully"
      ) {
        const updatedStaffs = staffsArray.map((staff) =>
          staff.id === updatedStaff.id ? { ...staff, ...updatedStaff } : staff
        );
        setStaffsArray(updatedStaffs);
        setIsEditModalOpen(false);
      } else {
        console.error("Error updating staff:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating staff:", error);
    }
  };

  const handleDeleteModalOpen = (staffId) => {
    const selected = staffsArray.find((staff) => staff.id === staffId);
    setSelectedStaff(selected);
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    const filtered = staffsArray.filter((staff) => {
      const searchString = searchTerm.toLowerCase();
      return (
        staff.name.toLowerCase().includes(searchString) ||
        staff.email.toLowerCase().includes(searchString) ||
        staff.mobile.toLowerCase().includes(searchString)
      );
    });
    setFilteredStaffs(filtered);
  }, [searchTerm, staffsArray]);

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>Staff Management</h4>
                    <div>
                      <input
                        type="text"
                        placeholder="Search Staffs"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                          marginRight: "10px",
                          border: "1px solid black",
                          padding: "8px 12px",
                          borderRadius: "4px", 
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                          transition: "box-shadow 0.3s ease", 
                          width: "200px", 
                          fontSize: "16px",
                        }}
                      />
                      <button
                        className="btn btn-primary rounded"
                        onClick={handleAddStaff}
                        style={{ marginRight: "10px" }}
                      >
                        Add Staff
                      </button>
                      <Link
                        to="/archivestaffs"
                        className="btn btn-primary rounded"
                      >
                        Archive Staff
                      </Link>
                    </div>
                  </div>

                  <AddStaffModal
                    isOpen={isAddStaffModalOpen}
                    onClose={() => setIsAddStaffModalOpen(false)}
                    setStaffsArray={setStaffsArray}
                    fetchData={fetchData}
                  />

                  <Staffstable
                    staffsArray={filteredStaffs}
                    handleEdit={handleEdit}
                    handleDelete={handleDeleteModalOpen}
                    setStaffsArray={setStaffsArray}
                  />

                  <EditStaffModal
                    isOpen={isEditModalOpen}
                    setIsEditModalOpen={setIsEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    staff={selectedStaff}
                    handleSave={handleSave}
                  />
                  <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setIsOpen={setIsDeleteModalOpen}
                    staff={selectedStaff}
                    handleDelete={async (staffId) => {
                      try {
                        const response = await axios.put(
                          `${BaseURL}/archivestaff/${staffId}`
                        );
                        if (
                          response.status === 200 &&
                          response.data.message ===
                            "Staff deleted from staffs table and archived successfully"
                        ) {
                          const updatedStaffs = staffsArray.filter(
                            (staff) => staff.id !== staffId
                          );
                          setStaffsArray(updatedStaffs);
                          setIsDeleteModalOpen(false);
                        } else {
                          console.error(
                            "Error Archive staff:",
                            response.data.message
                          );
                        }
                      } catch (error) {
                        console.error("Error Archive staff:", error);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
