import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { useDropzone } from "react-dropzone";
import { Modal, Button, Form } from "react-bootstrap";

export default function AdminChat() {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [newMessageFiles, setNewMessageFiles] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const navigate = useNavigate();
  const staffId = localStorage.getItem("staffId");
  const type = localStorage.getItem("type");

  useEffect(() => {
    fetchTicket(ticketId);

    const interval = setInterval(() => {
      fetchTicket(ticketId);
    }, 6000);

    return () => clearInterval(interval);
  }, [ticketId]);

  const fetchTicket = (id) => {
    axios
      .get(`${BaseURL}/getmessages/${id}`)
      .then((response) => {
        setTicket(response.data);
        if (!response.data.assignedStaffId) {
          setShowAssignModal(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching ticket:", error);
      });
  };

  const sendMessage = () => {
    if (!newMessage.trim()) {
      console.error("Error: Message cannot be blank");
      alert("Hmmm 🤔 Type your message")
      return;
    }
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("isAdminReply", true);
    formData.append("staffId", staffId);
    formData.append("type", type);
    newMessageFiles.forEach((file) => {
      formData.append("documents", file);
    });

    axios
      .post(`${BaseURL}/tickets/${ticketId}/messages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        fetchTicket(ticketId);
        setNewMessage("");
        setNewMessageFiles([]);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(timestamp).toLocaleDateString("en-GB", options);
  };

  const handleNewMessageFiles = (acceptedFiles) => {
    setNewMessageFiles([...newMessageFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleNewMessageFiles,
  });

  const handleAssignTicket = () => {
    axios
      .post(
        `${BaseURL}/assignstaff`,
        { staffId, ticketId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setShowAssignModal(false);
      })
      .catch((error) => {
        console.error("Error assigning ticket:", error);
      });
  };

  const handleDecline = () => {
    navigate(-1);
  };

  const fetchStaffList = () => {
    axios
      .get(`${BaseURL}/getstaffswithticketpermission/${staffId}`)
      .then((response) => {
        setStaffList(response.data.staffs);
        setShowTransferModal(true);
      })
      .catch((error) => {
        console.error("Error fetching staff list:", error);
      });
  };

  const handleTransferTicket = () => {
    axios
      .put(
        `${BaseURL}/transferticket`,
        { ticketId, staffId: selectedStaffId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setShowTransferModal(false);
        navigate("/tickets");
        fetchTicket(ticketId);
      })
      .catch((error) => {
        console.error("Error transferring ticket:", error);
      });
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                <button
                    onClick={handleDecline}
                    className="btn btn-secondary mb-3"
                  >
                    Back
                  </button>
                  <button
                    onClick={fetchStaffList}
                    className="btn btn-secondary mb-3"
                    style={{ float:"right", background:"#172d88", }}
                  >
                   <i class="fa fa-exchange"></i> Transfer
                  </button>
                  {ticket ? (
                    ticket.assignedStaffName ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="card-title">Ticket #{ticketId} Chat</h4>
                        <h4>
                          Assigned Staff Name:{" "}
                          <span style={{ color: "red", marginRight: "5rem" }}>
                            {ticket.assignedStaffName}
                          </span>
                        </h4>
                      </div>
                    ) : (
                      <p>Not Assigned</p>
                    )
                  ) : (
                    <p>No Ticket Data</p>
                  )}

                  
                  {ticket ? (
                    <div className="ticket-message-container">
                      <div>
                        <strong>Created Date:</strong>{" "}
                        {formatDate(ticket.ticketCreatedDate)}
                      </div>

                      <div className="messages">
                        {ticket.messages.map((message, index) => (
                          <div
                            key={index}
                            className={`mb-2 ${
                              message.is_admin_reply ? "text-end" : "text-start"
                            }`}
                          >
                            <strong>
                              {message.is_admin_reply
                                ? message.staffName
                                : message.userName}
                              :
                            </strong>{" "}
                            {message.message} <br />
                            <small>{formatDate(message.timestamp)}</small>
                            {message.documents.length > 0 && (
                              <div className="documents mt-2">
                                {message.documents.map((doc, idx) => (
                                  <div key={idx}>
                                    <a
                                      href={doc.file_path}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {doc.file_name}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message"
                        className="form-control"
                      />
                      <div
                        {...getRootProps()}
                        className="dropzone mt-2 small-dropzone"
                      >
                        <input {...getInputProps()} />
                        <p>Upload files as supporting documents.</p>
                      </div>
                      <div className="mt-2">
                        {newMessageFiles.map((file, index) => (
                          <div key={index} className="file-item">
                            {file.name}
                          </div>
                        ))}
                      </div>
                      <button
                        onClick={sendMessage}
                        className="btn btn-primary mt-2"
                      >
                        Send Message
                      </button>
                    </div>
                  ) : (
                    <p>Loading ticket...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <Modal show={showAssignModal} onHide={handleDecline}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to assign this ticket id{" "}
            <span style={{ color: "red" }}>{ticketId}</span> to yourself?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDecline}>
            No
          </Button>
          <Button variant="primary" onClick={handleAssignTicket}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="staffSelect">
            <Form.Label>Select Staff</Form.Label>
            <Form.Control
              as="select"
              value={selectedStaffId}
              onChange={(e) => setSelectedStaffId(e.target.value)}
            >
              <option value="">Select staff</option>
              {staffList.map((staff) => (
                <option key={staff.id} value={staff.id}>
                  {staff.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTransferModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleTransferTicket}>
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>
      <style>
        {`
          .ticket-message-container {
            max-height: 300px;
            overflow-y: auto;
          }
          .messages {
            max-height: 200px;
            overflow-y: auto;
            border: 1px solid #ddd;
            padding: 10px;
            margin-bottom: 10px;
          }
          .dropzone {
            border: 2px dashed #007bff;
            padding: 20px;
            text-align: center;
            cursor: pointer;
          }
          .small-dropzone {
            padding: 10px;
            font-size: 0.875rem;
          }
          .file-item {
            margin-bottom: 5px;
            font-size: 0.875rem;
            color: #555;
          }
          .documents a {
            text-decoration: none;
            color: #007bff;
          }
        `}
      </style>
    </div>
  );
}
