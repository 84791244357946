import React from "react";

const Notification = ({ newTicketCount }) => {
  return (
    <div className="notification">
      {newTicketCount > 0 ? (
        <span className="notification-count">{newTicketCount} new ticket(s)</span>
      ) : (
        "No new tickets"
      )}
    </div>
  );
};

export default Notification;
