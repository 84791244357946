import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import ActivitiesTable from "./ActivitiesTable";

export default function Activities() {
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getallactivities`);
        if (response.data && response.data.results) {
          setActivities(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchActivities();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredActivities = activities.filter(
    (activity) => 
      (activity.name && activity.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (activity.email && activity.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (activity.mobile && activity.mobile.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentActivities = filteredActivities.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 className="card-title" style={{ margin: 0 }}>
                      User Activities
                    </h4>
                    <input
                      type="text"
                      placeholder="Search by name, email or mobile"
                      value={searchTerm}
                      onChange={handleSearch}
                      className="form-control mb-3"
                      style={{ maxWidth: "300px" }} 
                    />
                  </div>
                  <ActivitiesTable
                    activities={currentActivities}
                    itemsPerPage={itemsPerPage}
                    totalItems={filteredActivities.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
