import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isValidMobile } from "../../../utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "auto",
  borderRadius: "12px", // Rounded corners
  backgroundColor: "#fff", // White background color
  color: "#333", // Text color
  fontFamily: "'Roboto', sans-serif", // Font style
  fontSize: "16px", // Font size
  transition: "all 0.3s ease", // Smooth transition
  animation: "fadeIn 0.5s", // Fade-in animation
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
};

const permissionsList = [
  { id: 0, label: "Properties" },
  { id: 1, label: "Users" },
  { id: 2, label: "Services" },
  // { id: 3, label: "Packages" },
  { id: 4, label: "Tickets" },
  { id: 5, label: "Rewards" },
  { id: 6, label: "Payments" },
  { id: 7, label: "Activities" },
  { id: 8, label: "User Query" },
  { id: 9, label: "Coupons" },
  { id: 10, label: "Testimonials" },
  { id: 11, label: "Agents" },
  { id: 12, label: "Abouts" },
  { id: 13, label: "Teams" },
];

function EditStaffModal({
  isOpen,
  setIsEditModalOpen,
  onClose,
  staff,
  handleSave,
}) {
  const [editedDetails, setEditedDetails] = useState({
    id: "",
    name: "",
    email: "",
    mobile: "",
    address: "",
    permissions: [],
  });

  useEffect(() => {
    if (staff) {
      setEditedDetails({
        id: staff.id || "",
        name: staff.name || "",
        email: staff.email || "",
        mobile: staff.mobile || "",
        address: staff.address || "",
        permissions: staff.permissions
          ? staff.permissions.split(",").map(Number)
          : [],
      });
    }
  }, [staff]);

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;

    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePermissionChange = (id) => {
    setEditedDetails((prevState) => {
      const newPermissions = prevState.permissions.includes(id)
        ? prevState.permissions.filter((permId) => permId !== id)
        : [...prevState.permissions, id];
      return { ...prevState, permissions: newPermissions };
    });
  };

  const handleSubmit = () => {
    if (
      !editedDetails.address.trim() ||
      editedDetails.address.trim().length <= 4
    ) {
      alert("Address is required and must be longer than 4 characters");
      return;
    }

    if (!isValidMobile(editedDetails.mobile)) {
      alert("Please enter a valid 10-digit mobile number");
      return;
    }

    handleSave({
      ...editedDetails,
      permissions: editedDetails.permissions.join(","),
    });
    setIsEditModalOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h2">
            Edit Staff Details
          </Typography>
          <IconButton onClick={onClose} style={{ color: "#000" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={editedDetails.email}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            placeholder="Enter Name"
            value={editedDetails.name}
            name="name"
            onChange={handleUserDetailsChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Mobile"
            placeholder="Enter Mobile"
            value={editedDetails.mobile}
            name="mobile"
            onChange={handleUserDetailsChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Address"
            placeholder="Enter Address"
            value={editedDetails.address}
            name="address"
            onChange={handleUserDetailsChange}
            multiline
            rows={3}
          />
          {staff && staff.type === 0 && (
  <>
    <Typography variant="h6" component="h3" marginTop={2}>
      Permissions
    </Typography>
    <FormGroup style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '16px' }}>
      {permissionsList.map((permission) => (
        <FormControlLabel
          key={permission.id}
          control={
            <Checkbox
              checked={editedDetails.permissions.includes(permission.id)}
              onChange={() => handlePermissionChange(permission.id)}
            />
          }
          label={permission.label}
          style={{ margin: 0 }} // Adjust margin if necessary
        />
      ))}
    </FormGroup>
  </>
)}

        </form>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: 8 }}
          >
            Close
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default EditStaffModal;
