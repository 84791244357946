import React from "react";
import { Link } from "react-router-dom";

const ActivitiesTable = ({ activities, itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div style={{height:"70vh", overflowY:"scroll"}}>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Occupation</th>
              <th>Created Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {activities.length > 0 ? (
              activities.map((activity, index) => (
                <tr key={activity.id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{activity.name || activity.user_name}</td>
                  <td>{activity.email || activity.user_email}</td>
                  <td>{activity.mobile || activity.user_mobile}</td>
                  <td>{activity.occupation || activity.user_occupation}</td>
                  <td>{new Date(activity.created_date).toLocaleDateString()}</td>
                  <td>
                    <Link to={`/view-all-activity/${activity.email || activity.user_email}?userId=${activity.userId}`} className="btn btn-primary">
                      View
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No activities found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
              <a onClick={() => paginate(number)} className="page-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default ActivitiesTable;
